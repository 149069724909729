<template>
  <DashboardLayout>
    <LoadingSpinner :isLoading="isLoading" />
    <ToastManagerVue ref="toastManager" />
    <div class="overall-container">
      <div class="dashboard-main-container">
        <div class="header-container">
          <!-- <div class="profile-image-container">
            <div class="profile-image">
              <img
                :src="profileImage || require(`@/assets/${defaultImage}`)"
                alt="Profile Image"
              />
              <input type="file" @change="onFileChange" id="file-input" />
              <label for="file-input" class="edit-icon">
                <svg
                  width="30"
                  height="30"
                  viewBox="0 0 30 30"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect width="30" height="30" rx="15" fill="#979797" />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M20.4919 7.5931L21.6172 8.71842C22.2757 9.3686 22.2757 10.4272 21.6172 11.0774L10.5892 22.1055H7.10486V18.6212L15.7739 9.94376L18.1329 7.5931C18.7831 6.94292 19.8417 6.94292 20.4919 7.5931ZM8.77199 20.4383L9.94731 20.4884L18.1329 12.2944L16.9576 11.1191L8.77199 19.3047V20.4383Z"
                    fill="white"
                  />
                </svg>
              </label>
            </div>
          </div> -->
          <div class="header-container-details">
            <div class="header-container-details-first-div">
              <h4>{{ user.name }}</h4>
            </div>
            <div>Lagos</div>
            <div>Member since 2024</div>
          </div>
        </div>
        <div class="main-dashboard-container">
          <div class="first-dashboard-container">
            <div class="jobs-container">
              <!-- <div>
                <div class="job">
                  <div class="job-header-content">
                    <div class="job-header-content-job-title">
                      <h2>Bio</h2>
                      <svg
                        @click="showModal('aboutme')"
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M16.0576 0.586953L17.4076 1.93695C18.1976 2.71695 18.1976 3.98695 17.4076 4.76695L4.17756 17.997H-0.00244141V13.817L10.3976 3.40695L13.2276 0.586953C14.0076 -0.193047 15.2776 -0.193047 16.0576 0.586953ZM1.99756 15.997L3.40756 16.057L13.2276 6.22695L11.8176 4.81695L1.99756 14.637V15.997Z"
                          fill="#979797"
                        />
                      </svg>
                    </div>
                  </div>
                  <div class="job-body-content">
                    <p>
                      Donec euismod lorem eget laoreet rutrum. Pellentesque
                      vitae Donec euismod lorem eget laoreet rutrum.
                      Pellentesque vitae Donec euismod lorem eget laoreet
                      rutrum. Pellentesque vitae Donec euismod lorem eget
                      laoreet rutrum. Pellentesque vitae
                    </p>
                  </div>

          
                </div>
              </div> -->
              <div>
                <div class="job">
                  <div class="job-header-content">
                    <div class="job-header-content-job-title">
                      <h2>Care Needs</h2>
                      <svg
                        @click="showModal('services')"
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M16.0576 0.586953L17.4076 1.93695C18.1976 2.71695 18.1976 3.98695 17.4076 4.76695L4.17756 17.997H-0.00244141V13.817L10.3976 3.40695L13.2276 0.586953C14.0076 -0.193047 15.2776 -0.193047 16.0576 0.586953ZM1.99756 15.997L3.40756 16.057L13.2276 6.22695L11.8176 4.81695L1.99756 14.637V15.997Z"
                          fill="#979797"
                        />
                      </svg>
                    </div>
                  </div>

                  <div
                    style="
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                      padding-right: 10px;
                    "
                  >
                    <p class="category-text-class">
                      {{ user.categories.name }}
                    </p>
                    <svg
                      width="12"
                      height="8"
                      viewBox="0 0 12 8"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12 1.41L10.59 -6.16331e-08L6 4.58L1.41 -4.62904e-07L-6.16331e-08 1.41L6 7.41L12 1.41Z"
                        fill="black"
                      />
                    </svg>
                  </div>

                  <!-- <button @click="saveJob(job.id)">Save</button> -->
                </div>
              </div>
              <section class="jobs">
                <h2 style="text-align: left">Jobs History</h2>
                <div
                  v-for="job in filteredJobs"
                  :key="job.id"
                  class="job"
                  @click="openJobDetails(job)"
                >
                  <div class="job-header-content">
                    <div class="job-header-content-job-title">
                      <h2>{{ job.title }}</h2>
                    </div>
                    <div style="display: flex; align-items: center; gap: 16px">
                      <div class="caregiver-rating">
                        <span
                          v-for="star in 5"
                          :key="star"
                          class="star"
                          :class="{ filled: star <= 5 }"
                          >★</span
                        >
                      </div>
                      <p class="faint-text">
                        23rd March 2024 - 4th April, 2024
                      </p>
                    </div>
                    <p class="faint-text">Lagos, Nigeria</p>
                  </div>
                  <div class="job-body-content">
                    <p>{{ job.description }}</p>
                  </div>

                  <h5>Budget: N 15, 000</h5>
                  <!-- <button @click="saveJob(job.id)">Save</button> -->
                </div>
              </section>
            </div>
          </div>
        </div>
        <!-- Job Details Modal -->
        <GenericModal
          :isVisible="isMainModalVisible"
          :title="modalTitle"
          @close="hideModal"
          @confirm="handleConfirm"
        >
          <template v-if="currentModal === 'services'">
            <!-- Services form content -->
            <div>
              <!-- <p style="text-align: center">Do you want any other care services?</p> -->
            </div>
            <!-- <div class="care-card-container">
              <div
                v-for="service in services"
                :key="service.id"
                class="care-card"
                :class="{
                  'care-card-selected': localData.type_of_care.includes(service.id),
                }"
                @click="toggleService(service.id)"
              >
                <div>
                  <img :src="require(`@/assets/${service.icon}`)" />
                  <p>{{ service.label }}</p>
                </div>
                <input
                  type="checkbox"
                  :checked="localData.type_of_care.includes(service.id)"
                />
              </div>
            </div> -->
            <div class="care-card-container">
              <div
                v-for="service in services"
                :key="service.id"
                class="care-card"
                :class="{
                  'care-card-selected': user.categories.id === service.id,
                }"
                @click="toggleService(service.id)"
              >
                <div>
                  <img :src="require(`@/assets/${service.icon}`)" />
                  <h3>{{ service.label }}</h3>
                </div>
                <!-- <input
            type="radio"
            :checked=" user.categories.id === service.id"
          /> -->
                <input
                  type="radio"
                  :value="service.id"
                  v-model="user.categories.id"
                />
              </div>
            </div>
            <BaseButton
              :label="isProfileUpdate ? 'Updating...' : 'Update'"
              backgroundColor="var(--s-pink-normal)"
              textColor="var(--state-white)"
              height="60px"
              width="400px"
              @click="handleCategoryUpdate"
            />
          </template>
        </GenericModal>
      </div>
    </div>
  </DashboardLayout>
</template>

<script>
import { ref, computed, onMounted } from "vue";
import "@vueform/multiselect/themes/default.css";
import GenericModal from "@/components/GenericModal.vue";
import { useRouter } from "vue-router";
import BaseButton from "@/components/BaseButton.vue";
import ToastManagerVue from "@/components/ToastAlert/ToastManager.vue";
import "@vueform/multiselect/themes/default.css";
import DashboardLayout from "@/views/Dashboard/Caregiver/IndexPage.vue";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import Request from "@/utils/apiclient.js";
import { formatErrorMessages } from "@/utils/errorHandler";
export default {
  name: "CareSeekerProfilePage",
  components: {
    GenericModal,
    BaseButton,
    DashboardLayout,
    ToastManagerVue,
    LoadingSpinner,
  },
  setup() {
    const router = useRouter();
    const isLoading = ref(true);
    const userProfile = JSON.parse(localStorage.getItem("_userProfile"));
    const localData = ref({
      type_of_care: [],
    });
    const toastManager = ref(null);
    const showToast = (msg, statusValue) => {
      toastManager.value.addToast(msg, statusValue);
    };

    const services = ref([
      // { id: "senior_care", label: "Senior Care", icon: "senior_care.svg" },
      // {
      //   id: "academic_care",
      //   label: "Academic Care",
      //   icon: "academic_care.svg",
      // },
      // { id: "child_care", label: "Child Care", icon: "child_care.svg" },
      // { id: "home_care", label: "Home Care", icon: "house_care.svg" },
    ]);

    const disabled = ref(true);

    const toggleService = (serviceId) => {
      const index = localData.value.type_of_care.indexOf(serviceId);
      if (index > -1) {
        localData.value.type_of_care.splice(index, 1);
      } else {
        localData.value.type_of_care.push(serviceId);
      }
      disabled.value = localData.value.type_of_care.length === 0;
    };

    // Sample data
    const jobs = ref([
      {
        id: 1,
        posted: "5 minutes",
        title: "Senior Care Needed",
        budget: "₦20,000",
        description:
          "I need a sit in care giver for my mom of 75 years I need a sit in care giver for my mom of 75 years I need a sit in care giver for my mom of 75 years. I need a sit in care giver for ..",
        tags: ["Bathing / Dressing", "Special Care"],
      },
      {
        id: 2,
        posted: "5 minutes",
        title: "Senior Care Needed",
        budget: "₦20,000",
        description:
          "I need a sit in care giver for my mom of 75 years I need a sit in care giver for my mom of 75 years I need a sit in care giver for my mom of 75 years. I need a sit in care giver for ..",
        tags: ["Bathing / Dressing", "Special Care"],
      },
      {
        id: 3,
        posted: "5 minutes",
        title: "Senior Care Needed",
        budget: "₦20,000",
        description:
          "I need a sit in care giver for my mom of 75 years I need a sit in care giver for my mom of 75 years I need a sit in care giver for my mom of 75 years. I need a sit in care giver for ..",
        tags: ["Bathing / Dressing", "Special Care"],
      },
      {
        id: 4,
        posted: "5 minutes",
        title: "Senior Care Needed",
        budget: "₦20,000",
        description:
          "I need a sit in care giver for my mom of 75 years I need a sit in care giver for my mom of 75 years I need a sit in care giver for my mom of 75 years. I need a sit in care giver for ..",
        tags: ["Bathing / Dressing", "Special Care"],
      },
      // Add more job objects here
    ]);

    const user = ref({
      image: "profileimage.png",
      name: "",
      title: "Professional Careseeker",
      availability: "Mondays - Friday",
      categories: {},
      preferences: "Live Out",
      rate: "N5000 / day",
      education: "University of Lagos",
    });

    const searchQuery = ref("");
    const currentTab = ref("myFeed");
    const isModalVisible = ref(false);
    const selectedJob = ref({});

    // Computed property to filter jobs based on search query
    const filteredJobs = computed(() => {
      return jobs.value.filter((job) =>
        job.title.toLowerCase().includes(searchQuery.value.toLowerCase())
      );
    });

    // Method to set the current tab
    const setTab = (tab) => {
      currentTab.value = tab;
    };

    // Method to save a job (dummy implementation)
    const saveJob = (jobId) => {
      console.log(`Job ${jobId} saved!`);
    };

    // Method to open job details modal
    const openJobDetails = (job) => {
      selectedJob.value = job;
      isModalVisible.value = true;
    };

    // Method to close job details modal
    const closeJobDetails = () => {
      isModalVisible.value = false;
      selectedJob.value = null;
    };

    const profileImage = ref(null);
    const defaultImage = ref("woman.png");

    const onFileChange = (event) => {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          profileImage.value = e.target.result;
        };
        reader.readAsDataURL(file);
      }
    };

    const isMainModalVisible = ref(false);
    const currentModal = ref("");
    const modalTitle = ref("");

    const showModal = (modalType) => {
      currentModal.value = modalType;
      modalTitle.value = getModalTitle(modalType);
      isMainModalVisible.value = true;
    };

    const hideModal = () => {
      isMainModalVisible.value = false;
    };
    const institution = ref("University of Lagos");
    const getModalTitle = (modalType) => {
      switch (modalType) {
        case "availability":
          return "Update your availability";
        case "services":
          return "Update services you want";
        case "preferences":
          return "Update your preferences";
        case "languages":
          return "Update your languages";
        case "aboutme":
          return "Update your bio";
        case "institution":
          return "Update your institution";
        case "rateModal":
          return "Update your rate";
        default:
          return "";
      }
    };

    const handleConfirm = () => {
      hideModal();
    };
    const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    // replace this mon,wed with values coming from endpoint
    const selectedDays = ref(["Mon", "Wed"]);
    const showSpecificTime = ref(false);

    const toggleDay = (day) => {
      if (selectedDays.value.includes(day)) {
        selectedDays.value = selectedDays.value.filter((d) => d !== day);
      } else {
        selectedDays.value.push(day);
      }
    };
    // start services
    const categories = ref([
      // {
      //   name: "Senior Care",
      //   showDropdown: false,
      //   options: [
      //     "Bathing ",
      //     "Dementia",
      //     "Home Health",
      //     "Live-in Home care",
      //     "Dressing",
      //     "Dementia",
      //     "House Health",
      //     "Live-out Home care",
      //     "Flex Health",
      //     "Genuine Home care",
      //   ],
      //   selectedOptions: ["Dementia", "Home Health"], // Default selected options
      // },
      // {
      //   name: "Child Care",
      //   showDropdown: false,
      //   options: ["Babysitting", "Nanny", "Daycare", "Preschool"],
      //   selectedOptions: ["Babysitting"], // Default selected options
      // },
      // {
      //   name: "Academic Care",
      //   showDropdown: false,
      //   options: ["Tutoring", "Homework Help", "Special Education"],
      //   selectedOptions: ["Tutoring"], // Default selected options
      // },
      // {
      //   name: "House Care",
      //   showDropdown: false,
      //   options: ["Cleaning", "Cooking", "Laundry", "Gardening"],
      //   selectedOptions: ["Cleaning"], // Default selected options
      // },
    ]);

    const selectedOptions = ref(
      categories.value.flatMap((category) => category.selectedOptions)
    );
    const toggleDropdown = (index) => {
      categories.value[index].showDropdown =
        !categories.value[index].showDropdown;
    };

    const updateSelectedOptions = () => {
      selectedOptions.value = categories.value.flatMap(
        (category) => category.selectedOptions
      );
    };

    // preferences start
    const preferences = ref("Both");
    const preferencesOptions = ref([
      {
        value: "Live in",
        text: "Live in",
      },
      {
        value: "Live out",
        text: "Live out",
      },
      {
        value: "Both",
        text: "Both",
      },
    ]);
    const hideLanguageDropdown = ref(true);

    const toggleLanguages = () => {
      hideLanguageDropdown.value = !hideLanguageDropdown.value;
    };

    const languagesRef = ref({
      name: "Select Language",
      showDropdown: false,
      options: [
        "English",
        "Igbo",
        "Yoruba",
        "Hausa",
        "French",
        "Spanish",
        "German",
      ],
      selectedOptions: ["English", "Igbo"],
    });
    const toggleLanguageDropdown = () => {
      languagesRef.value.showDropdown = !languagesRef.value.showDropdown;
      if (
        !languagesRef.value.showDropdown &&
        languagesRef.value.selectedOptions.length
      ) {
        hideLanguageDropdown.value = false;
      }
    };
    // const updateLanguageSelectedOptions = () => {

    // };
    const selectedLanguageOptions = computed(
      () => languagesRef.value.selectedOptions
    );

    const updateLanguageSelectedOptions = () => {
      if (languagesRef.value.selectedOptions.length) {
        hideLanguageDropdown.value = false;
      } else {
        hideLanguageDropdown.value = true;
      }
    };
    const aboutme = ref("bla bla bla bla bla bla");
    const rate = ref(50000); // Initial rate value

    const fetchProfile = () => {
      const request = new Request(`/profile/${userProfile.id}/`);
      request.get((res, err) => {
        isLoading.value = false;
        if (err) {
          console.error("Error:", err);
          if (err.detail && err.code == "token_not_valid") {
            return router.push("/login");
          }
        } else {
          //          image: "profileimage.png",
          // name: "Jane Adeyemi",
          // title: "Professional Caregiver",
          // availability: "Mondays - Friday",
          // categories: ["Child Care", "Senior Care", "Academic Care", "Home Care"],
          // preferences: "Live Out",
          // rate: "N5000 / day",
          // education: "University of Lagos",
          console.log("here is profile data", res);
          localStorage.setItem("_userProfile", JSON.stringify(res));
          user.value.name = res.first_name + " " + res.last_name;
          user.value.categories = res.care_seeker.category;
          // user.value.location = res.
        }
      });
    };
    const fetchCareCategory = () => {
      console.log("here is the user profile", userProfile);
      isLoading.value = true;
      const request = new Request("/category/");
      request.get((res, err) => {
        isLoading.value = false;
        if (err) {
          console.error("Error:", err);
        } else {
          console.log("Data:", res);
          // Map the API data to add the icon field from the static data
          const iconMap = {
            "Senior care": "senior_care.svg",
            "Academic care": "academic_care.svg",
            "Child care": "child_care.svg",
            "Home care": "house_care.svg",
          };

          services.value = res.results.map((item) => {
            return {
              id: item.id,
              label: item.name,
              icon: iconMap[item.name],
            };
          });
        }
      });
    };
    const isProfileUpdate = ref(false);
    const handleCategoryUpdate = () => {
      if (isProfileUpdate.value) return;
      isProfileUpdate.value = true;
      console.log("users", user.value.categories.id);
      const categoryPayload = { category: user.value.categories.id };
      const rawData = JSON.stringify(categoryPayload);
      const request = new Request(
        `/care-seeker/${userProfile.care_seeker.id}/`
      );
      request.patch(rawData, (data, error) => {
        isProfileUpdate.value = false;
        if (error) {
          console.log("data error", error);
          if (error.status === "failure") {
            return showToast(error.message, "error");
          }
          const errorMessages = formatErrorMessages(error);
          errorMessages.forEach((message) => showToast(message, "error"));
        } else {
          console.log("Success:", data);
          fetchProfile();
          hideModal();
          showToast("Profile Update Successful", "success");
        }
      });
    };
    onMounted(() => {
      fetchProfile();
      fetchCareCategory();
    });
    return {
      jobs,
      user,
      searchQuery,
      currentTab,
      filteredJobs,
      setTab,
      saveJob,
      openJobDetails,
      closeJobDetails,
      selectedJob,
      isModalVisible,

      defaultImage,
      onFileChange,
      profileImage,
      isMainModalVisible,
      currentModal,
      modalTitle,
      showModal,
      hideModal,
      handleConfirm,
      getModalTitle,
      days,
      selectedDays,
      showSpecificTime,
      toggleDay,
      categories,
      selectedOptions,
      toggleDropdown,
      updateSelectedOptions,
      toggleLanguages,
      preferences,
      preferencesOptions,
      languagesRef,
      toggleLanguageDropdown,
      updateLanguageSelectedOptions,
      selectedLanguageOptions,
      hideLanguageDropdown,
      aboutme,
      institution,
      rate,
      localData,
      toggleService,
      services,
      LoadingSpinner,
      isLoading,
      handleCategoryUpdate,
      isProfileUpdate,
      toastManager,
    };
  },
};
</script>

<style scoped>
.overall-container {
  display: flex;
  width: 100%;
  padding-left: 128px;
}
.login-container {
  max-width: 400px;
  margin: auto;
}

h2 {
  margin-bottom: 20px;
  margin-top: 32px;
  font-size: 24px;
  font-weight: 700;
  line-height: 29.05px;
  letter-spacing: 0.5px;
  text-align: center;
}

.login-button {
  width: 100%;
  background-color: var(--s-pink-normal);
  border: none;
  border-radius: 40px;
  color: white;
  font-size: 16px;
  cursor: pointer;
  margin-bottom: 10px;
  padding: 16px 24px 16px 24px;
}

.login-button:hover {
  background-color: var(--s-pink-normal-hover);
}

.forgot-password {
  display: block;
  margin-bottom: 34px;
  color: var(--s-green-normal);
  text-decoration: none;
  text-align: center;

  font-size: 14px;
  font-weight: 400;
  line-height: 23.8px;
  letter-spacing: 0.5px;
  text-align: center;
}

.forgot-password:hover {
  color: var(--s-green-normal-hover);
}

/* .google-login:hover,
.apple-login:hover {
  background-color: #f5f5f5;
} */
.separator {
  display: flex;
  align-items: center;
  text-align: center;
  color: #000;

  font-size: 20px;
  font-weight: 400;
  line-height: 34px;
  letter-spacing: 0.5px;
  text-align: left;
  margin-bottom: 16px;
}
.separator::before,
.separator::after {
  content: "";
  flex: 1;
  border-bottom: 1px solid #979797;
}
.separator:not(:empty)::before {
  margin-right: 8px;
}
.separator:not(:empty)::after {
  margin-left: 8px;
}
.join-now:hover {
  background-color: var(--s-pink-normal-hover);
}
.login-form {
  padding-bottom: 200px;
}

.dashboard-main-container {
  width: 1168px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  margin-top: 33px;
}

.first-dashboard-container {
  width: 100%;
  width: 988px;
  box-sizing: border-box;
}
.second-dashboard-container {
  width: 100%;
  width: 372px;
  box-sizing: border-box;
  padding-top: 76px;
}
h1 {
  font-size: 36px;
  font-weight: 300;
  line-height: 52px;
  letter-spacing: 0.5px;
  text-align: left;
  color: #000000;
  margin-top: 0px;
  margin-bottom: 24px;
}
.search-wrapper input {
  border: 1px solid #979797 !important;
  width: 575px;
  height: 64px;
  padding: 10px 10px 10px 70px;
  gap: 16px;
  border-radius: 12px;
  border: 1px;
  box-sizing: border-box;
  background-color: transparent;
}
.search-wrapper input::placeholder {
  font-size: 16px;
  font-weight: 400;
  line-height: 27.2px;
  letter-spacing: 0.5px;
  color: #979797;
}

.search-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 32px;
}
.search-icon {
  position: absolute;
  left: 20px;
}

.active {
  border-bottom: 3px solid var(--s-pink-normal);
}
.active p {
  color: var(--s-pink-normal) !important;
  font-weight: var(--font-weight-bold) !important;
}
.single-tab {
  padding: 0px 20.5px 4px 20.5px;
  cursor: pointer;
}
.tabs {
  display: flex;
  justify-content: flex-start;
  margin-top: 42px;
}
.tabs p {
  color: #0a3828;
  font-size: var(--font-body-normal-regular-size);
  line-height: var(--font-body-normal-regular-line-height);
  font-weight: var(--font-weight-regular);
}
.jobs-container {
  padding-top: 32px;
}
.job {
  padding: 34px 16px 34px 0px;
  border-bottom: 0.5px solid black;
  cursor: pointer;
}
.job-header-content {
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
  margin-bottom: 10px;
}
.job-header-content p {
  font-size: 12px;
  font-weight: 400;
  line-height: 20.4px;
  letter-spacing: 0.5px;
}
.job-header-content-job-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.job-header-content-job-title h2 {
  margin: 0px;

  font-size: 20px;
  font-weight: 500;
  line-height: 34px;
  letter-spacing: 0.5px;
  text-align: left;
}
.job-body-content p {
  font-size: 14px;
  font-weight: 400;
  line-height: 23.8px;
  letter-spacing: 0.5px;
  margin: 0px;
  padding: 0px;
}
.job-body-content {
  margin-bottom: 10px;
  display: flex;
}
.job-tag {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
.job-tag span {
  padding: 10px;
  gap: 8px;
  border-radius: 12px;
  background-color: #ececec;
  font-size: 12px;
  font-weight: 400;
  line-height: 20.4px;
  letter-spacing: 0.5px;
  text-align: left;
}
.second-dashboard-container-main {
  display: flex;
  gap: 18px;
  flex-direction: column;
}
.second-dashboard-container-profile-div {
  background-color: #e8f3ef80;
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 24px 16px;
}
.second-dashboard-container-profile-div img {
  width: 71px;
  height: 71px;
  border-radius: 50%;
}
.second-dashboard-container-profile-settings {
  background-color: #e8f3ef80;
}
.second-dashboard-container-profile-div-user {
  display: flex;
  flex-direction: column;
}
.second-dashboard-container-profile-div-user h3 {
  margin: 0px;
  font-size: 20px;
  font-weight: 700;
  line-height: 34px;
  letter-spacing: 0.5px;
  text-align: left;
}
.second-dashboard-container-profile-div-user p {
  font-size: 16px;
  font-weight: 400;
  line-height: 27.2px;
  letter-spacing: 0.5px;
  text-align: left;
}
.second-dashboard-container-profile-settings {
  padding: 24px 16px;
  display: flex;
  flex-direction: column;
  gap: 14px;
}
.second-dashboard-container-profile-settings h4 {
  font-size: 20px;
  font-weight: 700;
  line-height: 34px;
  letter-spacing: 0.5px;
  margin: 0px;
}
.availability-header,
.categories-header,
.job-preferences-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.availability,
.categories,
.preferences p {
  font-size: 16px;
  font-weight: 400;
  line-height: 27.2px;
  letter-spacing: 0.5px;
}
.availability,
.categories,
.preferences {
  padding: 10px 0px 10px 0px;
}

/* start availability */
.availability-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  font-family: Arial, sans-serif;
}

h1 {
  color: #0a3828;
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 20px;
}

.days-container,
.times-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 20px;
}

.day-button,
.time-button {
  padding: 10px 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: white;
  cursor: pointer;
  transition: background-color 0.3s, border-color 0.3s;
}

.day-button.selected,
.time-button.selected {
  background-color: var(--s-pink-light-hover);
  border-color: var(--s-green-normal);
}

.specific-time-container {
  margin-bottom: 20px;
  margin-top: 29px;
}

.time-picker {
  display: flex;
  align-items: center;
  gap: 29px;
  margin-top: 10px;
  position: relative;
}

.time-picker select {
  padding: 16px 52px 16px 10px;

  border-radius: 12px;
  border: 1px solid #979797;
  background-color: white;
  font-size: 16px;
  font-weight: 400;
  line-height: 27.2px;
  letter-spacing: 0.5px;
  text-align: left;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  position: relative;
  background: url('data:image/svg+xml;utf8,<svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12 1.70492L10.59 0.294922L6 4.87492L1.41 0.294921L-6.16331e-08 1.70492L6 7.70492L12 1.70492Z" fill="black"/></svg>')
    no-repeat right 26px center;
  background-size: 10px 6px;
}

.time-picker select::-ms-expand {
  display: none; /* Remove default arrow in Internet Explorer */
}

.next-button {
  background-color: #ff6978;
  color: white;
  font-size: 16px;
  padding: 10px 20px;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.next-button:disabled {
  background-color: #ececec;
  color: #979797;
  cursor: not-allowed;
}

.days-container button {
  padding: 10px;
  gap: 10px;
  border-radius: 12px;
  border: 1px solid #979797;
  font-size: 14px;
  font-weight: 400;
  line-height: 23.8px;
  letter-spacing: 0.5px;
  text-align: left;
  background-color: transparent;
}

.specific-time-container label {
  text-align: left;
}

.add-times {
  color: #0063f7;
  text-decoration: none;
  text-align: left;
}
span {
  font-size: 14px;
  font-weight: 400;
  line-height: 23.8px;
  letter-spacing: 0.5px;
  text-align: left;
}
.bottom-text {
  margin-top: 29px;
  margin-bottom: 16px;
  font-size: 14px;
  font-weight: 400;
  line-height: 23.8px;
  letter-spacing: 0.5px;
  text-align: center;
}
/* end availability */
/* start .categories */
input[type="checkbox"]:checked {
  border-color: var(--s-green-normal-active);
}

input[type="checkbox"]:checked::before {
  transform: scale(1);
}

.selected-options {
  margin-bottom: 16px;
  margin-top: 16px;
}
.selected-option {
  display: inline-block;
  background-color: var(--s-pink-light-hover);
  border-radius: 12px;
  padding: 10px;
  margin-right: 8px;
  margin-bottom: 8px;
  font-size: 12px;
  font-weight: 400;
  line-height: 20.4px;
  letter-spacing: 0.5px;

  color: #000000;
  border: 1px solid var(--s-green-normal-active);
}
.focus {
  border: 1px solid var(--s-green-normal);
}

.dropdown {
  display: flex;
  flex-wrap: wrap;
  border-radius: 5px;
}
.dropdown label {
  white-space: nowrap;
  font-size: 14px;
  font-weight: 400;
  line-height: 23.8px;
  letter-spacing: 0.5px;
  margin-left: 8px;
  color: #000000;
}

.option-container {
  width: 50%;
  box-sizing: border-box;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
}
.care-button .category {
  margin-top: 10px;
}

.care-button {
  padding: 16px;
  gap: 10px;
  border-radius: 12px;
  margin-bottom: 20px;
  border: 1px solid #979797;
  width: 100%;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  background-color: transparent;
  margin-top: 20px;
  flex-direction: column;
  box-sizing: border-box;
}
.care-button-first {
  display: flex;
  justify-content: space-between;
}
.care-button {
  max-height: 210px;
}
input[type="checkbox"] {
  appearance: none;
  width: 15px;
  height: 15px;
  border: 2px solid #979797;
  background-color: transparent;
  display: grid;
  place-content: center;
  transition: background-color 0.3s, border-color 0.3s;
}

input[type="checkbox"]::before {
  content: "";
  width: 10px;
  height: 10px;
  transform: scale(0);
  transition: transform 0.3s;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
  background-color: var(--s-green-normal-active);
}

input[type="checkbox"]:checked {
  border-color: var(--s-green-normal-active);
}

input[type="checkbox"]:checked::before {
  transform: scale(1);
}
/* end categories */
.custom-multiselect {
  position: relative;
  width: 100%;
}
.multiselect-option.is-selected {
  background: var(--s-green-normal-active);
  color: #000;
}
.custom-multiselect .multiselect {
  z-index: 11;
  width: 100%;
  padding: 10px;
  height: 60px;
  margin-bottom: 24px;
  font-size: 16px;
  border: 1px solid #979797;
  border-radius: 12px;
  background-color: transparent; /* White background color */
}

.custom-multiselect .multiselect__content {
  background-color: transparent; /* White background for options */
}
.multiselect {
  background: transparent;
}
.custom-multiselect-selected .multiselect {
  border: 1px solid var(--s-green-normal-active); /* Change to desired color when a value is selected */
}

.header-container {
  display: flex;
  width: 100%;
  background-color: var(--s-pink-light);
  padding: 32px 10px;
}
.profile-image-container {
  display: flex;
  justify-content: center;
}
.profile-image {
  width: 115px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
}

.profile-image img {
  width: 100px;
  height: 100px;
  border-radius: 12px;
}

.profile-image input[type="file"] {
  display: none;
}
.edit-icon {
  position: absolute;
  bottom: 0;
  right: 0;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
}
.caregiver-rating {
  color: #ffd700;
}

.star {
  font-size: 20px;
  margin-right: 6px;
}

.star.filled {
  color: #ffcc00;
}

.star:not(.filled) {
  color: #ddd;
}
h4 {
  margin: 0px;
  font-size: 28px;
  font-weight: 700;
  line-height: 36.4px;
  letter-spacing: 0.5px;
  text-align: left;
}
.header-container-details-first-div {
  display: flex;
  gap: 27px;
}
.header-container-details-first-div-svg {
  gap: 16px;
  display: flex;
  align-items: center;
}
.header-container-details {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 16px;
  height: 100px;
}
.main-dashboard-container {
  display: flex;
  gap: 71px;
}
.category-text-class {
  font-size: 16px;
  font-weight: 400;
  line-height: 27.2px;
  letter-spacing: 0.5px;
  text-align: left;
  margin-bottom: 16px;
}
h5 {
  margin-top: 10px;
  margin-bottom: 0px;
  font-size: 16px;
  font-weight: 700;
  line-height: 34px;
  letter-spacing: 0.5px;
  text-align: left;
}
.faint-text {
  color: #979797;
}
.bio-section textarea {
  display: flex;
  flex-direction: column;
  height: 197px;
  width: 100%;
  padding: 16px;
  background-color: transparent;
  gap: 10px;
  border-radius: 12px;
  box-sizing: border-box;
  border: 1px solid #979797;
  margin-top: 16px;
}

h1 {
  color: #0a3828;
  font-size: 24px;
  font-weight: 700;
  line-height: 31.2px;
  letter-spacing: 0.5px;
  margin: 0px;
  text-align: center;
}

.caregiver-container {
  padding-top: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.care-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 16px 24px;
  border-radius: 12px;
  border: 1px solid #979797;
  box-sizing: border-box;
  transition: background-color 0.3s, border-color 0.3s;
  cursor: pointer;
}

.care-card-selected {
  background-color: var(--s-pink-light-hover);
  border-color: var(--s-green-normal-active);
}

.care-card-container {
  width: 400px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 42px;
  margin-bottom: 24px;
}

.care-card p {
  color: #0d4a34;
}

.care-card div:first-child {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
}

.care-card input[type="checkbox"] {
  appearance: none;
  width: 18px;
  height: 18px;
  border: 2px solid #979797;
  background-color: transparent;
  display: grid;
  place-content: center;
  transition: background-color 0.3s, border-color 0.3s;
}

.care-card input[type="checkbox"]::before {
  content: "";
  width: 12px;
  height: 12px;
  transform: scale(0);
  transition: transform 0.3s;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
  background-color: var(--s-green-normal-active);
}

.care-card input[type="checkbox"]:checked {
  border-color: var(--s-green-normal-active);
}

.care-card input[type="checkbox"]:checked::before {
  transform: scale(1);
}
.care-card input[type="radio"] {
  appearance: none;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 2px solid #979797;
  background-color: transparent;
  display: grid;
  place-content: center;
  transition: background-color 0.3s, border-color 0.3s;
}

.care-card input[type="radio"]::before {
  content: "";
  width: 12px;
  height: 12px;
  transform: scale(0);
  transition: transform 0.3s;
  background-color: var(--s-green-normal-active);
  border-radius: 50%;
}

.care-card input[type="radio"]:checked {
  border-color: var(--s-green-normal-active);
}

.care-card input[type="radio"]:checked::before {
  transform: scale(1);
}
</style>

