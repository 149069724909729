<template>
  <div class="input-wrapper" :class="{ 'has-value': hasValueOrFocus }">
    <label :class="{ 'has-value': hasValueOrFocus }">{{ label }}</label>
    <div class="input-container">
      <input
        v-model="value"
        @input="onInput"
        :placeholder="placeholder"
        :type="type"
        :style="{ borderColor: hasValueOrFocus ? focusColor : defaultColor }"
      />
      <!-- <i v-if="icon" :class="['input-icon', icon]">{{ icon }}</i> -->
    </div>
  </div>
</template>

<script setup>
import { ref, computed, defineProps, defineEmits, toRefs } from 'vue';

const props = defineProps({
  label: {
    type: String,
    required: true,
  },
  type: {
    type: String,
    default: 'text',
  },
  defaultColor: {
    type: String,
    default: '#979797',
  },
  focusColor: {
    type: String,
    default: '#17865F',
  },
   placeholder: {
    type: String,
    default: '',
  },
  modelValue: {
    type: String,
    default: '',
  },
});

const { label,  type, defaultColor, focusColor, modelValue } = toRefs(props);
const emits = defineEmits(['update:modelValue']);

const value = ref(modelValue.value);

const hasValueOrFocus = computed(() => value.value.length > 0);

const onInput = (event) => {
  value.value = event.target.value;
  emits('update:modelValue', value.value);
};
</script>

<style scoped>
.input-wrapper {
  /* position: relative; */
  margin: 1rem 0;
}

label {


  color: #979797;
}

.input-container {
  /* position: relative; */
  margin-top: 4px;
}

input {
  width: 100%;
  outline: none;
  transition: 0.2s;
  height: 60px;
  background-color: transparent;
  padding: 16px 10px 16px 10px;
  border-radius: 12px;
  box-sizing: border-box;
  border: 1px solid #979797;

}
input::placeholder{
    color: #979797;
font-size: 16px;
font-weight: 400;
line-height: 27.2px;
letter-spacing: 0.5px;
text-align: left;

}
.input-wrapper.has-value label,
input:focus + label {

font-size: 12px;
font-weight: 400;
line-height: 20.4px;
letter-spacing: 0.5px;
text-align: left;

  color:#17865F;
}
label{
    font-size: 12px;
font-weight: 400;
line-height: 20.4px;
letter-spacing: 0.5px;
text-align: left;

  color:#979797;
}

/* .input-wrapper.has-value input {
  border-color: var(--focusColor);
} */

.input-icon {
  position: absolute;
  right: 0.5rem;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
}
</style>
