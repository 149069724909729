<template>
  <div class="avatar" :style="avatarStyle">
    <div v-if="!src" >
      {{ initials }}
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { defineProps } from 'vue';

const props = defineProps({
  src: {
    type: String,
    default: "",
  },
  initials: {
    type: String,
    default: "AV",
  },
  width: {
    type: String,
    default: "40px"
  },
  height: {
    type: String,
    default: "40px"
  },
   fontSize: {
    type: String,
    default: "16px"
  }
});

const avatarStyle = computed(() => ({
  backgroundImage: props.src ? `url(${props.src})` : "",
  backgroundSize: props.src ? "cover" : "",
  height: props.height,
  width: props.width,
fontSize: props.fontSize
}));
</script>

<style scoped>
.avatar {
  border-radius: 50%;
  background-color: #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
  /* font-size: 16px; */
  color: white;
  overflow: hidden;
  background-position: center;
  background-size: cover;
}
</style>
