import axios from 'axios';
import { authHeader, uploadAuthHeader, authHeader2 } from '@/utils/authHeader';

const apiClient = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

class Request {
  constructor(url) {
    this.url = `${url}`;
    console.log("this.url", this.url);
  }

  async post(raw, callback) {
    const headers = await authHeader();
    try {
      const response = await apiClient.post(this.url, raw, { headers });
      callback(response.data, undefined);
    } catch (err) {
      const errorResponse = err.response ? err.response.data : { message: err.message };
      callback(undefined, errorResponse);
    }
  }

  async postFormData(raw, callback) {
    const headers = await authHeader2();
    try {
      const response = await apiClient.post(this.url, raw, { headers });
      callback(response.data, undefined);
    } catch (err) {
      const errorResponse = err.response ? err.response.data : { message: err.message };
      callback(undefined, errorResponse);
    }
  }

  async patch(raw, callback) {
    const headers = await authHeader();
    try {
      const response = await apiClient.patch(this.url, raw, { headers });
      callback(response.data, undefined);
    } catch (err) {
      const errorResponse = err.response ? err.response.data : { message: err.message };
      callback(undefined, errorResponse);
    }
  }

  async patchFormData(raw, callback) {
    const headers = await authHeader2();
    try {
      const response = await apiClient.patch(this.url, raw, { headers });
      callback(response.data, undefined);
    } catch (err) {
      const errorResponse = err.response ? err.response.data : { message: err.message };
      callback(undefined, errorResponse);
    }
  }

  async put(raw, callback) {
    const headers = await authHeader();
    try {
      const response = await apiClient.put(this.url, raw, { headers });
      callback(response.data, undefined);
    } catch (err) {
      const errorResponse = err.response ? err.response.data : { message: err.message };
      callback(undefined, errorResponse);
    }
  }

  async get(callback) {
    const headers = await authHeader();
    try {
      const response = await apiClient.get(this.url, { headers });
      callback(response.data, undefined);
    } catch (err) {
      const errorResponse = err.response ? err.response.data : { message: err.message };
      callback(undefined, errorResponse);
    }
  }

  async delete(callback, bodyData) {
    const headers = await authHeader();
    try {
      const response = await apiClient.delete(this.url, { headers, data: bodyData });
      callback(response.data, undefined);
    } catch (err) {
      const errorResponse = err.response ? err.response.data : { message: err.message };
      callback(undefined, errorResponse);
    }
  }

  async upload(raw, callback, params) {
    const headers = await uploadAuthHeader();
    try {
      const response = await apiClient.post(this.url, raw, { headers });
      callback(response.data, undefined, params);
    } catch (err) {
      const errorResponse = err.response ? err.response.data : { message: err.message };
      callback(undefined, errorResponse, params);
    }
  }
}

export default Request;
