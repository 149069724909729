<template>
  <DashboardLayout>
    <div class="overall-container">
      <div class="main-container">
        <div class="messagesss">Messages</div>
        <div class="messages-container">
          <div class="messages-container-first">
            <div class="search-wrapper">
              <input type="text" placeholder="Search" v-model="searchQuery" />
              <svg
                v-if="careType === 'caregiver'"
                class="search-icon"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M16.1372 14.0926L24 21.9554L21.9554 24L14.0926 16.1372C12.6244 17.1938 10.8542 17.8388 8.91938 17.8388C3.99314 17.8388 0 13.8456 0 8.91938C0 3.99314 3.99314 0 8.91938 0C13.8456 0 17.8388 3.99314 17.8388 8.91938C17.8388 10.8542 17.1938 12.6244 16.1372 14.0926ZM8.91938 2.74443C5.50257 2.74443 2.74443 5.50257 2.74443 8.91938C2.74443 12.3362 5.50257 15.0943 8.91938 15.0943C12.3362 15.0943 15.0943 12.3362 15.0943 8.91938C15.0943 5.50257 12.3362 2.74443 8.91938 2.74443Z"
                  fill="#979797"
                />
              </svg>
              <svg
                v-else
                class="search-icon"
                width="38"
                height="38"
                viewBox="0 0 38 38"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  y="0.253906"
                  width="37.49"
                  height="37.49"
                  rx="18.745"
                  fill="#F59CAB"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M21.76 20.5239L27.49 26.2539L26 27.7439L20.27 22.0139C19.2 22.7839 17.91 23.2539 16.5 23.2539C12.91 23.2539 10 20.3439 10 16.7539C10 13.1639 12.91 10.2539 16.5 10.2539C20.09 10.2539 23 13.1639 23 16.7539C23 18.1639 22.53 19.4539 21.76 20.5239ZM16.5 12.2539C14.01 12.2539 12 14.2639 12 16.7539C12 19.2439 14.01 21.2539 16.5 21.2539C18.99 21.2539 21 19.2439 21 16.7539C21 14.2639 18.99 12.2539 16.5 12.2539Z"
                  fill="white"
                />
              </svg>
            </div>
            <div class="messages-item-list">
              <MessageItem
                v-for="(message, index) in messages"
                :key="index"
                :name="message.name"
                :date="message.date"
                :message="message.text"
                :avatarSrc="message.avatarSrc"
                :initials="message.initials"
                :isSelected="selectedMessage === message.name"
                @select="handleSelect"
              />
            </div>
          </div>
          <div class="messages-container-second">
            <div class="messages-list">
              <div class="messages-list-header">
                <div>
                  <Avatar :initials="initials" />
                  <h2>Anita Kova</h2>
                </div>
                <div class="date-separator">
                  <p>Wednesday 7th, Apr. 2024</p>
                  <hr />
                </div>
              </div>
               <!-- <MainMessageItem
                v-for="(message, index) in mainMessages"
                :key="index"
                :name="message.name"
                :date="message.date"
                :time="message.time"
                :message="message.text"
                :avatarSrc="message.avatarSrc"
                :initials="message.initials"
                :reply="message.reply"
              /> -->
              <div v-for="(message, index) in mainMessages" :key="index">
                {{ message.message }}
              </div>
            </div>
            <div class="message-input-container">
              <input
                type="text"
                placeholder="Type message here"
                v-model="message"
                @keyup.enter="sendMessage"
              />
              <div class="button-icons">
                <div class="icons">
                  <!-- Attachment Icon -->
                  <svg
                    @click="handleAttachment"
                    width="11"
                    height="20"
                    viewBox="0 0 11 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11 14.5C11 16.0333 10.4667 17.3333 9.4 18.4C8.33333 19.4667 7.03333 20 5.5 20C3.96667 20 2.66667 19.4667 1.6 18.4C0.533334 17.3333 4.7302e-08 16.0333 6.55868e-08 14.5L1.90798e-07 4C2.03915e-07 2.9 0.391667 1.95833 1.175 1.175C1.95833 0.391666 2.9 3.45822e-08 4 4.76995e-08C5.1 6.08169e-08 6.04167 0.391666 6.825 1.175C7.60833 1.95833 8 2.9 8 4L8 13.5C8 14.2 7.75833 14.7917 7.275 15.275C6.79167 15.7583 6.2 16 5.5 16C4.8 16 4.20833 15.7583 3.725 15.275C3.24167 14.7917 3 14.2 3 13.5L3 4.75C3 4.53333 3.07067 4.354 3.212 4.212C3.354 4.07067 3.53333 4 3.75 4C3.96667 4 4.14567 4.07067 4.287 4.212C4.429 4.354 4.5 4.53333 4.5 4.75L4.5 13.5C4.5 13.7833 4.59567 14.0207 4.787 14.212C4.979 14.404 5.21667 14.5 5.5 14.5C5.78333 14.5 6.02067 14.404 6.212 14.212C6.404 14.0207 6.5 13.7833 6.5 13.5L6.5 4C6.5 3.3 6.25833 2.70833 5.775 2.225C5.29167 1.74167 4.7 1.5 4 1.5C3.3 1.5 2.70833 1.74167 2.225 2.225C1.74167 2.70833 1.5 3.3 1.5 4L1.5 14.5C1.5 15.6 1.89167 16.5417 2.675 17.325C3.45833 18.1083 4.4 18.5 5.5 18.5C6.6 18.5 7.54167 18.1083 8.325 17.325C9.10833 16.5417 9.5 15.6 9.5 14.5L9.5 4.75C9.5 4.53333 9.57067 4.354 9.712 4.212C9.854 4.07067 10.0333 4 10.25 4C10.4667 4 10.646 4.07067 10.788 4.212C10.9293 4.354 11 4.53333 11 4.75L11 14.5Z"
                      fill="#979797"
                    />
                  </svg>

                  <!-- Emoji Icon -->

                  <svg
                    @click="handleEmoji"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_281_13192)">
                      <path
                        d="M15.5 11C16.3284 11 17 10.3284 17 9.5C17 8.67157 16.3284 8 15.5 8C14.6716 8 14 8.67157 14 9.5C14 10.3284 14.6716 11 15.5 11Z"
                        fill="#979797"
                      />
                      <path
                        d="M8.5 11C9.32843 11 10 10.3284 10 9.5C10 8.67157 9.32843 8 8.5 8C7.67157 8 7 8.67157 7 9.5C7 10.3284 7.67157 11 8.5 11Z"
                        fill="#979797"
                      />
                      <path
                        d="M12 18C14.28 18 16.22 16.34 17 14H7C7.78 16.34 9.72 18 12 18Z"
                        fill="#979797"
                      />
                      <path
                        d="M11.99 2C6.47 2 2 6.48 2 12C2 17.52 6.47 22 11.99 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 11.99 2ZM12 20C7.58 20 4 16.42 4 12C4 7.58 7.58 4 12 4C16.42 4 20 7.58 20 12C20 16.42 16.42 20 12 20Z"
                        fill="#979797"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_281_13192">
                        <rect width="24" height="24" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </div>

                <button @click="sendMessage">
                  Send
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_281_13204)">
                      <path
                        d="M2.01 21L23 12L2.01 3L2 10L17 12L2 14L2.01 21Z"
                        fill="white"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_281_13204)">
                        <rect width="24" height="24" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </DashboardLayout>
</template>

<script>
import { ref, onMounted, onBeforeUnmount } from "vue";
import MessageItem from "@/components/MessageItem.vue";
import Avatar from "@/components/AvatarComponent.vue";
import DashboardLayout from "@/views/Dashboard/Caregiver/IndexPage.vue";

export default {
  name: "MessagesPage",
  components: {
    MessageItem,
    Avatar,
    DashboardLayout,
  },
  setup() {
    const careType = ref("careseeker");
    const selectedMessage = ref("");
    const searchQuery = ref("");
    const messages = ref([
      {
        name: "Taiwo A.",
        date: "12/12/2024",
        text: "Donec euismod lorem eget laoreet rutrum. Pellentesque vitae urna id urna ornare",
        avatarSrc: "path_to_image.jpg",
        initials: "TA",
      },
      {
        name: "Taiwo Awoniyi.",
        date: "12/12/2024",
        text: "Donec euismod lorem eget laoreet rutrum. Pellentesque vitae urna id urna ornare",
        avatarSrc: "",
        initials: "AV",
      },
    ]);

    const mainMessages = ref([]);

    const userProfile = {
      username: "testokay833",
      type: "Care giver",
    };
    const otherUserProfile = {
      username: "djyk621",
      type: "Care seeker",
    };

    const userToken = localStorage.getItem("_user");
    const user = JSON.parse(localStorage.getItem("_userProfile"));
    const roomName =
      userProfile.type === "Care giver" ? `${otherUserProfile.username}_${userProfile.username}` : `${userProfile.username}_${otherUserProfile.username}`;
    console.log("roomie", roomName);
    const socketUrl = `ws://api.succour.care/ws/chat/${roomName}/?token=${userToken}`;
    let socket;

    const message = ref("");

    const sendMessage = () => {
      const msg = {
        message: message.value,
        recipient:
          user.type === "Care giver"
            ? otherUserProfile.username
            : userProfile.username,
        // sender: user.type === "Care giver" ? userProfile.username : otherUserProfile.username,
        // type: user.type === "Care giver" ? userProfile.type : otherUserProfile.type,
        room: roomName,
        // date: new Date().toLocaleString(),
      };
      console.log("Sending message:", msg);
      socket.send(JSON.stringify(msg));
        // if (roomName) {
        //   mainMessages.value.push(msg);
        // }
     // mainMessages.value.push(msg);
      message.value = "";
    };

    const handleSelect = (val) => {
      console.log("Selected message:", val);
      selectedMessage.value = val;
    };

    onMounted(() => {
      console.log("Connecting to WebSocket:", socketUrl);
      socket = new WebSocket(socketUrl);

      socket.addEventListener("open", () => {
        console.log("WebSocket connection opened.");
        socket.send(JSON.stringify({ type: "join", room: roomName }));
      });

      socket.addEventListener("message", (e) => {
        const msg = JSON.parse(e.data);
        console.log("msg", e);
        console.log("msg room:", msg.room);
        console.log("roomname:", roomName);
        if (roomName) {
          mainMessages.value.push(msg);
          console.log("main", mainMessages);
        }
      });

      socket.addEventListener("error", (e) => {
        console.error("WebSocket error:", e);
      });

      socket.addEventListener("close", () => {
        console.log("WebSocket connection closed.");
      });
    });

    onBeforeUnmount(() => {
      if (socket) {
        socket.send(JSON.stringify({ type: "leave", room: roomName }));
        socket.close();
      }
    });

    return {
      messages,
      mainMessages,
      handleSelect,
      selectedMessage,
      careType,
      searchQuery,
      message,
      sendMessage,
    };
  },
};
</script>

<style scoped>
.overall-container {
  display: flex;
  width: 100%;
  justify-content: center;
}
.messagesss {
  margin-bottom: 8px;
  font-size: 28px;
  font-weight: 700;
  line-height: 36.4px;
  letter-spacing: 0.5px;
  text-align: left;
  width: 1184px;
  box-sizing: border-box;
}
.main-container {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-left: 128px;
  padding-right: 128px;
  align-items: center;
  margin-top: 33px;
  box-sizing: border-box;
}
.messages-container {
  display: flex;
  width: 100%;
  width: 1184px;
  gap: 35px;
  box-sizing: border-box;
}
.messages-container-first {
  display: flex;
  flex-direction: column;
  width: 380px;
  box-sizing: border-box;
}
.messages-container-second {
  display: flex;
  flex-direction: column;
  width: 790px;
  box-sizing: border-box;
}
.search-wrapper input {
  border: 1px solid #979797 !important;
  width: 575px;
  height: 64px;
  padding: 10px 10px 10px 70px;
  gap: 16px;
  border-radius: 12px;
  border: 1px;
  box-sizing: border-box;
  background-color: transparent;
}
.search-wrapper input::placeholder {
  font-size: 16px;
  font-weight: 400;
  line-height: 27.2px;
  letter-spacing: 0.5px;
  color: #979797;
}

.search-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 32px;
}
.search-icon {
  position: absolute;
  left: 20px;
}
.messages-list {
  padding: 20.5px;
  background-color: #ececec80;
}
.messages-list-header {
  display: flex;
  flex-direction: column;
  margin-bottom: 22px;
}
.messages-list-header div:first-child {
  display: flex;
  align-items: center;
  gap: 8px;
}
.messages-list-header h2 {
  font-size: 20px;
  font-weight: 700;
  line-height: 34px;
  letter-spacing: 0.5px;
  text-align: left;
}
.messages-list-header p {
  font-size: 12px;
  font-weight: 400;
  line-height: 20.4px;
  letter-spacing: 0.5px;
  text-align: left;
}
.date-separator {
  display: flex;
  align-items: center;
  width: 100%;
  color: #333;
}

.date-separator hr {
  flex-grow: 1;
  border: 0;
  margin-left: 15px;
  border-top: 1px solid #ccc;
}
.message-input-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: transparent;
  padding: 10px;
  width: 100%;
  box-sizing: border-box;
  margin-top: 35px;
}

.message-input-container input[type="text"] {
  flex-grow: 1;
  border: none;
  outline: none;
  padding: 10px;
  font-size: 16px;
  width: 100%;
  background-color: transparent;
}
.message-input-container input[type="text"]::placeholder {
  color: #979797;
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 23.8px;
  letter-spacing: 0.5px;
  text-align: left;
}

.message-input-container button {
  background-color: var(--s-green-normal);
  color: white;
  border: none;
  border-radius: 40px;
  padding: 10px;
  margin-left: 10px;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 18px;
  width: 173px;
  height: 48px;
}

.message-input-container button:hover {
  background-color: var(--s-green-normal-hover);
}

.message-input-container .icons {
  display: flex;
  align-items: center;
  margin-right: 10px;
}

.message-input-container .icons svg {
  margin: 0 5px;
  cursor: pointer;
  fill: #888;
}

.message-input-container .icons svg:hover {
  fill: #555;
}
.button-icons {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.messages-item-list {
  background-color: var(--s-pink-light);
  height: 100%;
  padding: 10px;
  box-sizing: border-box;
}
</style>
