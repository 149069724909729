<template>
  <div :class="['toggle-switch', { 'toggle-switch--on': isOn }]" @click="toggle">
    <div class="toggle-switch__thumb"></div>
  </div>
</template>

<script>
import { ref } from "vue";
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const isOn = ref(props.value);

    const toggle = () => {
      isOn.value = !isOn.value;
      emit('update:value', isOn.value);
    };

    return {
      isOn,
      toggle,
    };
  },
};
</script>

<style scoped>
.toggle-switch {
  width: 52px;
  height: 32px;
  background-color: #ccc;
  border-radius: 15px;
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  transition: background-color 0.3s;
}

.toggle-switch--on {
  background-color: var(--s-green-normal);
}

.toggle-switch__thumb {
  width: 24px;
  height: 24px;
  background-color: #fff;
  border-radius: 50%;
  position: absolute;
  left: 5px;
  transition: left 0.3s;
}

.toggle-switch--on .toggle-switch__thumb {
  left: 21px;
}
</style>
