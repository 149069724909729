<template>
  <LoginLayout>
    <!-- Add your login form here -->
    <div class="login-form">
      <!-- Your login form content -->

      <div class="login-container">
        <h2>Forgot Password</h2>
        <p>Get a secure one-time link to reset your password</p>
        <form @submit.prevent="handleLogin">
          <div class="input-group">
            <label for="email">Email</label>
            <input
              type="email"
              id="email"
              v-model="email"
              placeholder="Enter Email address"
              required
            />
          </div>
         
          <button type="submit" class="login-button" :disabled="sendLink">  {{ sendLink ? 'Sending link ...' : 'Send Link' }}</button>
      
         
        </form>
      </div>
    </div>
  </LoginLayout>
</template>

<script>
import LoginLayout from "@/layouts/LoginLayout.vue";
import { ref } from "vue";
export default {
  name: "ForgotPassword",
  components: {
    LoginLayout,
  },

  setup() {
    const email = ref("");
     const sendLink = ref(false);

    const handleLogin = () => {
        if (sendLink.value) return;
      sendLink.value = true;
      // Handle login logic here
      console.log("Email:", email.value);
  
    };

    return {
      email,
      handleLogin,
      sendLink
    };
  },
};
</script>

<style scoped>
.login-container {
  max-width: 400px;
  margin: auto;
}

h2 {
  margin-bottom: 20px;
margin-top: 32px;
  font-size: 24px;
  font-weight: 700;
  line-height: 29.05px;
  letter-spacing: 0.5px;
  text-align: center;
}

.input-group {
  margin-bottom: 32px;
  text-align: left;
}

.input-group label {
  display: block;
  margin-bottom: 5px;
  color: #979797;
  font-size: 12px;
  font-weight: 400;
  line-height: 20.4px;
  letter-spacing: 0.5px;
  text-align: left;
}

.input-group input {
  width: 100%;
  padding: 16px 10px;
  height: 60px;
  border: 1px solid #979797;
  border-radius: 12px;
  background-color: transparent;
  box-sizing: border-box;
}
.input-group input::placeholder {
  font-size: 16px;
  font-weight: 400;
  line-height: 27.2px;
  letter-spacing: 0.5px;
  text-align: left;
  color: #979797;
}

.login-button {
  width: 100%;
  background-color: var(--s-pink-normal);
  border: none;
  border-radius: 40px;
  color: white;
  height: 60px;
  font-size: 16px;
  cursor: pointer;
  margin-bottom: 10px;
  padding: 16px 24px 16px 24px;
}
.login-button:disabled{
   width: 100%;
  background-color: var(--s-pink-normal);
  border: none;
  border-radius: 40px;
  color: white;
  font-size: 16px;
  cursor: pointer;
  margin-bottom: 10px;
  padding: 16px 24px 16px 24px;
   cursor: not-allowed;
  opacity: 0.6;
}
.login-button:hover {
  background-color: var(--s-pink-normal-hover);
}

.forgot-password {
  display: block;
  margin-bottom: 34px;
  color: var(--s-green-normal);
  text-decoration: none;
  text-align: center;

  font-size: 14px;
  font-weight: 400;
  line-height: 23.8px;
  letter-spacing: 0.5px;
  text-align: center;
}

.forgot-password:hover {
  color: var(--s-green-normal-hover);
}



.google-login,
.apple-login {
  width: 100%;
  padding: 10px 67px;
  border-radius: 12px;
  border: 1px solid var(--s-green-normal);
  color: #000;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  gap: 10px;
  font-size: 20px;
  font-weight: var(--font-weight-regular);
  line-height: 34px;
  letter-spacing: 0.5px;
  text-align: left;
  background-color: transparent;
}

.google-login img,
.apple-login img {
  width: 40.67px;
  height: 40.67px;
}

/* .google-login:hover,
.apple-login:hover {
  background-color: #f5f5f5;
} */
.separator {
  display: flex;
  align-items: center;
  text-align: center;
  color: #000;

  font-size: 20px;
  font-weight: 400;
  line-height: 34px;
  letter-spacing: 0.5px;
  text-align: left;
  margin-bottom: 16px;
}
.separator::before,
.separator::after {
  content: "";
  flex: 1;
  border-bottom: 1px solid #979797;
}
.separator:not(:empty)::before {
  margin-right: 8px;
}
.separator:not(:empty)::after {
  margin-left: 8px;
}
.join-now:hover{
   background-color: var(--s-pink-normal-hover);
}
.login-container p{
  margin-bottom: 32px;
  color: var(--s-green-darker);
font-size: 14px;
font-weight: 400;
line-height: 23.8px;
letter-spacing: 0.5px;
text-align: center;

}
.login-form{
  padding-bottom: 500px;
}
</style>

