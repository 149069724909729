<template>
  <div class="caregiver-container">
    <div class="header-container">
      <h1>What kind of job are you looking for?</h1>
      <p class="text">Please select only one option</p>
    </div>
    <div class="care-card-container">
      <div
        class="care-card"
        :class="{ 'care-card-selected': localData.type_of_care === 'Tutor' }"
        @click="setCare('Tutor')"
      >
        <img src="@/assets/academic_care.svg" />
        <p>Tutor</p>
      </div>
      <div
        class="care-card"
        :class="{ 'care-card-selected': localData.type_of_care === 'Child care' }"
        @click="setCare('Child care')"
      >
        <img src="@/assets/child_care.svg" />
        <p>Child Care</p>
      </div>
      <div
        class="care-card"
      :class="{ 'care-card-selected': localData.type_of_care === 'Elderly care' }"
        @click="setCare('Elderly care')"
      >
        <img src="@/assets/senior_care.svg" />
        <p>Elderly Care</p>
      </div>
      <div
        class="care-card"
        :class="{ 'care-card-selected': localData.type_of_care === 'Housekeeper' }"
        @click="setCare('Housekeeper')"
      >
        <img src="@/assets/house_care.svg" />
        <p>Housekeeper</p>
      </div>
    </div>
    <div>
      <BaseButton
        label="Next"
        :disabled="disabled"
        backgroundColor="var(--s-pink-normal)"
        textColor="var(--state-white)"
        disabledBackgroundColor="#ECECEC"
        disabledColor="#ECECEC"
        disabledTextColor="#979797"
        height="60px"
        width="400px"
        @click="handleClick"
      />
    </div>
    <div></div>
  </div>
</template>

<script>
import BaseButton from "@/components/BaseButton.vue";
import { ref, inject, watch } from "vue";
import { useRouter } from "vue-router";

export default {
  name: "CareGiverStepOne",
  components: {
    BaseButton,
  },
  setup() {
    const formData = inject("formData");
    const updateFormData = inject("updateFormData");

    const localData = ref({
      ...formData.value.stepOneData,
    });

    const router = useRouter();
    const disabled = ref(true);

    const setCare = (care) => {
      localData.value.type_of_care = care;
      disabled.value = false; // Enabling the button after selection
    };

    const handleClick = () => {
      console.log("BaseButton clicked");
      console.log("values", disabled.value + " selected: " + localData.value.selectedCare);
      updateFormData("stepOneData", localData.value);
      console.log("formdata here", formData);
      router.push("/caregiver-onboarding/create-account/step-2");
    };

    watch(
      localData,
      (newValue) => {
        updateFormData("stepOneData", newValue);
      },
      { deep: true }
    );

    return {
      disabled,
      localData,
      handleClick,
      setCare,
    };
  },
};
</script>

<style scoped>
h1 {
  color: #0a3828;
  font-size: 24px;
  font-weight: 700;
  line-height: 31.2px;
  letter-spacing: 0.5px;
  margin: 0px;
  text-align: center;
}

.text {
  color: #979797;
  font-size: 16px;
  font-weight: 400;
  line-height: 27.2px;
  letter-spacing: 0.5px;
  text-align: center;
}

.caregiver-container {
  padding-top: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.care-card {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 16px 24px;
  gap: 16px;
  border-radius: 12px;
  border: 1px solid #979797;
  box-sizing: border-box;
  transition: background-color 0.3s, border-color 0.3s;
}

.care-card-selected {
  background-color: var(--s-pink-light-hover);
  border-color: var(--s-green-normal-active);
}

.care-card-container {
  width: 400px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 42px;
  margin-bottom: 24px;
  cursor: pointer;
}

.care-card p {
  color: #0d4a34;
}
@media screen and (max-width: 800px) {
 
.care-card-container{
  width: 350px !important;
  margin-top: 16px
}
.base-button{
  width: 350px !important;
}
h1{
  font-size: 20px;
  max-width: 350px;
 text-align: left;
}
.text{
  text-align: left;
}
.header-container{
  width: 100%;
  padding-left: 20px
}
.caregiver-container{
  padding-top: 29px
}
}
</style>
