<template>
  <div class="rate-container">
  
    <div class="rate-input">
     
      <div class="slider-container">
        <div class="slider-track" :style="sliderStyle"></div>
        <input
          type="range"
          v-model="minRate"
          :min="10000"
          :max="100000"
          :step="1000"
          @input="updateFormattedRate"
          class="slider min-slider"
        />
        <input
          type="range"
          v-model="maxRate"
          :min="10000"
          :max="100000"
          :step="1000"
          @input="updateFormattedRate"
          class="slider max-slider"
        />
        <div class="slider-value" :style="{ left: minRatePosition }">N {{ formattedMinRate }}</div>
        <div class="slider-value" :style="{ left: maxRatePosition }">N {{ formattedMaxRate }}</div>
      </div>
      <div class="rate-display-container">
        <div class="rate-display">
          <span>N {{ formattedMinRate }}</span>
        </div>
        <div>
          <span>-</span>
        </div>
        <div class="rate-display">
          <span>N {{ formattedMaxRate }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, watch } from "vue";

export default {
  name: "RateSlider",
  props: {
    initialMinRate: {
      type: Number,
      default: 10000,
    },
    initialMaxRate: {
      type: Number,
      default: 100000,
    },
  },
  emits: ["update:rates"],
  setup(props, { emit }) {
    const minRate = ref(props.initialMinRate);
    const maxRate = ref(props.initialMaxRate);
    const formattedMinRate = ref(minRate.value.toLocaleString());
    const formattedMaxRate = ref(maxRate.value.toLocaleString());

    const updateFormattedRate = () => {
      if (minRate.value > maxRate.value) {
        minRate.value = maxRate.value - 1000; // Ensure minRate stays below maxRate
      }
      if (maxRate.value < minRate.value) {
        maxRate.value = minRate.value + 1000; // Ensure maxRate stays above minRate
      }
      formattedMinRate.value = minRate.value.toLocaleString();
      formattedMaxRate.value = maxRate.value.toLocaleString();
      emit("update:rates", { minRate: minRate.value, maxRate: maxRate.value });
    };

    const sliderStyle = computed(() => {
      const minPercentage = ((minRate.value - 10000) / (100000 - 10000)) * 100;
      const maxPercentage = ((maxRate.value - 10000) / (100000 - 10000)) * 100;
      return {
        background: `linear-gradient(to right, #e0e0e0 ${minPercentage}%, var(--s-green-normal) ${minPercentage}%, var(--s-green-normal) ${maxPercentage}%, #e0e0e0 ${maxPercentage}%)`,
      };
    });

    const minRatePosition = computed(() => {
      const percentage = ((minRate.value - 10000) / (100000 - 10000)) * 100;
      return `calc(${percentage}% - 12px)`; // Adjust as needed
    });

    const maxRatePosition = computed(() => {
      const percentage = ((maxRate.value - 10000) / (100000 - 10000)) * 100;
      return `calc(${percentage}% - 12px)`; // Adjust as needed
    });

    watch([minRate, maxRate], updateFormattedRate);

    return {
      minRate,
      maxRate,
      formattedMinRate,
      formattedMaxRate,
      sliderStyle,
      minRatePosition,
      maxRatePosition,
    };
  },
};
</script>

<style scoped>
/* Your styles here */
.rate-container {
  display: flex;
  flex-direction: column;
  align-items: center;
 
}

h1 {
  color: #0a3828;
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 32px;
}

.rate-input {
  width: 100%;
  max-width: 400px;
  text-align: center;
  margin-bottom: 32px;
}

.rate-input label {
  display: block;
  font-size: 16px;
  color: #000000;
  margin-bottom: 56px;
  font-size: 14px;
  font-weight: 400;
  line-height: 23.8px;
  letter-spacing: 0.5px;
  text-align: left;
}

.slider-container {
  position: relative;
  width: 100%;
  height: 50px; /* Adjust height to ensure track visibility */
}

.slider-track {
  position: absolute;
  width: calc(100% - 24px); /* Adjust width to fit within the container */
  height: 10px;
  top: 50%;
  left: 12px; /* Center the track within the container */
  transform: translateY(-50%);
  background: #e0e0e0;
  border-radius: 5px;
}

.slider {
  -webkit-appearance: none;
  width: calc(100% - 24px); /* Adjust width to fit within the container */
  height: 10px;
  background: transparent;
  position: absolute;
  top: 50%;
  left: 12px; /* Center the slider within the container */
  transform: translateY(-50%);
  pointer-events: none; /* Disable pointer events for the slider itself */
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: #17865f;
  cursor: pointer;
  pointer-events: auto; /* Enable pointer events for the thumb */
  box-shadow: 0 0 0 4px #ffffff, 0 0 0 8px #b7d9cd;
}

.slider::-moz-range-thumb {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: #17865f;
  cursor: pointer;
  pointer-events: auto; /* Enable pointer events for the thumb */
  box-shadow: 0 0 0 4px #ffffff, 0 0 0 8px #b7d9cd;
}

.min-slider {
  z-index: 2;
}

.max-slider {
  z-index: 3;
}

.slider-value {
  position: absolute;
  top: -30px;
  background-color: var(--s-green-normal);
  color: white;
  font-size: 14px;
  white-space: nowrap;
  padding: 10px;
  gap: 10px;
  border-radius: 12px;
  transform: translateX(-50%);
}

.rate-display-container {
  width: 100%;
  display: flex;
  justify-content:space-between;
  align-items: center;
  margin-top:13px

}

.rate-display {
  font-size: 18px;
  color: #0a3828;
max-width: 121px;
 
  border: 1px solid #979797;
  border-radius: 5px;

  padding: 14.5px 37.5px 14.5px 12px;
  gap: 10px;
  border-radius: 12px;
}

.rate-info {
  display: flex;
  align-items: center;
  background-color: #0063f71a;
  color: #000000;
  padding: 10px 24px;
  border-radius: 12px;
  margin-bottom: 24px;
  max-width: 400px;
  text-align: left;
  gap: 16px;
  box-sizing: border-box;
}

.info-icon {
  background-color: #007bff;
  color: white;
  font-size: 14px;
  font-weight: bold;
  padding: 5px;
  border-radius: 50%;
  margin-right: 10px;
}

.next-button {
  background-color: #ff6978;
  color: white;
  font-size: 16px;
  padding: 10px 20px;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.next-button:disabled {
  background-color: #ececec;
  color: #979797;
  cursor: not-allowed;
}

.average-text {
  font-size: 14px;
  font-weight: 400;
  line-height: 23.8px;
  letter-spacing: 0.5px;
  text-align: left;
  color: #000000;
}
label{
  
font-size: 14px;
font-weight: 400;
line-height: 23.8px;
letter-spacing: 0.5px;
text-align: left;

}
</style>
