<template>
  <div class="class-container">
    <!-- start -->
    <LoadingSpinner :isLoading="isLoading" />
    <ToastManagerVue ref="toastManager" />
    <div v-if="!isCareTypeChosen" class="content-container">
      <h1>Dashboard</h1>
      <div class="content-container-categories">
        <div class="content-container-categories-main">
          <div class="carecategory-number">
            <h4>Careseekers</h4>
            <p class="text">{{ dashboardData.care_seeker_count }}</p>
          </div>
          <div class="svg-div">
            <svg
          
                 :width="isLargeScreen ? '77' : '70'"
              :height="isLargeScreen ? '69' : '50'"
              viewBox="0 0 77 69"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M38.0375 60.0185C40.3007 60.076 42.5065 59.7383 44.6475 59.0271C45.2678 58.8211 45.6846 58.8857 46.154 59.3671C47.9885 61.2495 49.8493 63.108 51.7294 64.9449C52.2371 65.4407 52.2371 65.7592 51.7006 66.231C47.6364 69.8066 43.4501 73.2194 38.9045 76.1772C38.3345 76.5484 37.8771 76.6154 37.2831 76.2131C25.1743 68.0104 14.6798 58.2056 7.04231 45.6011C3.73252 40.1382 1.43099 34.2658 0.439483 27.9193C-0.5137 21.8098 -0.0754273 15.9159 3.34454 10.568C7.34647 4.31002 13.0608 0.722407 20.4994 0.0997248C27.0161 -0.44632 32.6729 1.6229 37.4627 6.05113C37.9705 6.52054 38.2243 6.45348 38.689 6.02C42.4897 2.46592 46.9898 0.514048 52.1868 0.075775C63.5795 -0.882198 74.2322 7.342 75.8799 19.137C76.6007 24.2909 75.7074 29.3155 74.1962 34.2371C72.3258 40.325 69.2722 45.8166 65.6008 50.9873C65.1913 51.562 64.9063 51.6698 64.3722 51.119C62.7293 49.4258 61.0576 47.7589 59.3668 46.1112C58.9142 45.6705 58.8663 45.2706 59.0435 44.6838C62.2288 34.1293 57.7478 23.3066 47.9621 18.3707C35.1253 11.8924 19.9103 19.1802 16.6244 32.975C13.626 45.5627 22.128 57.99 34.9121 59.8341C35.9539 59.985 36.9957 59.9778 38.0375 60.0233V60.0185Z"
                fill="#E8F3EF"
              />
              <path
                d="M37.9203 22.1438C46.4726 22.151 53.382 28.6628 53.9137 37.0067C54.1148 40.1681 53.4514 43.1234 51.866 45.8488C51.4732 46.5242 51.5235 46.8954 52.0672 47.4343C57.0917 52.4181 62.0876 57.4307 67.093 62.4337C67.6933 63.0341 67.6933 63.6344 67.093 64.2347C66.1015 65.2286 65.074 66.189 64.128 67.2236C63.5724 67.8319 63.2228 67.8199 62.6384 67.2308C57.5923 62.1416 52.5078 57.0906 47.4617 52.0014C46.9492 51.4841 46.6091 51.4266 45.9577 51.7907C35.9876 57.3397 24.0297 51.5344 22.3197 40.2207C20.9857 31.3954 27.3347 23.5472 35.693 22.3114C36.4929 22.1941 37.2928 22.1246 37.9155 22.1438H37.9203ZM28.7309 38.0174C28.7238 43.2144 32.8502 47.4031 37.9969 47.4199C43.2107 47.4391 47.3827 43.2815 47.3947 38.0557C47.4066 32.8348 43.3209 28.7035 38.1263 28.6843C32.8622 28.6652 28.7381 32.7629 28.7309 38.0174Z"
                fill="#E8F3EF"
              />
            </svg>
          </div>
        </div>
        <div class="content-container-categories-main">
          <div class="carecategory-number">
            <h4>Caregivers</h4>
            <p class="text">{{ dashboardData.care_giver_count }}</p>
          </div>
          <div class="svg-div">
            <svg
              :width="isLargeScreen ? '98' : '70'"
              :height="isLargeScreen ? '73' : '50'"
              viewBox="0 0 98 73"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M43.3952 63.1723C46.5245 65.0691 49.7369 66.1563 53.2087 66.4365C58.5384 66.8647 63.3802 65.3908 67.6201 62.2434C73.9306 57.5598 80.7082 53.6573 87.5584 49.8559C88.5781 49.2902 89.5615 48.7038 90.7525 48.5144C93.8507 48.024 96.9671 50.1309 97.5716 53.1902C97.9401 55.0558 97.2188 56.553 95.9447 57.8348C92.0058 61.7971 87.657 65.2663 82.9994 68.3359C75.3396 73.3854 67.0674 76.9688 58.1076 79.1068C50.8137 80.8479 43.9453 79.9268 37.1963 76.9843C34.586 75.8452 31.9834 74.6931 29.482 73.3283C27.8836 72.4564 26.2489 72.2151 24.5468 72.9287C23.8825 73.2063 23.6879 72.786 23.4518 72.3423C20.7999 67.4122 18.1428 62.4847 15.4884 57.5572C14.3674 55.4762 13.2802 53.3796 12.123 51.3219C11.7623 50.681 11.8349 50.2892 12.4032 49.8559C17.9067 45.655 23.9655 42.7332 30.9922 42.658C36.4827 42.5983 41.5555 44.0721 45.4685 48.3042C46.7088 49.6457 48.3876 50.1958 50.0716 50.6758C53.3514 51.61 56.7194 52.0096 60.1082 52.2794C61.8778 52.4221 63.6631 52.4922 65.3419 53.185C67.8977 54.2411 68.383 56.2235 66.5614 58.3253C64.6335 60.549 62.0984 61.8282 59.3609 62.726C54.4749 64.327 49.5241 64.4567 44.5317 63.2501C44.28 63.1905 44.031 63.1256 43.7767 63.0763C43.7222 63.0659 43.6573 63.1048 43.3978 63.1775L43.3952 63.1723Z"
                fill="#E8F3EF"
              />
              <path
                d="M34.2875 16.7752C34.2927 9.52023 38.1901 3.41472 44.3319 1.0872C49.4151 -0.838127 54.2492 -0.212783 58.6759 3.01512C59.1455 3.35763 59.4517 3.50034 60.0044 3.08259C67.1348 -2.31196 77.213 -0.311387 81.9355 7.40808C85.6797 13.5266 85.2879 21.1111 80.9339 27.2374C78.4922 30.6703 75.4096 33.4882 72.2647 36.2361C69.4131 38.7271 66.5069 41.1558 63.6267 43.613C60.9255 45.9172 57.8819 45.9821 55.1885 43.652C50.6372 39.7131 45.8836 36.0026 41.6282 31.7341C38.7973 28.8929 36.3141 25.7973 35.0712 21.8895C34.5185 20.1484 34.2045 18.3736 34.2849 16.7778L34.2875 16.7752Z"
                fill="#E8F3EF"
              />
              <path
                d="M9.21952 51.6614C9.71253 51.6822 9.78518 52.0844 9.94605 52.3776C12.1542 56.4047 14.3572 60.437 16.5575 64.4667C18.1533 67.391 19.731 70.3257 21.3553 73.2344C21.7367 73.9169 21.6641 74.2879 20.9791 74.7031C18.3765 76.2755 15.7947 77.8843 13.2336 79.5216C12.5279 79.9731 12.2087 79.82 11.835 79.1142C7.98439 71.8566 4.11299 64.6094 0.215629 57.3751C-0.173588 56.6512 -0.0438493 56.332 0.669715 55.9792C3.30861 54.674 5.91636 53.3117 8.53969 51.9702C8.76803 51.8535 9.00675 51.7523 9.21952 51.6562V51.6614Z"
                fill="#E8F3EF"
              />
            </svg>
          </div>
        </div>
      </div>
      <div class="requests-reports">
        <div class="requests">
          <div class="toggle-header">
            <h3>
              Caregiver Requests ({{ dashboardGeneral.requestCaregiverNumber }})
            </h3>
            <div class="toggle-control">
              <span
                v-if="!showCaregiverRequests"
                @click="toggleCaregiverRequests"
                class="toggle-icon"
              >
                <svg
                  width="12"
                  height="8"
                  viewBox="0 0 12 8"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12 1.99594L10.59 0.585937L6 5.16594L1.41 0.585937L-6.16331e-08 1.99594L6 7.99594L12 1.99594Z"
                    fill="#17865F"
                  />
                </svg>
              </span>
              <span v-else @click="toggleCaregiverRequests" class="toggle-icon">
                <svg
                  width="12"
                  height="8"
                  viewBox="0 0 12 8"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.68141e-08 6.29312L1.41 7.70313L6 3.12313L10.59 7.70313L12 6.29312L6 0.293124L1.68141e-08 6.29312Z"
                    fill="#17865F"
                  />
                </svg>
              </span>
            </div>
          </div>
          <div v-if="showCaregiverRequests">
            <div
              class="report-item"
              v-if="dashboardGeneral.caregiverRequests.length === 0"
            >
              <p>No requests available</p>
            </div>
            <div
              class="request-item"
              v-for="(request, index) in dashboardGeneral.caregiverRequests"
              :key="index"
            >
              <p>
                {{ request.user.first_name }}
                {{ request.user.last_name.charAt(0) }} requests approval
              </p>
              <div class="view-request" @click="viewCaregiverRequest(request)">
                View request
              </div>
            </div>
          </div>
        </div>
        <div class="requests">
          <div class="toggle-header">
            <h3>
              Careseeker Requests ({{
                dashboardGeneral.requestCareseekerNumber
              }})
            </h3>
            <div class="toggle-control">
              <span
                v-if="!showCareseekerRequests"
                @click="toggleCareseekerRequests"
                class="toggle-icon"
              >
                <svg
                  width="12"
                  height="8"
                  viewBox="0 0 12 8"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12 1.99594L10.59 0.585937L6 5.16594L1.41 0.585937L-6.16331e-08 1.99594L6 7.99594L12 1.99594Z"
                    fill="#17865F"
                  />
                </svg>
              </span>
              <span
                v-else
                @click="toggleCareseekerRequests"
                class="toggle-icon"
              >
                <svg
                  width="12"
                  height="8"
                  viewBox="0 0 12 8"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.68141e-08 6.29312L1.41 7.70313L6 3.12313L10.59 7.70313L12 6.29312L6 0.293124L1.68141e-08 6.29312Z"
                    fill="#17865F"
                  />
                </svg>
              </span>
            </div>
          </div>
          <div v-if="showCareseekerRequests">
            <div
              class="report-item"
              v-if="dashboardGeneral.careseekerRequests.length === 0"
            >
              <p>No requests available</p>
            </div>
            <div
              class="request-item"
              v-for="(request, index) in dashboardGeneral.careseekerRequests"
              :key="index"
            >
              <p>
                {{ request.user.first_name }}
                {{ request.user.last_name.charAt(0) }} requests approval
              </p>
              <div class="view-request" @click="viewCareseekerRequest(request)">
                View request
              </div>
            </div>
          </div>
        </div>
        <div class="reports">
          <div class="toggle-header">
            <h3>Reports ({{ reports.length }})</h3>
            <div class="toggle-control">
              <span
                v-if="!showReports"
                @click="toggleReports"
                class="toggle-icon"
              >
                <svg
                  width="12"
                  height="8"
                  viewBox="0 0 12 8"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12 1.99594L10.59 0.585937L6 5.16594L1.41 0.585937L-6.16331e-08 1.99594L6 7.99594L12 1.99594Z"
                    fill="#17865F"
                  />
                </svg>
              </span>
              <span v-else @click="toggleReports" class="toggle-icon">
                <svg
                  width="12"
                  height="8"
                  viewBox="0 0 12 8"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.68141e-08 6.29312L1.41 7.70313L6 3.12313L10.59 7.70313L12 6.29312L6 0.293124L1.68141e-08 6.29312Z"
                    fill="#17865F"
                  />
                </svg>
              </span>
            </div>
          </div>
          <div v-if="showReports">
            <div class="report-item" v-if="reports.length === 0">
              <p>No reports available</p>
            </div>
            <div
              class="report-item"
              v-for="(report, index) in reports"
              :key="index"
            >
              <p>{{ report.description }}</p>
              <router-link @click.prevent="viewReport(report)"
                >View report</router-link
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end -->
    <div v-if="isCareTypeChosen" class="caregiver-details">
      <div @click="backtoDashboard" class="caregiver-details-first-div">
        <svg
          width="8"
          height="12"
          viewBox="0 0 8 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.70492 1.41L6.29492 0L0.294922 6L6.29492 12L7.70492 10.59L3.12492 6L7.70492 1.41Z"
            fill="black"
          />
        </svg>
        <p>Back to Dashboard</p>
      </div>

      <!-- start -->
      <div class="main-container">
        <div class="main-container-section">
          <div class="image-header">
            <div class="profile-section">
              <div v-if="isCareGiverChosen" class="profile-image">
                <!-- <img src="@/assets/woman.png" alt="Profile Image" /> -->
                <img :src="jobDetails.profile_picture" alt="Profile Image" />
              </div>
              <div>
                <div>
                  <span class="span-header"> Name: </span>
                  <span class="span-normal"> {{ jobDetails.name }}</span>
                </div>
                <div>
                  <span class="span-header"> Gender: </span>
                  <span class="span-normal"> {{ jobDetails.gender }}</span>
                </div>
                <div v-if="isCareGiverChosen">
                  <span class="span-header"> Location: </span>
                  <span class="span-normal">{{ jobDetails.location }} </span>
                </div>
                <div>
                  <span class="span-header"> Email address: </span>
                  <span class="span-normal">{{ jobDetails.email }}</span>
                </div>
              </div>
            </div>
            <div v-if="isCareGiverChosen" class="the-div">Caregiver</div>
            <div v-else class="the-div">Careseeker</div>
          </div>

          <h2  v-if="isCareGiverChosen">Bio</h2>

          <div v-if="isCareGiverChosen" class="content-section">
            <p>{{ jobDetails.description }}</p>
          </div>
          <!-- <div class="content-section">
              <p class="header-text">Categories</p>
             
               <div class="job-tag">
                  <span
                    v-for="sub_care in jobDetails.sub_care_categories"
                    :key="sub_care"
                    >{{ sub_care }}</span
                  >
                </div>
            </div> -->
          <div  v-if="isCareGiverChosen" class="content-section">
            <p class="header-text">Categories</p>
            <!-- <p v-else class="header-text">Care services needed</p> -->
            <div class="jobs-list-item-type-of-care">
              <!-- <h4>Senior Care Needed</h4> -->
              <p>{{ jobDetails.categories }}</p>
              <div class="job-tag">
                <span
                  v-for="sub_care in jobDetails.sub_care_categories"
                  :key="sub_care"
                  >{{ sub_care.name }}</span
                >
              </div>
            </div>
          </div>

          <div  v-if="isCareGiverChosen" class="content-section">
            <div class="jobs-list-item-type-of-care-container">
              <div style="width: 300px">
                <p class="header-text">Availability</p>
                <span>{{ jobDetails.availability }}</span>
                <!-- <span>Mornings</span> -->
              </div>

              <div>
                <p class="header-text">Rate</p>
                <p>{{ jobDetails.rate }}</p>
              </div>
            </div>
          </div>
          <div class="content-section">
            <div class="jobs-list-item-type-of-care-container">
              <div v-if="isCareGiverChosen" style="width: 300px">
                <p class="header-text">Education</p>
                <span>{{ jobDetails.education }}</span>
                <!-- <span>Mornings</span> -->
                <div
                  style="
                    display: flex;
                    gap: 10px;
                    align-items: center;
                    margin-top: 8px;
                  "
                >
                  <svg
                    width="16"
                    height="20"
                    viewBox="0 0 16 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10 0H2C0.9 0 0.0100002 0.9 0.0100002 2L0 18C0 19.1 0.89 20 1.99 20H14C15.1 20 16 19.1 16 18V6L10 0ZM2 18V2H9V7H14V18H2Z"
                      fill="#979797"
                    />
                  </svg>
                  <a
                    :href="jobDetails.certificate"
                    target="_blank"
                    style="color: var(--state-info); text-decoration: none"
                  >
                    View Certificate
                  </a>
                </div>
              </div>

              <div  v-if="isCareGiverChosen">
                <p class="header-text">Preferences</p>
                <p>{{ jobDetails.preferences }}</p>
              </div>
            </div>
          </div>
          <div  v-if="isCareGiverChosen" class="content-section">
            <p class="header-text">Language</p>
            <div class="jobs-list-item-type-of-care">
              <!-- <h4>Senior Care Needed</h4> -->
              <div class="job-tag">
                <span v-for="language in jobDetails.language" :key="language">{{
                  language.name
                }}</span>
              </div>
            </div>
          </div>
          <div class="content-section">
            <div class="jobs-list-item-type-of-care-container">
              <div style="width: 300px">
                <p class="header-text">Verification</p>
                <span>{{ jobDetails.document_type }} : </span>
                <span>{{ jobDetails.document_number }}</span>
              </div>
            </div>
          </div>
          <div v-if="isCareGiverChosen" class="content-section">
            <p class="header-text">Guarantor's details</p>
            <div>
              <span class="span-header"> Name: </span>
              <span class="span-normal">{{ jobDetails.guarantor.name }}</span>
            </div>
            <div>
              <span class="span-header"> Designation: </span>
              <span class="span-normal">
                {{ jobDetails.guarantor.designation }}</span
              >
            </div>
            <div>
              <span class="span-header"> Work address: </span>
              <span class="span-normal"
                >{{ jobDetails.guarantor.address }}
              </span>
            </div>
            <div>
              <span class="span-header"> Email address: </span>
              <span class="span-normal">{{ jobDetails.guarantor.email }} </span>
            </div>
          </div>
          <!-- end -->
        </div>
      </div>
      <div class="button-div">
        <BaseButton
          :label="isApproving ? 'Approving...' : 'Approve'"
          backgroundColor="var(--s-pink-normal)"
          textColor="var(--state-white)"
          height="60px"
          :width="isLargeScreen ? '400px' : '260px'"
          @click="handleApproval"
        />
        <BaseButton
          label="Cancel"
          backgroundColor="transparent"
          textColor="var(--s-green-normal-active)"
          height="60px"
           :width="isLargeScreen ? '400px' : '260px'"
          borderColor="var(--s-green-normal-active)"
          @click="backtoDashboard"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import { useRouter } from "vue-router";
import BaseButton from "@/components/BaseButton.vue";
import Request from "@/utils/apiclient.js";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import ToastManagerVue from "@/components/ToastAlert/ToastManager.vue";
import { formatErrorMessages } from "@/utils/errorHandler";

export default {
  name: "AdminGeneral",
  components: {
    BaseButton,
    LoadingSpinner,
    ToastManagerVue,
  },
  setup() {
    const toastManager = ref(null);
    const showToast = (msg, statusValue) => {
      toastManager.value.addToast(msg, statusValue);
    };
    const isLoading = ref(true);
    const isCareTypeChosen = ref(false);
    const isCareSeekerChosen = ref(false);
    const isCareGiverChosen = ref(false);

    const router = useRouter();
    const searchQuery = ref("");
    const showCaregiverRequests = ref(false);
    const showCareseekerRequests = ref(false);
    const showReports = ref(false);
    const dashboardData = ref({});
    const requests = ref([
      { id: 1, name: "Tawio A.", care_type: "caregiver" },
      { id: 2, name: "Janet A.", care_type: "careseeker" },
    ]);
    const dashboardGeneral = ref({
      careGiverCount: 0,
      careSeekerCount: 0,
      requestNumber: 0,
      caregiverRequests: [],
      careseekerRequests: [],
      reports: [],
    });
    const reports = ref([]);

    const toggleCaregiverRequests = () => {
      showCaregiverRequests.value = !showCaregiverRequests.value;
    };
    const toggleCareseekerRequests = () => {
      showCareseekerRequests.value = !showCareseekerRequests.value;
    };

    const toggleReports = () => {
      showReports.value = !showReports.value;
    };
    const jobDetails = ref({
      description: "",
      name: "",
      gender: "",
      location: "",
      email: "",
      type_of_care: "",
      sub_care_categories: [],
      categories: [],
      language: [],
      rate: "",
      start_date: "",
      preferences: "",
      education: "",
      certificate: "",
      profile_picture: "",
      guarantor: {
        first_name: "",
        last_name: "",
        address: "",
        email: "",
      },
      document_type: "",
      document_number: "",
      degree_obtained: "",
    });
    const careId = ref();
    const viewCaregiverRequest = (req) => {
      // Log individual properties
      console.log(`User: ${req.user}`);
      console.log(`Bio: ${req.bio}`);
      console.log(`Sub Categories: ${req.sub_category}`);
      console.log(`Language: ${req.language}`);
      console.log(`Monthly Rate: ${req.monthly_rate}`);
      console.log(`Preferences: ${req.preferences}`);
      console.log(`Education: ${req.education}`);

      // Or use JSON.stringify to log the whole object
      console.log(`View request ${JSON.stringify(req, null, 2)}`);

      isLoading.value = true;
      jobDetails.value.description = req.bio;
      jobDetails.value.type_of_care = "Caregiver";
      jobDetails.value.sub_care_categories = [...req.sub_category];
      jobDetails.value.language = [...req.language];
      jobDetails.value.rate = req.monthly_rate;
      jobDetails.value.preferences = req.work_preference;
      jobDetails.value.document_number = req.document_number;
      jobDetails.value.certificate = req.certificate;
      jobDetails.value.availability = req.availabilities[0].days.join(", ");
      jobDetails.value.name = req.user.first_name + " " + req.user.last_name;
      jobDetails.value.gender = req.user.gender;
      jobDetails.value.location = req.lga + ", " + req.state;
      jobDetails.value.email = req.user.email;
      jobDetails.value.document_type = req.document_type;
      jobDetails.value.degree_obtained = req.degree_obtained;
      jobDetails.value.education = req.education;
      jobDetails.value.profile_picture = req.user.profile_picture;
      jobDetails.value.categories = [
        ...req.category.map((val) => val.name),
      ].join(", ");
      jobDetails.value.guarantor.name =
        req.guarantor.first_name + " " + req.guarantor.last_name;
      jobDetails.value.guarantor.designation = req.guarantor.designation;
      jobDetails.value.guarantor.email = req.guarantor.email;
      jobDetails.value.guarantor.address = req.guarantor.work_address;
      careId.value = req.id;
      isCareGiverChosen.value = true;
      isCareTypeChosen.value = true;
      isLoading.value = false;
    };
    const viewCareseekerRequest = (req) => {
      // Log individual properties
      console.log(`User: ${req.user}`);
      console.log(`Bio: ${req.bio}`);
      console.log(`Sub Categories: ${req.sub_category}`);
      console.log(`Language: ${req.language}`);
      console.log(`Monthly Rate: ${req.monthly_rate}`);
      console.log(`Preferences: ${req.preferences}`);
      console.log(`Education: ${req.education}`);

      // Or use JSON.stringify to log the whole object
      console.log(`View request ${JSON.stringify(req, null, 2)}`);

      isLoading.value = true;
      jobDetails.value.description = req.bio;
      jobDetails.value.type_of_care = "Careseeker";
      // jobDetails.value.sub_care_categories = [...req.sub_category];
      // jobDetails.value.language = [...req.language];
      jobDetails.value.rate = req.monthly_rate;
      jobDetails.value.preferences = req.work_preference;
      jobDetails.value.document_number = req.document_number;
      // jobDetails.value.availability = req.availabilities[0].days.join(", ");
      jobDetails.value.name = req.user.first_name + " " + req.user.last_name;
      jobDetails.value.gender = req.user.gender;
      jobDetails.value.location = req.lga + ", " + req.state;
      jobDetails.value.email = req.user.email;
      jobDetails.value.document_type = req.document_type;
      jobDetails.value.degree_obtained = req.degree_obtained;
      jobDetails.value.education = req.education;
      jobDetails.value.categories = req.category.name;
      careId.value = req.id;
      isCareSeekerChosen.value = true;
      isCareTypeChosen.value = true;
      isLoading.value = false;
    };

    // const jobDetails = ref({
    //   description:
    //     "Donec euismod lorem eget laoreet rutrum. Pellentesque vitae urna id urna ornare iaculis. Nulla facilisi. In hac habitasse platea dictumst. Nulla ac sapien sapien. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas.Donec euismod lorem eget laoreet rutrum. Pellentesque vitae urna id urna ornare iaculis. Nulla facilisi. ",
    //   type_of_care: "Senior Care",
    //   sub_care_categories: [
    //     "Bathing / Dressing",
    //     "Dementia",
    //     "Home Health",
    //     "Live-in Home care",
    //     "Live-out Home Care",
    //   ],
    //   language: ["English", "Spanish", "Yoruba", "German"],
    //   rate: "N5,000 / day",
    //   start_date: "Starts: Thur. 9th April, 2024",
    //   preferences: "Live in",
    //   education: "University of Lagos",
    // });

    const viewReport = (req) => {
      // Handle view report logic here
      console.log(`View report ${req.id}`);
    };
    const backtoDashboard = () => {
      isCareGiverChosen.value = false;
      isCareSeekerChosen.value = false;
      isCareTypeChosen.value = false;
    };

    const fetchDashboard = () => {
      const request = new Request("/admin/dashboard/");
      request.get((res, err) => {
        isLoading.value = false;
        if (err) {
          console.error("Error:", err);
        } else {
          console.log("here is dashboard data", res);
          dashboardData.value = res.data;
          dashboardGeneral.value.careGiverCount = res.data.care_giver_count;
          dashboardGeneral.value.careSeekerCount = res.data.care_seeker_count;
          dashboardGeneral.value.requestCaregiverNumber =
            res.data.pending_care_givers.length;
          dashboardGeneral.value.requestCareseekerNumber =
            res.data.pending_care_seekers.length;
          dashboardGeneral.value.caregiverRequests = [
            ...res.data.pending_care_givers,
          ];
          dashboardGeneral.value.careseekerRequests = [
            ...res.data.pending_care_seekers,
          ];
        }
      });
    };
    const resetJobDetails = () => {
      jobDetails.value = {
        description: "",
        name: "",
        gender: "",
        location: "",
        email: "",
        type_of_care: "",
        sub_care_categories: [],
        categories: [],
        language: [],
        rate: "",
        start_date: "",
        preferences: "",
        education: "",
        certificate: "",
        profile_picture: "",
        guarantor: {
          first_name: "",
          last_name: "",
          address: "",
          email: "",
        },
        document_type: "",
        document_number: "",
        degree_obtained: "",
      };
    };
    onMounted(() => {
      fetchDashboard();
    });
    const isApproving = ref(false);
    const handleApproval = async () => {
      if (isApproving.value) return;
      isApproving.value = true;

      // Handle login logic here
      const formData = {
        approval_status: 1,
      };
      let request;
      if (isCareGiverChosen.value) {
        request = new Request(`/admin/care-givers/${careId.value}/`);
        console.log("request care giver", request)
      } else {
        request = new Request(`/admin/care-seekers/${careId.value}/`);
         console.log("request care seeker", request)
      }

      const rawData = JSON.stringify(formData);
      console.log("raw data", rawData);
      request.patch(rawData, (data, error) => {
        isApproving.value = false;
        if (error) {
          console.log("data error", error);
          if (error.status == "failure") {
            return showToast(error.message, "error");
          }
          if (error.detail) {
            return showToast("Incorrect Email or Password", "error");
          }
          const errorMessages = formatErrorMessages(error);
          console.log("error", errorMessages);
          errorMessages.forEach((message) => showToast(message, "error"));
        } else {
          console.log("Success:", data);

          //  showToast(data.message, "success");
          isCareGiverChosen.value = false;
          isCareSeekerChosen.value = false;
          isCareTypeChosen.value = false;
          // Reset jobDetails before navigating
          resetJobDetails();
          showToast("Approved Successfully", "success");
          fetchDashboard();
          setTimeout(() => {
            router.push("/admin/general");
          }, 1000);
        }
      });
    };
    return {
      router,
      searchQuery,
      requests,
      reports,
      viewReport,
      viewCaregiverRequest,
      viewCareseekerRequest,
      toggleReports,
      toggleCaregiverRequests,
      toggleCareseekerRequests,
      showCaregiverRequests,
      showCareseekerRequests,
      showReports,
      isCareTypeChosen,
      isCareSeekerChosen,
      isCareGiverChosen,
      backtoDashboard,
      jobDetails,
      dashboardData,
      dashboardGeneral,
      isLoading,
      toastManager,
      isApproving,
      handleApproval,
    };
  },
};
</script>

<style scoped>
h1 {
  margin: 0px 0px 32px 0px;
}
.class-container {
  display: flex;
  width: 100%;
}
.content-container {
  display: flex;
  flex-direction: column;
  padding-left: 14px;
  margin-top: 72px;
  box-sizing: border-box;
}
.content-container-categories {
  display: flex;

  gap: 24px;
}
.content-container-categories-main {
  display: flex;
  justify-content: space-between;
  background-color: var(--s-green-normal);
  width: 470px;
  height: 148px;
  padding: 16px 24px 0px 24px;
  box-sizing: border-box;
  border-radius: 8.14px;
}
.svg-div {
  display: flex;
  align-items: flex-end;
}
h4 {
  margin: 0px;
  font-family: Inter;
  font-size: 24.43px;
  font-weight: 400;
  line-height: 23.07px;
  letter-spacing: 0.3392857015132904px;
  text-align: left;
  color: var(--state-white);
}

.carecategory-number {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 18.5px 0px 34.5px 0px;
  gap: 16px;
}
h1 {
  font-size: 44px;
  font-weight: 700;
  line-height: 57.2px;
  letter-spacing: 0.5px;
  text-align: left;
}

.stats {
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px;
}

.stat-card {
  background-color: #c8e6c9;
  padding: 16px;
  flex: 1;
  margin: 0 8px;
  text-align: center;
}

.requests-reports {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.requests,
.reports {
  margin-bottom: 16px;
}

.request-item,
.report-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #ececec;
  margin-top: 8px;
  box-sizing: border-box;
}

.request-item a,
.report-item a {
  color: #1e88e5;
  text-decoration: none;
}

.request-item a:hover,
.report-item a:hover {
  text-decoration: underline;
}
.toggle-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-bottom: 6px;
  border-bottom: 1px solid #979797;
}
.toggle-control {
  cursor: pointer;
}
h3 {
  color: var(--s-green-normal);
  font-size: 20px;
  font-weight: 400;
  line-height: 34px;
  letter-spacing: 0.5px;
  text-align: left;
}
.text {
  font-family: Inter;
  font-size: 40px;
  font-weight: 700;
  line-height: 38.81px;
  letter-spacing: 0.3392857015132904px;
  text-align: left;
  color: var(--state-white);
}
.caregiver-details-first-div {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
}
.caregiver-details {
  padding-left: 46px;
  margin-top: 30px;
}
.view-request {
  color: var(--state-info);
  text-decoration: underline;
  cursor: pointer;
}

.jobs-list-item-first-div {
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  margin-top: 16px;
}
.sub-text {
  font-size: 12px;
  font-weight: 400;
  line-height: 20.4px;
  letter-spacing: 0.5px;
  text-align: left;
}
.main-container-section {
  display: flex;
  width: 839px;
  flex-direction: column;
}
.main-container {
  padding: 20px;
  background-color: var(--state-white);
  border-radius: 12px;
  margin-top: 22px;
}
h4 {
  font-size: 24px;
  font-weight: 700;
  line-height: 31.2px;
  letter-spacing: 0.5px;
  text-align: left;
  margin: 0px 0px 0px 0px;
}
.header-text {
  font-size: 16px;
  font-weight: 700;
  line-height: 27.2px;
  letter-spacing: 0.5px;
  margin-bottom: 16px;
  text-align: justified;
}
p {
  font-size: 16px;
  font-weight: 400;
  line-height: 27.2px;
  letter-spacing: 0.5px;
  text-align: left;
}
.job-tag {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
.job-tag span {
  padding: 10px;
  gap: 8px;
  border-radius: 12px;
  background-color: #ececec;
  font-size: 12px;
  font-weight: 400;
  line-height: 20.4px;
  letter-spacing: 0.5px;
  text-align: left;
}
.jobs-list-item-type-of-care {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.schedule-container {
  display: flex;
  align-items: center;
  gap: 24px;
}
.start-end {
  display: flex;
  flex-direction: column;
  gap: 9px;
}
.content-section:last-child {
  border-bottom: 0px;
}
.content-section {
  padding-bottom: 24px;
  margin-bottom: 24px;
  border-bottom: 1px solid #979797;
  width: 100%;
}
.jobs-list-item-type-of-care-container {
  display: flex;
  gap: 152px;
}
.span-header {
  font-size: 16px;
  font-weight: 700;
  line-height: 27.2px;
  letter-spacing: 0.5px;
  text-align: left;
}
.span-normal {
  font-size: 16px;
  font-weight: 400;
  line-height: 27.2px;
  letter-spacing: 0.5px;
  text-align: left;
}
h2 {
  font-size: 20px;
  font-weight: 700;
  line-height: 34px;
  letter-spacing: 0.5px;
  text-align: left;
}
.button-div {
  display: flex;
  justify-content: space-between;
  margin-bottom: 129px;
  margin-top: 20px;
}
.profile-image-container {
  display: flex;
  justify-content: center;
}
.profile-image {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
}

.profile-image img {
  width: 100px;
  height: 100px;
  border-radius: 12px;
}
.image-header {
  display: flex;
  justify-content: space-between;
}
.profile-section {
  display: flex;
  gap: 24px;
  align-items: center;
}
.the-div {
  width: 138px;
  height: 44px;
  padding: 10px;
  gap: 10px;
  border-radius: 12px;
  background-color: var(--s-pink-light-active);
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}
@media (max-width: 800px) {
 .content-container-categories{
  flex-direction: column;
 }
 .content-container-categories-main{
  width: 260px
 }
 .main-container-section {

  width: 260px;

}

 h4{
  font-size:18px
 }
.requests-reports{
  margin-top:20px
}
h1{
  font-size: 35px;
}
.content-container{
  margin-top:30px
}
.button-div {
flex-direction: column;
justify-content: center;
align-items: center;
  margin-bottom: 129px;
  margin-top: 20px;
  gap: 20px
}
.caregiver-details{
  padding-left: 0px
}
.profile-section{
  flex-direction: column;
}
.image-header{
  flex-direction: column;
}
.jobs-list-item-type-of-care-container{
  flex-direction: column;
}
}
</style>
