<template>
  <div class="login-layout-container">
    <div class="main">
      <div class="navbar">
        <img
          @click="redirectToHome"
          class="main-logo"
          src="@/assets/full_logo.svg"
          alt="Logo"
        />
        <div class="create-account">
          <p>{{ currentSection.message }}</p>
          <p>Step {{ currentStep }} of {{ currentSection.steps }}</p>
        </div>
      </div>
      <div class="progress-bar">
        <div class="progress" :style="{ width: progressWidth + '%' }"></div>
      </div>
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import { ref, provide, computed, watch } from "vue";
import { useRoute } from "vue-router";

export default {
  setup() {
    const formData = ref({
      stepOneData: {
        type_of_care: "",
      },
      stepTwoData: {
        document_type: "",
        document_number: "",
      },
      stepThreeData: {
        hear_about_us: "",
      },
      stepFourData: {
        preference: "",
        type_of_care: "",
        type_of_care_id: "",
      },
      stepFiveData: {
        //  specific_care_requirements: {},
        //   care_giver_gender: ""
        sub_category: [],
      },
      stepSixData: {
        care_giver_gender: "",
        specific_care_data: {},
        //    who_needs_care: "",
        //  gender:"",
        //  age:"",
        //  preferred_gender:"",
        //  help_type:""
      },
      stepSevenData: {
        state: "",
        local_government: "",
      },
      stepEightData: {
        days_of_the_week: [],
      },
      stepNineData: {
        pay_rate: "",
      },
      stepTenData: {
        share_details: "",
      },
    });

    const updateFormData = (step, data) => {
      formData.value[step] = data;
    };

    provide("onboardingCareSeekerformData", formData);
    provide("onboardingUpdateCareSeekerformData", updateFormData);

    // Access the current route
    const route = useRoute();

    // Define sections with their respective steps and messages
    const sections = [
      { section: "Job Post", steps: 7, message: "Make your job post" },
      { section: "Create Account", steps: 3, message: "Create an account" },
    ];

    // Compute the current section based on the route path
    const currentSection = computed(() => {
      if (route.path.includes("job-post")) return sections[0];
      if (route.path.includes("create-account")) return sections[1];
      return { steps: 0, message: "" };
    });

    // Compute the current step based on the route path
    const currentStep = computed(() => {
      const pathParts = route.path.split("/");
      const stepPart = pathParts[pathParts.length - 1];
      if (stepPart.includes("step-")) {
        return parseInt(stepPart.split("-")[1]);
      }
      return 1;
    });

    // Compute the progress width as a percentage of the total steps
    const progressWidth = computed(() => {
      return (currentStep.value / currentSection.value.steps) * 100;
    });

    // Watch for changes in the route path to ensure reactivity
    watch(
      () => route.path,
      () => {
        // This will force the computed properties to re-evaluate
        console.log("Route path changed:", route.path);
      }
    );

    return {
      progressWidth,
      currentSection,
      currentStep,
    };
  },
};
</script>

<style scoped>
/* Your existing styles */
.onboarding-container {
  padding-top: 56px;
}
.care-card {
  display: flex;
  gap: 40px;
  justify-content: center;
}
.care-card-single {
  padding: 24px;
  gap: 10px;
  border-radius: 12px;
  border: 1px solid var(--s-green-normal);
 
  width: 360px;
}
.login-layout-container {
  background-color: #fffbfc;
  min-height: 100vh;
}
.main {
  background-color: #fff8f9;
  min-height: 100vh;
  width: 100%;
  padding-bottom: 200px;
}
.navbar {
  display: flex;
  padding: 32px 128px;
  justify-content: space-between;
}
header {
  box-shadow: 0px 4px 1px 0px #0000001a;
}
.main-logo {
  width: 168px;
  height: 59px;
  cursor: pointer;
}
.onboarding-header-text {
  font-size: 24px;
  font-weight: 700;
  line-height: 31.2px;
  letter-spacing: 0.5px;
  text-align: center;
  margin-bottom: 60px;
}

.stepper {
  width: 100%;
  margin: 0 auto;
  padding: 20px;
}

.progress-bar {
  width: 100%;
  background-color: #979797;
  height: 5px;
}

.progress {
  background-color: #17865f;
  height: 5px;
  transition: width 0.3s;
}
.create-account {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.create-account p {
  color: #116547;
}
.create-account p:first-child {
  font-weight: 700;
  line-height: 27.2px;
  letter-spacing: 0.5px;
  text-align: left;
  margin-right: 8px;
}
</style>
