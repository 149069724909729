<template>
<DashboardLayout>
  <div class="main-container">
    <div v-if="!showNextScreen" class="main-container-content">
      <div class="content-section">
        <h4 class="small-header-text">When do you need care?</h4>
        <p class="text">Select days of the week</p>
        <div>
          <MultipleSelector
            :subjects="days"
            :selectedSubjects="jobValues.selectedDays"
            @update:selectedSubjects="updateSelectedDays"
            :buttonWidth="47.14"
          />
        </div>
      </div>
      <div class="content-section" style="margin-bottom: 8px">
        <h4 class="small-header-text">Where are you looking for care ?</h4>
        <p class="text">Select days of the week</p>
        <div class="state-city-div">
          <div
            style="width: 153px"
            :class="['custom-multiselect', 'state-select', {'selected': jobValues.state}]"
          >
            <label :class="{'label-selected': jobValues.state}" for="state">State</label>
            <Multiselect
              id="state"
              label="text"
              v-model="jobValues.state"
              track-by="value"
              placeholder="State"
              class="custom-multiselect"
              :options="stateOptions"
              @input="updateCityOptions"
            >
              <template #caret>
                <svg
                  width="12"
                  height="8"
                  viewBox="0 0 12 8"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12 1.70688L10.59 0.296875L6 4.87687L1.41 0.296875L0 1.70688L6 7.70688L12 1.70688Z"
                    fill="#979797"
                  />
                </svg>
              </template>
            </Multiselect>
          </div>
          <div
            style="width: 231px"
            :class="['custom-multiselect', 'city-select',{'selected': jobValues.city}]"
          >
            <label :class="{'label-selected': jobValues.city}" for="state">Local Government</label>
            <Multiselect
              id="lga"
              label="text"
              v-model="jobValues.city"
              track-by="value"
              placeholder="City"
              class="custom-multiselect"
              :options="cityOptions"
            >
              <template #caret>
                <svg
                  width="12"
                  height="8"
                  viewBox="0 0 12 8"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12 1.70688L10.59 0.296875L6 4.87687L1.41 0.296875L0 1.70688L6 7.70688L12 1.70688Z"
                    fill="#979797"
                  />
                </svg>
              </template>
            </Multiselect>
          </div>
        </div>
      </div>
      <!-- start senior care -->
      <div v-if="check_type_of_care == 'Elderly care'">
        <div class="content-section">
          <h4 class="small-header-text">
            What kind of care are you looking for?
          </h4>
          <p class="text">Select all that apply</p>
          <div>
            <MultipleSelector
              :subjects="kindOfCare"
              :selectedSubjects="jobValues.selectedKindOfCare"
              @update:selectedSubjects="updateSelectedkindOfCare"
              :buttonWidth="47.14"
            />
          </div>
        </div>
        <div class="content-section">
          <h4 class="small-header-text">Who needs care</h4>
          <p class="text">Select one option</p>
          <div>
            <MultipleSelector
              :subjects="forWho"
              :singleSelect="true"
              :selectedSubjects="jobValues.selectedforWho"
              @update:selectedSubjects="updateSelectedForWho"
              :buttonWidth="47.14"
            />

            <div
              style="width: 146px"
              :class="['custom-multiselect-age', 'state-select']"
            >
              <label for="state">Age</label>
              <Multiselect
                id="age"
                label="text"
                v-model="jobValues.ageSelect"
                track-by="value"
                placeholder="Age"
                class="custom-multiselect"
                :options="ageOptions"
              >
                <template #caret>
                  <svg
                    width="12"
                    height="8"
                    viewBox="0 0 12 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12 1.70688L10.59 0.296875L6 4.87687L1.41 0.296875L0 1.70688L6 7.70688L12 1.70688Z"
                      fill="#979797"
                    />
                  </svg>
                </template>
              </Multiselect>
            </div>
          </div>
        </div>
      </div>
      <!-- end senior care -->
      <!-- house care start -->
      <div v-if="check_type_of_care == 'Housekeeper'">
        <div style="margin-bottom: 24px">
          <h4 class="small-header-text">
            What kind of house care are you looking for?
          </h4>
          <p class="child-text-main" style="margin-bottom: 16px">
            Select all that apply
          </p>
          <MultipleSelector
            :subjects="home"
            :selectedSubjects="jobValues.homeCare"
            @update:selectedSubjects="updateSelectedHome"
            :buttonWidth="49"
          />
        </div>
      </div>
      <!-- house care end -->
      <!-- child care start -->
      <div v-if="check_type_of_care == 'Child care'">
        <div>
           <h4 style="text-align: left" class="small-header-text">Number of children</h4>
        </div>
        <div class="button-div">
          <div @click="removeChild">
            <svg
              width="25"
              height="24"
              viewBox="0 0 25 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.5 0C5.876 0 0.5 5.376 0.5 12C0.5 18.624 5.876 24 12.5 24C19.124 24 24.5 18.624 24.5 12C24.5 5.376 19.124 0 12.5 0ZM18.5 13.2H6.5V10.8H18.5V13.2Z"
                fill="#979797"
              />
            </svg>
          </div>
          <span>{{ jobValues.children.length }}</span>
          <div @click="addChild">
            <svg
              width="26"
              height="24"
              viewBox="0 0 26 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.5 0C6.876 0 1.5 5.376 1.5 12C1.5 18.624 6.876 24 13.5 24C20.124 24 25.5 18.624 25.5 12C25.5 5.376 20.124 0 13.5 0ZM19.5 13.2H14.7V18H12.3V13.2H7.5V10.8H12.3V6H14.7V10.8H19.5V13.2Z"
                fill="#F59CAB"
              />
            </svg>
          </div>
        </div>
        <div
          v-for="(child, index) in jobValues.children"
          :key="index"
          class="child-details"
        >
          <div
            @click="toggleChildDetails(index)"
            style="
              display: flex;
              justify-content: space-between;
              align-items: center;
              border-bottom: 0.5px solid #979797;
              padding-bottom: 8px;
              margin-bottom: 24px;
            "
          >
            <p class="child-text-main">Child {{ index + 1 }}</p>
            <p>
              <span v-if="child.showDetails">
                <svg
                  width="12"
                  height="8"
                  viewBox="0 0 12 8"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0 6.29508L1.41 7.70508L6 3.12508L10.59 7.70508L12 6.29508L6 0.295075L0 6.29508Z"
                    fill="#979797"
                  />
                </svg>
              </span>
              <span v-else
                ><svg
                  width="12"
                  height="8"
                  viewBox="0 0 12 8"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12 1.70492L10.59 0.294922L6 4.87492L1.41 0.294921L-6.16331e-08 1.70492L6 7.70492L12 1.70492Z"
                    fill="#979797"
                  />
                </svg>
              </span>
            </p>
          </div>
          <div v-if="child.showDetails">
            <div style="margin-bottom: 24px">
              <p class="child-text-main" style="margin-bottom: 16px">
                Select age range
              </p>
              <div class="subjects-div">
                <button
                  v-for="age in childAgeOptions"
                  :key="age"
                  @click="toggleAgeRange(index, age)"
                  :class="{ active: child.selectedAgeRange.includes(age) }"
                >
                  {{ age }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- child care end -->
      <!-- Academic Care start -->
      <div v-if="check_type_of_care == 'Tutor'">
        <div>
          <h4 style="text-align: left" class="small-header-text">Number of children</h4>
        </div>
        <div class="button-div">
          <div @click="removeChildAcademic">
            <svg
              width="25"
              height="24"
              viewBox="0 0 25 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.5 0C5.876 0 0.5 5.376 0.5 12C0.5 18.624 5.876 24 12.5 24C19.124 24 24.5 18.624 24.5 12C24.5 5.376 19.124 0 12.5 0ZM18.5 13.2H6.5V10.8H18.5V13.2Z"
                fill="#979797"
              />
            </svg>
          </div>
          <span>{{ jobValues.childrenAcademic.length }}</span>
          <div @click="addChildAcademic">
            <svg
              width="26"
              height="24"
              viewBox="0 0 26 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.5 0C6.876 0 1.5 5.376 1.5 12C1.5 18.624 6.876 24 13.5 24C20.124 24 25.5 18.624 25.5 12C25.5 5.376 20.124 0 13.5 0ZM19.5 13.2H14.7V18H12.3V13.2H7.5V10.8H12.3V6H14.7V10.8H19.5V13.2Z"
                fill="#F59CAB"
              />
            </svg>
          </div>
        </div>
        <div
          v-for="(child, index) in jobValues.childrenAcademic"
          :key="index"
          class="child-details"
        >
          <div
            @click="toggleChildDetailsAcademic(index)"
            style="
              display: flex;
              justify-content: space-between;
              align-items: center;
              border-bottom: 0.5px solid #979797;
              padding-bottom: 8px;
              margin-bottom: 24px;
            "
          >
            <p class="child-text-main">Child {{ index + 1 }}</p>
            <p>
              <span v-if="child.showDetails">
                <svg
                  width="12"
                  height="8"
                  viewBox="0 0 12 8"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0 6.29508L1.41 7.70508L6 3.12508L10.59 7.70508L12 6.29508L6 0.295075L0 6.29508Z"
                    fill="#979797"
                  />
                </svg>
              </span>
              <span v-else
                ><svg
                  width="12"
                  height="8"
                  viewBox="0 0 12 8"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12 1.70492L10.59 0.294922L6 4.87492L1.41 0.294921L-6.16331e-08 1.70492L6 7.70492L12 1.70492Z"
                    fill="#979797"
                  />
                </svg>
              </span>
            </p>
          </div>
          <div v-if="child.showDetails">
            <div>
              <label :class="[{ 'label-selected': child.educationLevel }]"
                >Select level of education</label
              >
            </div>
            <div
              :class="[
                'custom-multiselect',
                { 'custom-multiselect-selected': child.educationLevel },
              ]"
            >
              <Multiselect
                id="level_of_education"
                label="text"
                v-model="child.educationLevel"
                track-by="value"
                placeholder="Level of Education"
                class="custom-multiselect"
                :options="levelOfEducationOptions"
              >
                <template #caret>
                  <svg
                    width="12"
                    height="8"
                    viewBox="0 0 12 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12 1.70688L10.59 0.296875L6 4.87687L1.41 0.296875L0 1.70688L6 7.70688L12 1.70688Z"
                      fill="#979797"
                    />
                  </svg> </template
              ></Multiselect>
            </div>
            <div style="margin-bottom: 24px">
              <p class="child-text-main" style="margin-bottom: 16px">
                Select Subjects
              </p>
              <div class="subjects-div">
                <button
                  v-for="subject in subjects"
                  :key="subject"
                  @click="toggleSubject(index, subject)"
                  :class="{ active: child.selectedSubjects.includes(subject) }"
                >
                  {{ subject }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Academic care end -->

    <div class="main-container-second" v-if="showNextScreen">
      <div class="content-section">
        <h4 class="small-header-text">What would you like to pay for care?</h4>
        <div>
          <RateSlider
            :initialMinRate="jobValues.pay_rate.pay_min"
            :initialMaxRate="jobValues.pay_rate.pay_max"
            @update:rates="handleRateUpdate"
          />
        </div>
      </div>
      <div class="content-section">
        <!-- <h4 class="small-header-text">What would you like to pay for care?</h4> -->
        <p class="text">Preferred gender of care giver</p>
        <div>
          <MultipleSelector
            :subjects="gender"
            :singleSelect="true"
            :selectedSubjects="jobValues.selectedGender"
            @update:selectedSubjects="updateSelectedGender"
            :buttonWidth="195"
          />
        </div>
      </div>
      <div class="content-section-last-child">
        <h4>Final details</h4>
        <p class="text">Are there other particular requirements?</p>
        <div>
          <textarea
            v-model="jobValues.details"
            placeholder="Share details here"
          ></textarea>
        </div>
      </div>
    </div>

    <div class="buttons-div-container">
      <BaseButton
        v-if="!showNextScreen"
        label="Next"
        backgroundColor="var(--s-pink-normal)"
        textColor="var(--state-white)"
        disabledBackgroundColor="#ECECEC"
        disabledColor="#ECECEC"
        disabledTextColor="#979797"
        height="60px"
        width="400px"
        :disabled="isNextDisabled"
        @click="handleNext"
      />
      <BaseButton
        v-if="showNextScreen"
        label="Post job"
        backgroundColor="var(--s-pink-normal)"
        textColor="var(--state-white)"
        height="60px"
        width="400px"
        @click="handleNext"
      />
      <!-- <BaseButton
        label="Cancel"
        backgroundColor="transparent"
        textColor="var(--s-green-normal-active)"
        borderColor="var(--s-green-normal-active)"
        height="60px"
        width="192.5px"
      /> -->
    </div>
  </div>
  </DashboardLayout>
</template>

<script>
import MultipleSelector from "@/components/MultipleSelect.vue";
import statesData from "@/data/states.js";
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
import RateSlider from "@/components/DoubleRateSlider.vue"; // Adjust the path as necessary
import BaseButton from "@/components/BaseButton.vue";
import { useRoute } from "vue-router";
import { ref, onMounted, watch, computed } from "vue";
import DashboardLayout from "@/views/Dashboard/Caregiver/IndexPage.vue";
export default {
  name: "CreateJob",
  components: {
    MultipleSelector,
    Multiselect,
    RateSlider,
    BaseButton,
    DashboardLayout

  },
  setup() {
    const check_type_of_care = ref("child care");
    const jobValues = ref({
      selectedDays: [],
      state: "",
      city: "",
      selectedKindOfCare: [],
      selectedforWho: "",
      pay_rate: { pay_min: 30000, pay_max: 70000 },
      selectedGender: "Male",
      details: "",
      selectedSubjects: "",
      ageSelect: "",
      childrenAcademic: [
        {
          showDetails: true,
          educationLevel: "",
          selectedSubjects: [],
        },
      ],
      homeCare: [],
      children: [
        {
          showDetails: true,
          selectedAgeRange: [],
        },
      ],
    });

    // senior care start
    //days
    const days = ref(["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]);

    const updateSelectedDays = (newSelectedDays) => {
      jobValues.value.selectedDays = newSelectedDays;
      console.log("selected", jobValues.value.selectedDays);
    };
    //days end
    //days
    const kindOfCare = ref([
      "In-home care",
      "Personal care",
      "Companionship",
      "Transportation",
      "Special care",
      "Mobility Assistance",
    ]);

    const updateSelectedkindOfCare = (newSelectedKindOfCare) => {
      jobValues.value.selectedKindOfCare = newSelectedKindOfCare;
    };
    //days end
    //days
    const forWho = ref([
      "My parent",
      "My spouse",
      "My Grandparent",
      "Friend / relative",
      "Myself",
    ]);

    const updateSelectedForWho = (newSelectedforWho) => {
      jobValues.value.selectedforWho = newSelectedforWho;
    };
    //days end
    //days
    const gender = ref(["Male", "Female"]);

    const updateSelectedGender = (newSelectedGender) => {
      jobValues.value.selectedGender = newSelectedGender;
    };
    //days end
    // state
    const stateOptions = statesData.states.map((state) => ({
      value: state.state,
      text: state.state,
    }));

    const cityOptions = ref([]);

    const updateCityOptions = (state) => {
      const selectedStateData = statesData.states.find(
        (s) => s.state === state
      );
      cityOptions.value = selectedStateData
        ? selectedStateData.lgas.map((lga) => ({ value: lga, text: lga }))
        : [];
    };
    //end

    const handleRateUpdate = ({ minRate, maxRate }) => {
      jobValues.value.pay_rate.pay_min = minRate;
      jobValues.value.pay_rate.pay_max = maxRate;
    };
    const ageOptions = ref([
      {
        value: "50 - 59",
        text: "50 - 59",
      },
      {
        value: "60 - 69",
        text: "60 - 69",
      },
      {
        value: "70 - 79",
        text: "70 - 79",
      },
      {
        value: "80 - 89",
        text: "80 - 89",
      },
    ]);
    // end senior care
    // Academic care
    const subjects = [
      "Mathematics",
      "English",
      "Science",
      "Arts",
      "Music and Drama",
      "Special Education",
      "Others",
    ];

    const levelOfEducationOptions = ref([
      {
        value: "Pre-school",
        text: "Pre-school",
      },
      {
        value: "Primary school",
        text: "Primary school",
      },
      {
        value: "Secondary School",
        text: "Secondary School",
      },
      {
        value: "Higher Instituition",
        text: "Higher Instituition",
      },
    ]);

    const addChildAcademic = () => {
      jobValues.value.childrenAcademic.push({
        showDetails: false,
        educationLevel: "",
        selectedSubjects: [],
        caregiverGender: "",
      });
    };

    const removeChildAcademic = () => {
      if (jobValues.value.childrenAcademic.length > 1) {
        jobValues.value.childrenAcademic.pop();
      }
    };

    const toggleChildDetailsAcademic = (index) => {
      jobValues.value.childrenAcademic[index].showDetails =
        !jobValues.value.childrenAcademic[index].showDetails;
    };

    const toggleSubject = (index, subject) => {
      const subjects = jobValues.value.childrenAcademic[index].selectedSubjects;
      const subjectIndex = subjects.indexOf(subject);
      if (subjectIndex > -1) {
        subjects.splice(subjectIndex, 1);
      } else {
        subjects.push(subject);
      }
    };

    //home care
    const home = ref([
      "Carpet cleaning",
      "Bathroom cleaning",
      "Kitchen cleaning",
      "Window cleaning",
      "Laundry",
    ]);

    const updateSelectedHome = (newSelectedHome) => {
      jobValues.value.homeCare = newSelectedHome;
    };
    //days end
    const childAgeOptions = ref([
      "0 - 11 months",

      "1 - 3 yrs",

      "4 - 5 yrs",

      "6 - 11 yrs",

      "12+ yrs",
    ]);
    const updateSelectedAges = (newSelectedAge) => {
      jobValues.value.ageRangeSelect = newSelectedAge;
    };
    const addChild = () => {
      jobValues.value.children.push({
        showDetails: false,
        selectedAgeRange: [],
      });
    };

    const removeChild = () => {
      if (jobValues.value.children.length > 1) {
        jobValues.value.children.pop();
      }
    };

    const toggleChildDetails = (index) => {
      jobValues.value.children[index].showDetails =
        !jobValues.value.children[index].showDetails;
    };
    const toggleAgeRange = (index, subject) => {
      const ages = jobValues.value.children[index].selectedAgeRange;
      const ageIndex = ages.indexOf(subject);
      if (ageIndex > -1) {
        ages.splice(ageIndex, 1);
      } else {
        ages.push(subject);
      }
    };

    const isCommonFieldsComplete = computed(() => {
      return (
        jobValues.value.selectedDays.length &&
        jobValues.value.state &&
        jobValues.value.city
      );
    });

    const isAcademicCareComplete = computed(() => {
      return (
        isCommonFieldsComplete.value &&
        jobValues.value.childrenAcademic.every((child) => {
          return (
            child.selectedSubjects.length &&
            child.educationLevel &&
            child.showDetails
          );
        })
      );
    });

    const isSeniorCareComplete = computed(() => {
      return (
        isCommonFieldsComplete.value &&
        jobValues.value.selectedKindOfCare.length &&
        jobValues.value.selectedforWho &&
        jobValues.value.ageSelect
      );
    });

    const isHouseCareComplete = computed(() => {
      return isCommonFieldsComplete.value && jobValues.value.homeCare.length;
    });

    const isChildCareComplete = computed(() => {
      return (
        isCommonFieldsComplete.value &&
        jobValues.value.children.every((child) => {
          return child.selectedAgeRange.length && child.showDetails;
        })
      );
    });

    const isNextDisabled = computed(() => {
      if (check_type_of_care.value === "Tutor") {
        return !isAcademicCareComplete.value;
      } else if (check_type_of_care.value === "Elderly care") {
        return !isSeniorCareComplete.value;
      } else if (check_type_of_care.value === "Housekeeper") {
        return !isHouseCareComplete.value;
      } else if (check_type_of_care.value === "Child care") {
        return !isChildCareComplete.value;
      }
      return false;
    });

    // checking if it is edit
    const isEditMode = ref(false);
    const route = useRoute();

    // const fetchJobData = async (jobId) => {
    //   const response = await api.getJob(jobId);
    //   jobValues.value = response.data;
    // };

    onMounted(() => {
      if (route.params.jobId) {
        isEditMode.value = true;
        console.log("got here", route.params.jobId);
        // fetchJobData(route.params.jobId);
      }
    });

    // const handleSubmit = async () => {
    //   if (isEditMode.value) {
    //     await api.updateJob(route.params.jobId, jobValues.value);
    //   } else {
    //     await api.createJob(jobValues.value);
    //   }

    // };
    //end
    const showNextScreen = ref(false);
    const handleNext = () => {
      showNextScreen.value = true;
    };

    watch(
      jobValues,
      (newJobValues) => {
        console.log("Selected days updated:", newJobValues);
      },
      { deep: true, immediate: true }
    );

    return {
      days,
      updateSelectedDays,
      updateCityOptions,
      stateOptions,
      cityOptions,
      jobValues,
      kindOfCare,
      updateSelectedkindOfCare,
      updateSelectedForWho,
      forWho,
      handleRateUpdate,
      gender,
      updateSelectedGender,
      addChildAcademic,
      removeChildAcademic,
      toggleChildDetailsAcademic,
      toggleSubject,
      subjects,
      check_type_of_care,
      levelOfEducationOptions,

      showNextScreen,
      handleNext,
      ageOptions,
      home,
      updateSelectedHome,
      addChild,
      removeChild,
      toggleChildDetails,
      childAgeOptions,
      updateSelectedAges,
      toggleAgeRange,
      isNextDisabled,
    };
  },
};
</script>

<style scoped>
.main-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.main-container-content {
  width: 401px;
  /* background-color: aqua; */
  margin-top: 46px;
}
h4 {
  text-align: center;
  margin-bottom: 32px;
}
.custom-multiselect {
  position: relative;
  width: 100%;
}
.multiselect-option.is-selected {
  background: var(--s-green-normal-active);
  color: #000;
}
.custom-multiselect .multiselect {
  z-index: 11;
  width: 100%;
  padding: 10px;
  height: 60px;
  margin-bottom: 24px;
  font-size: 16px;
  border: 1px solid #979797;
  border-radius: 12px;
  background-color: transparent; /* White background color */
}
.custom-multiselect-age .multiselect {
  z-index: 11;
  width: 100%;
  padding: 10px;
  height: 48px;
  margin-bottom: 24px;
  font-size: 16px;
  border: 1px solid #979797;
  border-radius: 12px;
  background-color: transparent; /* White background color */
}
.custom-multiselect .multiselect__content {
  background-color: transparent; /* White background for options */
}
.multiselect {
  background: transparent;
}
.custom-multiselect-selected .multiselect {
  border: 1px solid var(--s-green-normal-active); /* Change to desired color when a value is selected */
}
.state-city-div {
  display: flex;
  gap: 16px;
}
.content-section {
  display: flex;
  padding-bottom: 32px;
  border-bottom: 1px solid #979797;
  flex-direction: column;
}
label {
  font-size: 12px;
  font-weight: 400;
  line-height: 20.4px;
  letter-spacing: 0.5px;
  margin-left: 10px;
}
.text {
  
  margin-bottom: 8px;
}
textarea {
  width: 400px;
  height: 197px;
  padding: 16px;
  gap: 10px;
  border-radius: 12px;
  border: 1px solid #979797;
  box-sizing: border-box;
  background-color: transparent;
  font-family: var(--font-family);
}
.content-section-last-child {
  display: flex;
  padding-bottom: 32px;
  border-bottom: 0px;
  flex-direction: column;
}
.content-section-last-child h4 {
  margin: 32px 0px 0px 0px;
}
.buttons-div-container {
  display: flex;
  gap: 16px;
  margin-top: 32px;
}
.button-div {
  display: flex;
  justify-content: center;
  gap: 47px;
  align-items: center;
  margin-top: 16px;
}
.multiselect-single-label-text {
  font-size: 16px;
  font-weight: 400;
  line-height: 27.2px;
  letter-spacing: 0.5px;
  text-align: left;
}
.child-text-main {
  font-size: 16px;
  font-weight: 400;
  line-height: 23.8px;
  letter-spacing: 0.5px;
}
.subjects-div {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
.subjects-div button {
  min-width: 126.67px;
  height: 44px;
  padding: 10px;
  border-radius: 12px;
  border: 1px solid #979797;
 
  background-color: transparent;
  white-space: nowrap;
  font-size: 14px;
  font-weight: 400;
  line-height: 23.8px;
  letter-spacing: 0.5px;
}
.subjects-div button.active {
  background-color: var(--s-pink-light-hover);
  color: var(--s-green-dark);
  border-color: var(--s-green-normal);
}
.small-header-text{
    margin: 32px 0px 32px 0px;
}
.multiselect-single-label-text{
    overflow:visible;
}
.label-selected {
  color: #17865F;
}
.custom-multiselect.selected .multiselect {
  border: 1px solid #17865F;
}
</style>
