<template>
  <LoginLayout>
    <!-- Add your login form here -->
    <div class="login-form">
      <!-- Your login form content -->

      <div class="login-container" v-if="!passwordResetSuccessful">
        <h2>Reset Password</h2>
        <p>Get a secure one-time link to reset your password</p>
        <form @submit.prevent="handleLogin">
          <div class="input-group">
            <label for="password">Password</label>
            <input
              type="password"
              id="password"
              v-model="password"
              placeholder="Enter New Password"
              required
            />
          </div>
          <div class="input-group">
            <label for="confirm_password">Confirm Password</label>
            <input
              type="password"
              id="confirm_password"
              v-model="confirm_password"
              placeholder="Enter Password"
              required
            />
          </div>
          <button type="submit" class="login-button" :disabled="isResetPassword">   {{ isResetPassword ? 'Updating Password...' : 'Update Password' }}</button>
        </form>
      </div>
        <div class="password-reset-successful-container" v-if="passwordResetSuccessful">
      <div >
        <!-- Your login form content -->

        <div class="login-container">
          <div class="successful-div"><img src="@/assets/confetti.svg" alt="Confetti"/><span style="color: black">Password reset successful</span></div>
          <p>Your password has been reset successfully, you can now login with the new password created.</p>
          <button @click="routeToLogin" class="back-to-login-button">Back to login</button>
        </div>
      </div>
    </div>
    </div>
  
  </LoginLayout>
</template>

<script>
import LoginLayout from "@/layouts/LoginLayout.vue";
import { ref } from "vue";
import { useRouter } from 'vue-router';

export default {
  name: "LoginPage",
  components: {
    LoginLayout,
  },

  setup() {
     const isResetPassword = ref(false);
    const password = ref("");
    const confirm_password = ref("");
    const passwordResetSuccessful = ref(false);
    const router = useRouter();

    const handleLogin = () => {
      if (isResetPassword.value) return;
      isResetPassword.value = true;
      // Handle login logic here
      console.log("Password:", password.value);
      console.log("Confirm Password:", confirm_password.value);

      // If passwords match, show the success message
      if (password.value === confirm_password.value) {
        passwordResetSuccessful.value = true;
      } else {
        alert("Passwords do not match!");
      }
    };

    const routeToLogin = () => {
      router.push('/login');
    };

    return {
      password,
      confirm_password,
      passwordResetSuccessful,
      handleLogin,
      routeToLogin,
      isResetPassword
    };
  },
};
</script>

<style scoped>
.login-container {
  max-width: 400px;
  margin: auto;
}

h2 {
  margin-bottom: 20px;
  margin-top: 32px;
  font-size: 24px;
  font-weight: 700;
  line-height: 29.05px;
  letter-spacing: 0.5px;
  text-align: center;
}

.input-group {
  margin-bottom: 32px;
}

.input-group label {
  display: block;
  margin-bottom: 5px;
  color: #979797;
  font-size: 12px;
  font-weight: 400;
  line-height: 20.4px;
  letter-spacing: 0.5px;
  text-align: left;
}

.input-group input {
  width: 100%;
  padding: 16px 10px;
  height: 60px;
  border: 1px solid #979797;
  border-radius: 12px;
  background-color: transparent;
  box-sizing: border-box;
}
.input-group input::placeholder {
  font-size: 16px;
  font-weight: 400;
  line-height: 27.2px;
  letter-spacing: 0.5px;
  text-align: left;
  color: #979797;
}

.login-button {
  width: 100%;
  background-color: var(--s-pink-normal);
  border: none;
  border-radius: 40px;
  height: 60px;
  color: white;
  font-size: 16px;
  cursor: pointer;
  margin-bottom: 10px;
  padding: 16px 24px 16px 24px;
}

.login-button:hover {
  background-color: var(--s-pink-normal-hover);
}

.forgot-password {
  display: block;
  margin-bottom: 34px;
  color: var(--s-green-normal);
  text-decoration: none;
  text-align: center;

  font-size: 14px;
  font-weight: 400;
  line-height: 23.8px;
  letter-spacing: 0.5px;
  text-align: center;
}

.forgot-password:hover {
  color: var(--s-green-normal-hover);
}

.google-login,
.apple-login {
  width: 100%;
  padding: 10px 67px;
  border-radius: 12px;
  border: 1px solid var(--s-green-normal);
  color: #000;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  gap: 10px;
  font-size: 20px;
  font-weight: var(--font-weight-regular);
  line-height: 34px;
  letter-spacing: 0.5px;
  text-align: left;
  background-color: transparent;
}

.google-login img,
.apple-login img {
  width: 40.67px;
  height: 40.67px;
}

/* .google-login:hover,
.apple-login:hover {
  background-color: #f5f5f5;
} */
.separator {
  display: flex;
  align-items: center;
  text-align: center;
  color: #000;

  font-size: 20px;
  font-weight: 400;
  line-height: 34px;
  letter-spacing: 0.5px;
  text-align: left;
  margin-bottom: 16px;
}
.separator::before,
.separator::after {
  content: "";
  flex: 1;
  border-bottom: 1px solid #979797;
}
.separator:not(:empty)::before {
  margin-right: 8px;
}
.separator:not(:empty)::after {
  margin-left: 8px;
}
.join-now:hover {
  background-color: var(--s-pink-normal-hover);
}
.login-container p {
  margin-bottom: 32px;
  color: var(--s-green-darker);
  font-size: 14px;
  font-weight: 400;
  line-height: 23.8px;
  letter-spacing: 0.5px;
  text-align: center;
}
.password-reset-successful-container {
  height: 100%;
  width: 100%;
}
.successful-div{
  display: flex;
  justify-content: center;
  gap:16px;
  align-items: center;
  background-color: #E8F3EF;
  border-radius: 12px;
  margin-bottom: 32px;
  margin-top: 32px;
  padding: 10px 65px;
}
.successful-div span {

font-size: 16px;
font-weight: 400;
line-height: 27.2px;
letter-spacing: 0.5px;
color: #0000;
white-space: nowrap;

}
.back-to-login-button{

   width: 100%;
  background-color: var(--s-pink-normal);
  border: none;
  border-radius: 40px;
  color: white;
  font-size: 16px;
  cursor: pointer;
  margin-bottom: 10px;
  padding: 16px 24px 16px 24px;
}


.back-to-login-button:hover {
  background-color: var(--s-pink-normal-hover);
}
.login-form{
  padding-bottom: 500px;
}
.login-button:disabled{
   width: 100%;
  background-color: var(--s-pink-normal);
  border: none;
  border-radius: 40px;
  color: white;
  font-size: 16px;
  cursor: pointer;
  margin-bottom: 10px;
  padding: 16px 24px 16px 24px;
   cursor: not-allowed;
  opacity: 0.6;
}
</style>
