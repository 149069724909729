<template>
  <LoginLayout>
    <div class="about-us-container">
      <div class="about-us-main">
        <div class="about-us-text">
          <h1 class="our-story">OUR STORY</h1>
          <h3 class="caring-hands">Caring Hands, Compassionate Hearts</h3>
          <p class="main-text">
            Succour is a caretech company dedicated to addressing the social problem of access to care across Africa.
We know how challenging it can be to find safe, quick, and reliable care options.
As nurturers and advocates for quality care, we are committed to creating a supportive environment that enhances the lives of our key stakeholders: caregivers and care seekers.

          </p>
        </div>
        <div>
          <img src="@/assets/about-us-image.png" alt="" />
        </div>
      </div>
      <div class="our-drive">
        <h2 class="our-drive-heading">Our Mission</h2>
        <p class="our-drive-text">
         Succour’s mission is to use technology to provide quick access to safe and verified care options across Africa
        </p>
      </div>
      <div class="core-values">
        <h1 class="our-story">CORE VALUES</h1>
        <h3 class="commitment">Our commitment to you</h3>
        <p class="main-text">
         We are a mission - driven company and these values determine how we make decisions
        </p>
        <div class="core-values-item-container">
          <div class="core-values-item-row">
            <div class="core-values-item">
              <div class="img-div">
                <img src="@/assets/core-values.svg" alt="" />
                <p class="core-values-text">Quality</p>
              </div>
              <div>Quality isn’t by chance; it’s achieved through dedication, thoughtful planning, and skilled execution. At Succour, we strive for excellence by learning from the best and always seeking to go beyond basic needs to provide an exceptional experience for both caregivers and careseekers.</div>
            </div>
            <div class="core-values-item">
              <div class="img-div">
                <img src="@/assets/core-values.svg" alt="" />
                <p class="core-values-text">Safety</p>
              </div>
              <div>Safety is our top priority. We carefully verify all our recommended caregivers to ensure they meet our high standards. We encourage careseekers to only work with verified caregivers, and we are committed to ensuring the safety of both parties.</div>
            </div>
          </div>
          <div class="core-values-item-row">
            <div class="core-values-item">
              <div class="img-div">
                <img src="@/assets/core-values.svg" alt="" />
                <p class="core-values-text">Empathy</p>
              </div>
              <div>Empathy is our superpower. We believe that truly understanding and sharing the feelings of others creates the strongest connections. Our focus on empathy leads to more compassionate and effective care.</div>
            </div>
            <div class="core-values-item">
              <div class="img-div">
                <img src="@/assets/core-values.svg" alt="" />
                <p class="core-values-text">Community</p>
              </div>
              <div>Ubuntu - I am because we are. At Succour, we believe in the power of community. We are all interconnected, and our success comes from working together with respect, dignity, and compassion. By supporting each other, we create a caring and successful community.</div>
            </div>
          </div>
        </div>
      </div>
      <!-- start our team -->
      <!-- <div class="our-team">
        <h3 class="commitment">Our team</h3>
        <p class="main-text">
          Our experienced and dedicated care team is committed to providing
          exceptional care. Each member brings unique skills and a passion for
          helping others.
        </p>
        <div class="our-team-div">
          <div class="individual">
            <img src="@/assets/our-team-placeholder.png" alt="">
            <div>
                <h1 class="our-team-header-text">James Doe</h1>
                <p class="our-team-norm-text">Co-Founder / CEO</p>
            </div>
          </div>
          <div class="individual">
            <img src="@/assets/our-team-placeholder.png" alt="">
            <div>
                <h1 class="our-team-header-text">James Doe</h1>
                <p class="our-team-norm-text">Co-Founder / CEO</p>
            </div>
          </div>
          <div class="individual">
            <img src="@/assets/our-team-placeholder.png" alt="">
            <div>
                <h1 class="our-team-header-text">James Doe</h1>
                <p class="our-team-norm-text">Co-Founder / CEO</p>
            </div>
          </div>
          <div class="individual">
            <img src="@/assets/our-team-placeholder.png" alt="">
            <div>
                <h1 class="our-team-header-text">James Doe</h1>
                <p class="our-team-norm-text"> Co-Founder / CEO</p>
            </div>
          </div>
        </div>
      </div> -->
    </div>
  </LoginLayout>
</template>

<script>
import LoginLayout from "@/layouts/LoginLayout.vue";

export default {
  name: "AboutUs",
  components: {
    LoginLayout,
  },

  setup() {
    return {};
  },
};
</script>

<style scoped>
.about-us-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.about-us-main {
  display: flex;
  margin-top: 64px;
  max-width: 1061px;
  width: 100%;
  gap: 60px;
  margin-bottom: 132px;
}
.about-us-text {
  padding: 0px 0px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.our-story {
  font-size: 20px;
  font-weight: 700;
  line-height: 34px;
  letter-spacing: 0.5px;
  text-align: left;
  color: var(--s-green-normal-active);
}
.caring-hands {
  font-family: Inter;
  font-size: 42px;
  font-weight: 700;
  line-height: 50.32px;
  letter-spacing: 0.5634920597076416px;
  text-align: left;

  color: var(--s-green-normal-active);
}
.main-text {
  font-size: 20px;
  font-weight: 400;
  line-height: 34px;
  letter-spacing: 0.5px;
  text-align: left;
}
.our-drive {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: #f59cab;
  padding: 95px 0px;
  width: 100%;
  gap: 24px;
  margin-bottom: 100px;
  
}
.our-drive-heading {
  font-family: Inter;
  font-size: 36px;
  font-weight: 700;
  line-height: 52px;
  letter-spacing: 0.5px;
  text-align: left;
  color: white;
}
.our-drive-text {
  font-family: Inter;
  font-size: 20px;
  font-weight: 400;
  line-height: 34px;
  letter-spacing: 0.5px;
  text-align: center;
  color: white;
  width: 860px;
}
.core-values {
  display: flex;
  flex-direction: column;
  margin-top: 64px;
  /* max-width: 1061px;
  width: 100%; */
     max-width: 1307px;
  width: 100%;
  gap: 16px;
  margin-bottom: 132px;
}
.commitment {
  font-family: Inter;
  font-size: 36px;
  font-weight: 700;
  line-height: 52px;
  letter-spacing: 0.5px;
  text-align: left;
}
.core-values-item-container {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  gap: 32px;
}
.core-values-item-row {
  display: flex;
  justify-content: space-between;
  gap: 24px;
}
.img-div {
  display: flex;
  align-items: center;
  gap: 16px;
}
.core-values-text {
  color: #082f21;
  font-family: Inter;
  font-size: 24px;
  font-weight: 700;
  line-height: 31.2px;
  letter-spacing: 0.5px;
  text-align: left;
}
.core-values-item {
  background-color: var(--s-pink-light);
  padding: 20px 0px 20px 20px;
  display: flex;
  gap: 8px;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}
.our-team-div {
  display: flex;
  gap: 49px;
  justify-content: space-between;
  margin-bottom: 200px;
}
.our-team-header-text{
  
font-size: 24px;
font-weight: 700;
line-height: 31.2px;
letter-spacing: 0.5px;
text-align: left;
color: var(--s-green-darker);
}
.our-team-norm-text{

font-size: 16px;
font-weight: 400;
line-height: 27.2px;
letter-spacing: 0.5px;
text-align: left;
color:#082F21
}
.our-team{
      max-width: 1307px;
  width: 100%;
  display: flex;
  gap: 24px;
  flex-direction: column;
}
.individual img{
    width: 100%;
    /* height: 100%; */
    object-fit: cover;
}
@media screen and (max-width: 800px) {
  .our-team-div{
    flex-direction: column;
    gap: 20px
  }
  .core-values-item-row{
    flex-direction: column;
  }
  .core-values{
    margin-bottom: 50px
  }
  .our-drive{
    margin-bottom: 30px;
  }
  .our-drive-text{
    font-family: Inter;
    font-size: 12px;
    font-weight: 400;
    line-height: 34px;
    letter-spacing: 0.5px;
    text-align: center;
    color: white;
    width: 300px;
  }
}
</style>
