<template>
  <div>
    <div v-if="!isViewReport" class="main-table-container">
      <h2>Reports</h2>
      <div class="filter-container">
        <div class="custom-select-wrapper">
          <div @click="toggleDropdown" class="custom-select">
            <div style="gap: 7.33px; display: flex; align-items: center">
              <svg
                width="20"
                height="14"
                viewBox="0 0 20 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.77778 13.6693H12.2222V11.447H7.77778V13.6693ZM0 0.335938V2.55816H20V0.335938H0ZM3.33333 8.11371H16.6667V5.89149H3.33333V8.11371Z"
                  fill="black"
                />
              </svg>

              <span>{{ selectedCategory }}</span>
            </div>
            <svg
              class="caret-icon"
              width="12"
              height="8"
              viewBox="0 0 12 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12 1.70688L10.59 0.296875L6 4.87687L1.41 0.296875L0 1.70688L6 7.70688L12 1.70688Z"
                fill="black"
              />
            </svg>
          </div>
          <ul v-if="isOpen" class="custom-options">
            <li
              v-for="category in uniqueCategories"
              :key="category"
              @click="selectCategory(category)"
              :class="{ selected: selectedCategory === category }"
            >
              {{ category }}
            </li>
          </ul>
        </div>
      </div>
      <div class="careseekers-table-container">
        <table class="careseekers-table">
          <thead>
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th>Date Reported</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(careseeker, index) in filteredCareseekers"
              :key="careseeker.id"
            >
              <td>{{ careseeker.id }}</td>
              <td>{{ careseeker.name }}</td>
              <td>{{ careseeker.date_reported }}</td>
              <td :class="careseeker.status.toLowerCase()">
                {{ careseeker.status }}
              </td>
              <td class="the-td">
                <span class="icon-dots" @click="toggleReportsDropdown(index)">
                  <svg
                    width="16"
                    height="4"
                    viewBox="0 0 16 4"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M2 0C0.9 0 0 0.9 0 2C0 3.1 0.9 4 2 4C3.1 4 4 3.1 4 2C4 0.9 3.1 0 2 0ZM14 0C12.9 0 12 0.9 12 2C12 3.1 12.9 4 14 4C15.1 4 16 3.1 16 2C16 0.9 15.1 0 14 0ZM6 2C6 0.9 6.9 0 8 0C9.1 0 10 0.9 10 2C10 3.1 9.1 4 8 4C6.9 4 6 3.1 6 2Z"
                      fill="black"
                    />
                  </svg>
                </span>
                <div v-if="actionDropdown === index" class="dropdown-menu">
                  <button @click="viewReport(careseeker)">
                    <!-- <span class="icon"
                    ><svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8 0C3.584 0 0 3.584 0 8C0 12.416 3.584 16 8 16C12.416 16 16 12.416 16 8C16 3.584 12.416 0 8 0ZM1.6 8C1.6 4.464 4.464 1.6 8 1.6C9.48 1.6 10.84 2.104 11.92 2.952L2.952 11.92C2.104 10.84 1.6 9.48 1.6 8ZM8 14.4C6.52 14.4 5.16 13.896 4.08 13.048L13.048 4.08C13.896 5.16 14.4 6.52 14.4 8C14.4 11.536 11.536 14.4 8 14.4Z"
                        fill="black"
                      />
                    </svg>
                  </span> -->
                    View report
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- Pagination controls (commented out for now) -->
      <!--
    <div class="pagination">
      <button @click="prevPage" :disabled="currentPage === 1">Previous</button>
      <span>Page {{ currentPage }} of {{ totalPages }}</span>
      <button @click="nextPage" :disabled="currentPage === totalPages">Next</button>
    </div>
    --></div>
    <div style="width: 930px" v-if="isViewReport">
      <div @click="backtoDashboard" class="caregiver-details-first-div">
        <svg
          width="8"
          height="12"
          viewBox="0 0 8 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.70492 1.41L6.29492 0L0.294922 6L6.29492 12L7.70492 10.59L3.12492 6L7.70492 1.41Z"
            fill="black"
          />
        </svg>
        <p>Back to Dashboard</p>
      </div>

      <!-- start -->
      <div class="main-container">
        <div class="main-container-section">
          <div class="content-section">
            <div class="profile-section">
              <h2>Report Details</h2>
              <div>
                <div>
                  <span class="span-header"> Filed By: </span>
                  <span class="span-normal"> Taiwo Adeniji</span>
                </div>
                <div>
                  <span class="span-header"> Against: </span>
                  <span class="span-normal"> Taiwo Adeniji</span>
                </div>
                <div>
                  <span class="span-header"> Date: </span>
                  <span class="span-normal">12 / 04 / 2024 </span>
                </div>
              </div>
            </div>
          </div>
          <div
            style="display: flex; flex-direction: column; gap: 24px"
            class="content-section"
          >
            <div>
              <span class="span-header"> Incident: </span>
              <span class="span-normal">Indecent behaviour </span>
            </div>
            <div style="display: flex; flex-direction: column; gap: 8px">
              <span class="span-header"> Details on incident </span>
              <p class="span-normal">
                Indecent Donec euismod lorem eget laoreet rutrum. Pellentesque
                vitae urna id urna ornare iaculis. Nulla facilisi. In hac
                habitasse platea dictumst. Nulla ac sapien sapien. Pellentesque
                habitant morbi tristique senectus et netus et malesuada fames ac
                turpis egestas.
              </p>
            </div>
          </div>

          <!-- end -->
        </div>
         <div class="button-div">
        <BaseButton
          label="Resolve"
          backgroundColor="var(--s-pink-normal)"
          textColor="var(--state-white)"
          height="60px"
          width="421px"
        />
        <BaseButton
          label="Close"
          backgroundColor="transparent"
          textColor="var(--s-green-normal-active)"
          height="60px"
          width="421px"
          borderColor="var(--s-green-normal-active)"
          @click="backtoDashboard"
        />
      </div>
      </div>
     
    </div>
  </div>
</template>


<script>
import { ref, computed } from "vue";
import BaseButton from "@/components/BaseButton.vue";
export default {
  name: "AdminReports",
  components: {
    BaseButton,
  },
  setup() {
    const careseekers = ref([
      {
        id: "001",
        name: "Janet Adeyemi",
        date_reported: "12 / 04 / 2024",
        status: "Resolved",
      },
      {
        id: "002",
        name: "Janet Adeyemi",
        date_reported: "12 / 04 / 2024",
        status: "Unresolved",
      },
      {
        id: "003",
        name: "Janet Adeyemi",
        date_reported: "12 / 04 / 2024",
        status: "Resolved",
      },
      // Add more data here
    ]);
    const isViewReport = ref(false);
    const selectedCategory = ref("All categories");
    const isOpen = ref(false);
    const actionDropdown = ref(null);

    const toggleReportsDropdown = (index) => {
      actionDropdown.value = actionDropdown.value === index ? null : index;
    };
    const uniqueCategories = computed(() => {
      const categories = careseekers.value.flatMap(
        (careseeker) => careseeker.categories
      );
      return ["All categories", ...new Set(categories)];
    });

    const filteredCareseekers = computed(() => {
      if (selectedCategory.value === "All categories") {
        return careseekers.value;
      }
      return careseekers.value.filter((careseeker) =>
        careseeker.categories.includes(selectedCategory.value)
      );
    });

    const handleAction = (careseeker) => {
      console.log("Action clicked for:", careseeker);
      // Handle your action here
    };

    const toggleDropdown = () => {
      isOpen.value = !isOpen.value;
    };

    const viewReport = (careseeker) => {
      console.log("Delisting caregiver:", careseeker);
      // Handle the delisting action here
      isViewReport.value = true;
      actionDropdown.value = null; // Close the dropdown after action
    };

    const selectCategory = (category) => {
      selectedCategory.value = category;
      isOpen.value = false;
    };
    const backtoDashboard = () => {
      isViewReport.value = false;
    };
    // Pagination states (commented out for now)
    /*
    const currentPage = ref(1);
    const itemsPerPage = ref(10);

    const paginatedCareseekers = computed(() => {
      const start = (currentPage.value - 1) * itemsPerPage.value;
      const end = start + itemsPerPage.value;
      return filteredCareseekers.value.slice(start, end);
    });

    const totalPages = computed(() => {
      return Math.ceil(filteredCareseekers.value.length / itemsPerPage.value);
    });

    const nextPage = () => {
      if (currentPage.value < totalPages.value) {
        currentPage.value++;
      }
    };

    const prevPage = () => {
      if (currentPage.value > 1) {
        currentPage.value--;
      }
    };
    */

    return {
      careseekers,
      selectedCategory,
      uniqueCategories,
      filteredCareseekers,
      handleAction,
      toggleDropdown,
      actionDropdown,
      isOpen,
      selectCategory,
      toggleReportsDropdown,
      viewReport,
      isViewReport,
      backtoDashboard,
      // Pagination properties (commented out for now)
      /*
      currentPage,
      itemsPerPage,
      paginatedCareseekers,
      totalPages,
      nextPage,
      prevPage,
      */
    };
  },
};
</script>



<style scoped>
.main-table-container {
  padding: 20px;
  display: flex;
  flex-direction: column;
  width: 1008px;
  margin-top: 52px;
}

.filter-container {
  margin-bottom: 20px;
}

.custom-select-wrapper {
  position: relative;
  display: inline-block;
  width: 340px;
}

.custom-select {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #979797;
  border-radius: 12px;
  padding: 16px;
  cursor: pointer;
}

.custom-options {
  position: absolute;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 4px;
  width: 340px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  margin-top: 8px;
  z-index: 10;
  list-style-type: none;
  padding: 0;
}

.custom-options li {
  padding: 8px;
  cursor: pointer;
}

.custom-options li:hover,
.custom-options li.selected {
  background-color: #f4f4f4;
}

.careseekers-table {
  width: 100%;

  border-spacing: 0 13px;

  /* opacity: 0.5; */
}
.careseekers-table-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  border-radius: 12px;
  padding: 24px;
  background-color: #ececec80;
  min-height: 772px;
  margin-bottom: 400px;
}

.careseekers-table th {
  /* opacity: 1; */
  padding: 8px;

  font-size: 16px;
  font-weight: 500;
  line-height: 27.2px;
  letter-spacing: 0.5px;
  text-align: left;
}

.careseekers-table td {
  padding: 8px;

  font-size: 16px;
  font-weight: 400;

  letter-spacing: 0.5px;
  text-align: left;
}

.careseekers-table th {
  background-color: #f4f4f4;
}

.careseekers-table td.resolved {
  width: 93px;
  height: 35px;
  background-color: var(--state-success);
  color: var(--state-white);
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}

.careseekers-table td.unresolved {
  width: 111px;
  height: 35px;
  padding: 8px;
  background-color: var(--state-error);
  color: var(--state-white);
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}

.dropdown-menu {
  position: absolute;
  right: 80px; /* Align to the right edge of the icon-dots container */
  top: -20px;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 12px;
  box-shadow: 0px 2px 4px 0px #0000001a;
  padding: 10px;
  transform: translateY(100%); /* Position below the icon-dots */
  z-index: 10;
}

.dropdown-menu button {
  display: flex;
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;
  padding: 4px 8px;
  width: 100%;
  white-space: nowrap;
  text-align: left;
}

.dropdown-menu .icon {
  margin-right: 8px;
}
h2{
  margin-bottom: 15px;
}
.selected-option {
  display: flex;
  align-items: center;
}

.selected-option svg {
  margin-right: 8px;
}
.the-td {
  position: relative;
}
.icon-dots {
  display: flex;
  padding-left: 12px;

  cursor: pointer;
}
.caregiver-details-first-div {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  margin-top: 30px;
}
.caregiver-details {
  padding-left: 46px;
  margin-top: 30px;
}
.content-section:last-child {
  border-bottom: 0px;
}
.content-section {
  padding-bottom: 24px;
  margin-bottom: 24px;
  border-bottom: 1px solid #979797;
  width: 100%;
}
.jobs-list-item-type-of-care-container {
  display: flex;
  gap: 152px;
}
.span-header {
  font-size: 16px;
  font-weight: 700;
  line-height: 27.2px;
  letter-spacing: 0.5px;
  text-align: left;
}
.span-normal {
  font-size: 16px;
  font-weight: 400;
  line-height: 27.2px;
  letter-spacing: 0.5px;
  text-align: left;
}
.main-container-section {
  display: flex;

  flex-direction: column;
}
.main-container {
  padding: 20px;
  background-color: var(--state-white);
  border-radius: 12px;
  margin-top: 22px;
}
.button-div {
  display: flex;
  justify-content: space-between;
  /* margin-bottom: 129px; */
  margin-top: 20px;
}
/* Pagination styles (commented out for now) */
/*
.pagination {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pagination button {
  padding: 8px 16px;
  border: 1px solid #ddd;
  background-color: white;
  cursor: pointer;
}

.pagination button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}
*/
@media (max-width: 800px) {
 .main-table-container{
  width: 260px;
 }
 .careseekers-table{
  border-spacing: 0px 5px;
 }

 .careseekers-table td.active {
  width: 30px;
  height: 25px;
  background-color: var(--state-success);
  color: var(--state-white);
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}

.careseekers-table td.inactive {
  width: 33px;
  height: 25px;
  padding: 8px;
  background-color: #979797;
  color: var(--state-white);
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}
.careseekers-table th {
  /* opacity: 1; */
  padding: 8px;

  font-size: 7px;
  font-weight: 500;
  line-height: 27.2px;
  letter-spacing: 0.5px;
  text-align: left;
}

.careseekers-table td {
  padding: 8px;

  font-size: 7px;
  font-weight: 400;

  letter-spacing: 0.5px;
  text-align: left;
}
.custom-select-wrapper{
  width: 200px
}

.careseekers-table td.resolved {
  width: 30px;
  height: 35px;
  background-color: var(--state-success);
  color: var(--state-white);
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}

.careseekers-table td.unresolved {
  width: 30px;
  height: 35px;
  padding: 8px;
  background-color: var(--state-error);
  color: var(--state-white);
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}
}
</style>
