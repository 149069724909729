<template>
  <div class="main-container">
    \ <LoadingSpinner :isLoading="isLoading" />
    <!-- senior care -->
    <div class="careseeker-container">
      <div>
        <h1>What kind of help are you looking for ?</h1>
      </div>
      <div class="care-card-container">
        <div
          v-for="service in services"
          :key="service.id"
          class="care-card"
          :class="{ 'care-card-selected': service.selected }"
          @click="toggleService(service)"
        >
          <div class="label-div">
            <h3>{{ service.main }}</h3>
            <!-- <p>{{ service.label }}</p> -->
          </div>
          <input type="checkbox" :checked="service.selected" />
        </div>
      </div>
      <div>
        <BaseButton
          label="Next"
          :disabled="isSeniorCareDisabled"
          backgroundColor="var(--s-pink-normal)"
          textColor="var(--state-white)"
          disabledBackgroundColor="#ECECEC"
          disabledColor="#ECECEC"
          disabledTextColor="#979797"
          height="60px"
          width="400px"
          @click="handleClick"
        />
        <!-- <BaseButton
          label="No, continue"
          backgroundColor="transparent"
          textColor="#0063F7"
          height="60px"
          width="400px"
          @click="handleClickTwo"
        /> -->
      </div>
    </div>
    <!-- senior care end -->
    <!-- 
    <div
      v-else-if="formData.stepFourData.type_of_care === 'Home care'"
      class="careseeker-inner-container"
    >
      <div>
        <h1>What kind of help are you looking for?</h1>
      </div>
     
      <div class="care-card-container">
        <div
          v-for="service in secondServices"
          :key="service.id"
          class="care-card"
          :class="{
            'care-card-selected': homeCareData.services.includes(service.id),
          }"
          @click="toggleService(service.id, 'homeCare')"
        >
          <div class="label-div">
            <h3>{{ service.main }}</h3>
          </div>
          <input
            type="checkbox"
            :checked="homeCareData.services.includes(service.id)"
          />
        </div>
      </div>
    </div> -->

    <!-- home care end -->
  </div>
</template>

<script>
import BaseButton from "@/components/BaseButton.vue";
import { ref, inject, watch, computed, onMounted } from "vue";
import { useRouter } from "vue-router";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import Request from "@/utils/apiclient.js";
export default {
  name: "CareSeekerCreateJobStepTwo",
  components: {
    BaseButton,
    LoadingSpinner,
  },
  setup() {
    const router = useRouter();
    const formData = inject("onboardingCareSeekerformData");
    const updateFormData = inject("onboardingUpdateCareSeekerformData");
    const localData = ref({
      ...formData.value.stepFiveData,
    });
    if (
      formData.value.stepFourData.type_of_care == "" ||
      !formData.value.stepFourData.type_of_care
    ) {
      router.push("/careseeker-onboarding/job-post/step-1");
    }
    console.log("form data here", formData.value.stepFourData.type_of_care);

    const isLoading = ref(false);
    const services = ref([]);
    const fetchCareCategory = () => {
      isLoading.value = true;
      const request = new Request("/category/");
      request.get((res, err) => {
        isLoading.value = false;
        if (err) {
          console.error("Error:", err);
        } else {
          console.log("Data:", res);
          console.log(
            "form data here two",
            formData.value.stepFourData.type_of_care
          );

          let careCategory = res.results.find(
            (category) =>
              category.name === formData.value.stepFourData.type_of_care
          );
          console.log("care category", careCategory);

          if (careCategory && careCategory.sub_categories) {
            services.value = careCategory.sub_categories.map((sub) => ({
              id: sub.id,
              main: sub.name,
              label: sub.name,
              selected: false,
            }));
          } else {
            services.value = [];
          }
        }
      });
    };

    onMounted(() => {
      fetchCareCategory();
    });
    //  const userProfile = JSON.parse(localStorage.getItem("_userProfile"));
    // const care_seekers = userProfile.care_seekers;
    // const userId = userProfile.id;

    console.log("form data", formData);

    const homeCareData = ref({
      services: [],
    });
    const secondServices = [
      { id: "carpet_cleaning", main: "Carpet cleaning" },
      { id: "bathroom_cleaning", main: "Bathroom cleaning" },
      { id: "kitchen_cleaning", main: "Kitchen cleaning" },
      { id: "window_cleaning", main: "Window cleaning" },
      { id: "laundry", main: "Laundry" },
    ];
    const toggleHomeService = (serviceId, type) => {
      if (type === "homeCare") {
        const index = homeCareData.value.services.indexOf(serviceId);
        if (index === -1) {
          homeCareData.value.services.push(serviceId);
        } else {
          homeCareData.value.services.splice(index, 1);
        }
      }
    };

    const disabled = ref(true);

    const toggleService = (selectedService) => {
      selectedService.selected = !selectedService.selected;
      updateDisabledState();
    };

    const updateDisabledState = () => {
      disabled.value = services.value.every((service) => !service.selected);
    };
    // senior care end

    const handleClick = () => {
      router.push("/careseeker-onboarding/job-post/step-3");

      // Assuming `services.value` is an array of objects with an `id` property and a `selected` property
      let chosenServices = services.value
        .filter((service) => service.selected) // Filter services that are selected
        .map((service) => parseInt(service.id, 10)); // Map to IDs and convert to integers

      console.log("Chosen services IDs:", chosenServices);
      localData.value.sub_category = [...chosenServices];
      updateFormData("stepFiveData", localData.value);
      console.log("form three data here localData", localData);
      console.log("form three data here", formData);
    };

    // const handleClickTwo = () => {
    //   router.push("/careseeker-onboarding/job-post/step-1");
    // };

    const isSeniorCareDisabled = computed(() => {
      return services.value.every((service) => !service.selected);
    });

    watch(
      localData,
      (newValue) => {
        updateFormData("stepThreeData", newValue);
      },
      { deep: true }
    );

    return {
      services,
      localData,
      disabled,
      handleClick,
      // handleClickTwo,
      toggleService,
      toggleHomeService,
      formData,
      isLoading,
      secondServices,
      homeCareData,
      isSeniorCareDisabled,
    };
  },
};
</script>



<style scoped>
.time-picker {
  display: flex;

  flex-direction: column;
  gap: 8px;
  margin-top: 10px;
  position: relative;
}

.time-picker select {
  padding: 10px 24px 10px 24px;
  min-width: 160px;
  border-radius: 12px;
  border: 1px solid #979797;
  background-color: white;
  font-size: 16px;
  font-weight: 400;
  line-height: 27.2px;
  letter-spacing: 0.5px;
  text-align: left;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  position: relative;
  background: url('data:image/svg+xml;utf8,<svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12 1.70492L10.59 0.294922L6 4.87492L1.41 0.294921L-6.16331e-08 1.70492L6 7.70492L12 1.70492Z" fill="black"/></svg>')
    no-repeat right 26px center;
  background-size: 10px 6px;
}

.time-picker select::-ms-expand {
  display: none; /* Remove default arrow in Internet Explorer */
}

.custom-multiselect {
  position: relative;
  width: 100%;
}
.multiselect-option.is-selected {
  background: var(--s-green-normal-active);
  color: #000;
}
.custom-multiselect .multiselect {
  width: 100%;
  padding: 10px;
  height: 60px;
  margin-bottom: 24px;
  font-size: 16px;
  border: 1px solid #979797;
  border-radius: 12px;
  background-color: transparent; /* White background color */
}

.custom-multiselect .multiselect__content {
  background-color: transparent; /* White background for options */
}
.multiselect {
  background: transparent;
}

.label-selected {
  color: var(--s-green-normal-active); /* Change to desired color */
  /* font-weight: bold; */
}
.custom-multiselect-selected .multiselect {
  border: 1px solid var(--s-green-normal-active); /* Change to desired color when a value is selected */
}
h1 {
  color: #0a3828;
  font-size: 24px;
  font-weight: 700;
  line-height: 31.2px;
  letter-spacing: 0.5px;
  margin: 0px;
  text-align: center;
}
.main-container {
  padding-top: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.careseeker-container {
  display: flex;
  justify-content: center;

  flex-direction: column;
  width: 400px;
}

.care-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 16px 24px;
  border-radius: 12px;
  border: 1px solid #979797;
  box-sizing: border-box;
  transition: background-color 0.3s, border-color 0.3s;
  cursor: pointer;
}

.care-card-selected {
  background-color: var(--s-pink-light-hover);
  border-color: var(--s-green-normal-active);
}

.care-card-container {
  width: 400px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 42px;
  margin-bottom: 24px;
}

.care-card p {
  color: #0d4a34;
}

.care-card div:first-child {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 6px;
}

.care-card input[type="checkbox"] {
  appearance: none;
  width: 18px;
  height: 18px;
  border: 2px solid #979797;
  background-color: transparent;
  display: grid;
  place-content: center;
  transition: background-color 0.3s, border-color 0.3s;
}

.care-card input[type="checkbox"]::before {
  content: "";
  width: 12px;
  height: 12px;
  transform: scale(0);
  transition: transform 0.3s;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
  background-color: var(--s-green-normal-active);
}

.care-card input[type="checkbox"]:checked {
  border-color: var(--s-green-normal-active);
}

.care-card input[type="checkbox"]:checked::before {
  transform: scale(1);
}
h3 {
  font-size: 20px;
  font-weight: 400;
  line-height: 31.2px;
  letter-spacing: 0.5px;
  text-align: left;
  width: 100%;
  margin: 0px;
}
.label-div {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 4px;
}
.label-div p {
  font-size: 14px;
  font-weight: 400;
  line-height: 23.8px;
  letter-spacing: 0.5px;
  text-align: left;
  color: #000000;
}
/* child care */
.child-details {
  margin-top: 10px;
}
.button-div {
  display: flex;
  gap: 47px;
  align-items: center;
  margin-top: 16px;
  justify-content: center;
}
.child-text {
  font-size: 16px;
  font-weight: 700;
  line-height: 27.2px;
  letter-spacing: 0.5px;
  text-align: left;
}
h2 {
  font-size: 24px;
  font-weight: 700;
  line-height: 31.2px;
  letter-spacing: 0.5px;
  text-align: center;
  color: #0a3828;
}
.child-text-main {
  font-size: 16px;
  font-weight: 400;
  line-height: 23.8px;
  letter-spacing: 0.5px;
}
.button-class-div {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
.button-class-div button {
  width: 126.67px;
  height: 44px;
  padding: 10px;
  border-radius: 12px;
  border: 1px solid #979797;
 
  background-color: transparent;

  font-size: 14px;
  font-weight: 400;
  line-height: 23.8px;
  letter-spacing: 0.5px;
}

.button-class-div button.selectedAge {
  background-color: var(--s-pink-light-hover);
  color: var(--s-green-dark);
  border-color: var(--s-green-normal);
}
.gender-group {
  display: flex;
  gap: 10px;
  margin-top: 8px;
}

.gender-group button {
  flex: 1;

  background-color: transparent;
  cursor: pointer;
  transition: background-color 0.3s, border-color 0.3s;
  padding: 10px 24px 10px 24px;
  gap: 10px;
  border-radius: 12px;
  border: 1px solid #979797;
  height: 48px;
}

.gender-group button.selected {
  background-color: var(--s-pink-light-hover);
  color: var(--s-green-dark);
  border-color: var(--s-green-normal);
}
.child-details-div {
  margin-bottom: 16px;
}
.child-details-div-two {
  margin-bottom: 24px;
}
/* end child care */
/* start academic care */
.subjects-div {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
.subjects-div button {
  min-width: 126.67px;
  height: 44px;
  padding: 10px;
  border-radius: 12px;
  border: 1px solid #979797;
 
  background-color: transparent;
  white-space: nowrap;
  font-size: 14px;
  font-weight: 400;
  line-height: 23.8px;
  letter-spacing: 0.5px;
}
.subjects-div button.active {
  background-color: var(--s-pink-light-hover);
  color: var(--s-green-dark);
  border-color: var(--s-green-normal);
}
/* end academic care */
/* start home care */
.home-care-input-group {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.counter {
  display: flex;
  align-items: center;
  gap: 24px;
}
.home-care-input-group-container {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
/* end home care */
label {
  font-size: 14px;
  font-weight: 400;
  line-height: 23.8px;
  letter-spacing: 0.5px;
  text-align: left;
  color: #979797;
  margin-bottom: 8px;
}
.label-selected {
  color: var(--s-green-normal);
}
</style>


