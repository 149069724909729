<template>
  <div class="form-container">
    <!-- <div class="inputs-div">
      <CustomInput
        label="First Name"
        v-model="firstName"
        placeholder="First Name"
        type="text"
        focusColor="#17865F"
        defaultColor="#979797"
      />
      <CustomInput
        label="Last Name"
        v-model="lastName"
        placeholder="Last Name"
        type="text"
        focusColor="#17865F"
        defaultColor="#979797"
      />
    </div> -->
    <!-- <div class="form-group">
      <label for="dob" class="dob-class" :class="{ active: dob }"
        >Date of birth</label
      >
      <div class="input-container">
        <input
          id="dob"
          type="date"
          placeholder="Enter Date of birth"
          v-model="dob"
        />
        <span class="icon">
          <svg
            width="22"
            height="24"
            viewBox="0 0 22 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M19.6004 2.4H18.4004V0H16.0004V2.4H6.40039V0H4.00039V2.4H2.80039C1.46839 2.4 0.412391 3.48 0.412391 4.8L0.400391 21.6C0.400391 22.92 1.46839 24 2.80039 24H19.6004C20.9204 24 22.0004 22.92 22.0004 21.6V4.8C22.0004 3.48 20.9204 2.4 19.6004 2.4ZM19.6004 21.6H2.80039V9.6H19.6004V21.6ZM19.6004 7.2H2.80039V4.8H19.6004V7.2ZM7.60039 14.4H5.20039V12H7.60039V14.4ZM12.4004 14.4H10.0004V12H12.4004V14.4ZM17.2004 14.4H14.8004V12H17.2004V14.4ZM7.60039 19.2H5.20039V16.8H7.60039V19.2ZM12.4004 19.2H10.0004V16.8H12.4004V19.2ZM17.2004 19.2H14.8004V16.8H17.2004V19.2Z"
              fill="#979797"
            />
          </svg>
        </span>
      </div>
    </div> -->
 <ToastManagerVue ref="toastManager" />
    <div style="margin-bottom:40px">
      <SelectDropdown
        label="How did you hear about us?"
        id="hear-about-us"
        :options="hearAboutUsOptions"
        width="400px"
        height="60px"
        @update:selectedOption="handleAboutUsSelection"
      />
    </div>
    <!-- <p class="terms">
      By clicking “Join now” you agree to our <a href="#">terms of use</a> and
      <a href="#">privacy policy</a>
    </p> -->
    <div>
      <BaseButton
        label="Next"
        :disabled="disabled"
        backgroundColor="var(--s-pink-normal)"
        textColor="var(--state-white)"
        disabledBackgroundColor="#ECECEC"
        disabledColor="#ECECEC"
        disabledTextColor="#979797"
        height="60px"
        width="400px"
        @click="handleClick"
      />
    </div>
  </div>
</template>

<script>

import SelectDropdown from "@/components/SelectDropdown.vue";
import BaseButton from "@/components/BaseButton.vue";
import { useRouter } from "vue-router";
import { ref, inject, watch } from "vue";
import { formatErrorMessages } from "@/utils/errorHandler";
import ToastManagerVue from "@/components/ToastAlert/ToastManager.vue";
import Request from "@/utils/apiclient.js";
export default {
   name: "CareSeekerCreateAccountStepThree",
  components: {  SelectDropdown, BaseButton, ToastManagerVue },
  setup() {
      const toastManager = ref(null);
    const showToast = (msg, statusValue) => {
      toastManager.value.addToast(msg, statusValue);
    };
 const formData = inject("onboardingCareSeekerformData");
    const updateFormData = inject("onboardingUpdateCareSeekerformData");
    const localData = ref({
      ...formData.value.stepThreeData,
    });

    const router = useRouter();
    const hearAboutUsOptions = ref([
      {
        value: "Family",
        text: "Family",
      },
      {
        value: "Social media",
        text: "Social Media",
      },
      {
        value: "Friend",
        text: "Friends",
      },
    ]);
    const hearAboutUs = ref("");
    const handleAboutUsSelection = (value) => {
      hearAboutUs.value = value;
    };
    // const firstName = ref("");
    // const lastName = ref("");
    // const dob = ref("");

    watch(
      hearAboutUs,
      ( newHearAboutUs) => {
        // localData.value.firstname = newFirstName;
        // localData.value.lastname = newLastName;
        // localData.value.dob = newDob;
        localData.value.hear_about_us = newHearAboutUs;
      }
    );

    const handleClick = () => {
      updateFormData("stepThreeData", localData.value);
   
      console.log("formdata here", formData);

   
      // Handle the next button action here


      const payload = {
        document_type: formData.value.stepTwoData.document_type,
         document_number: formData.value.stepTwoData.document_number,
        category: formData.value.stepOneData.type_of_care,
        hear_about_us: formData.value.stepThreeData.hear_about_us,
      
      };
      console.log("payload here", payload);
      const request = new Request("/care-seeker/");
      const rawData = JSON.stringify(payload);
      console.log("raw data", rawData);
      request.post(rawData, (data, error) => {
        if (error) {
          console.log("data error", error);
           if (error.status == "failure"){
            return showToast(error.message, "error");
          }
          const errorMessages = formatErrorMessages(error);
          errorMessages.forEach((message) => showToast(message, "error"));
        } else {
          console.log("Success:", data);
          // localStorage.setItem("_userProfile", data)
          //  showToast(data.message, "success");
          showToast("Profile Update Successful", "success");
          // router.push("/login");
          setTimeout(() => {
                 router.push("/careseeker-onboarding/job-post/step-1");
          }, 1000);
        }
      });
    };

    return {
      hearAboutUsOptions,
      handleAboutUsSelection,
      handleClick,
      hearAboutUs,
      toastManager
    };
  },
};
</script>

<style scoped>
.form-container {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  padding: 20px;
}
.inputs-div {
  display: flex;
  gap: 16px;
}
.inputs-div .input-wrapper {
  width: 100%;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}
input[type="date"]::placeholder {
  /* Standard */
  color: #979797;
  font-family: "Inter", sans-serif; /* Ensure the font is applied to the placeholder */
}

input[type="date"]::-webkit-input-placeholder {
  /* WebKit browsers */
  color: #979797;
  font-family: "Inter", sans-serif; /* Ensure the font is applied to the placeholder */
}

input[type="date"]:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #979797;
  font-family: "Inter", sans-serif; /* Ensure the font is applied to the placeholder */
}

input[type="date"]::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #979797;
  font-family: "Inter", sans-serif; /* Ensure the font is applied to the placeholder */
}

input[type="date"]:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  color: #979797;
  font-family: "Inter", sans-serif; /* Ensure the font is applied to the placeholder */
}
.form-group {
  margin-bottom: 24px;
}
.form-group input {
  background-color: transparent;
  width: 100%;
  padding: 10px;
  font-size: 16px;
  transition: border-color 0.3s;
  padding: 16px 24px 16px 24px;
  border-radius: 12px;
  border: 1px solid #979797;
  box-sizing: border-box;
  font-family: var(--font-family);
}
.form-group input::placeholder {
  color: #979797;
}

.form-group.has-value label.active,
.form-group.has-value input:focus {
  color: var(--s-green-normal);
}

.form-group.has-value input {
  border-color: var(--s-green-normal);
}

.input-container {
  position: relative;
}

.input-container .icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

.terms {
  color: #000000;
  text-align: center;
  margin-top: 48px;
  font-size: 14px;
  font-weight: 400;
  line-height: 23.8px;
  letter-spacing: 0.5px;

  text-align: left;
  margin-bottom: 20px;
}

.terms a {
  color: #0063f7;
  text-decoration: none;

  font-size: 14px;
  font-weight: 400;
  line-height: 23.8px;
  letter-spacing: 0.5px;
}
.dob-class {
  color: #979797;
  font-size: 12px;
  font-weight: 400;
  line-height: 20.4px;
  letter-spacing: 0.5px;
  text-align: left;
}
</style>
