<template>
  <div class="rate-container">
    <!-- <ToastManagerVue ref="toastManager" /> -->
    <h1 style="margin-bottom: 45px">What would you like to pay for care?</h1>
    <!-- <p style="margin-bottom: 52px">
      The average range in your area is N 30 000 per month
    </p> -->
    <div class="rate-input">
      <!-- <label>My monthly rate</label> -->
      <div class="slider-container">
         <input
          type="range"
          v-model="rate"
          :min="50000"
          :max="150000"
          :step="1000"
          @input="updateFormattedRate"
          class="slider"
          :style="sliderStyle"
        />
        <div class="slider-value">N {{ formattedRate }}</div>
      </div>
      <div class="rate-display-container">
        <div class="rate-display">
          <span>N {{ formattedRate }}</span
          >/month
        </div>
      </div>
    </div>
    <div class="rate-info">
      <div><img src="@/assets/exclaim_icon.svg" /></div>
      <div class="average-text">
        The average monthly rate  in your area is N50,000
      </div>
    </div>
    <BaseButton
      label="Next"
      :disabled="rate === 0"
      backgroundColor="var(--s-pink-normal)"
      textColor="var(--state-white)"
      disabledBackgroundColor="#ECECEC"
      disabledColor="#ECECEC"
      disabledTextColor="#979797"
      height="60px"
      width="400px"
      @click="handleNext"
    />
  </div>
</template>

<script>
import { ref, computed, watch, inject } from "vue";
import BaseButton from "@/components/BaseButton.vue";
import { useRouter } from "vue-router";
// import { formatErrorMessages } from "@/utils/errorHandler";
// import ToastManagerVue from "@/components/ToastAlert/ToastManager.vue";

export default {
  name: "CareSeekerCreateJobStepSix",
  components: {
    BaseButton,
    // ToastManagerVue,
  },
  setup() {
    // const toastManager = ref(null);
    // const showToast = (msg, statusValue) => {
    //   toastManager.value.addToast(msg, statusValue);
    // };

    const formData = inject("onboardingCareSeekerformData");
    const updateFormData = inject("onboardingUpdateCareSeekerformData");
    const router = useRouter();
    if (
      formData.value.stepFourData.type_of_care == "" ||
      !formData.value.stepFourData.type_of_care
    ) {
      router.push("/careseeker-onboarding/job-post/step-1");
    }
    const localData = ref({
      ...formData.value.stepNineData,
    });

  const rate = ref(100000);
    const formattedRate = ref(rate.value.toLocaleString());

    const updateFormattedRate = () => {
      formattedRate.value = rate.value.toLocaleString();
      localData.value.monthly_rate = rate.value; // Update localData value
    };

  const sliderStyle = computed(() => {
      const min = 50000;
      const max = 150000;
      const percentage = ((rate.value - min) / (max - min)) * 100;
      return {
        "--value": rate.value,
        "--min": min,
        "--max": max,
        background: `linear-gradient(to right, var(--s-green-normal) ${percentage}%, #e0e0e0 ${percentage}%)`,
      };
    });
    const handleNext = () => {
      console.log(`Selected Rate: N ${rate.value}`);
      localData.value.pay_rate = rate.value;
      updateFormData("stepNineData", localData.value);
      console.log("formdata here", formData);

      const payload = {
        days: formData.value.stepEightData.days_of_the_week,
        budget: formData.value.stepNineData.pay_rate,
        state: formData.value.stepSevenData.state,
        lga: formData.value.stepSevenData.local_government,
        work_preference: formData.value.stepFourData.preference,
        care_giver_gender: formData.value.stepSixData.care_giver_gender,
        category: formData.value.stepFourData.type_of_care_id,
        sub_category: formData.value.stepFiveData.sub_category,
        data: formData.value.stepFiveData.specific_care_requirements
      };
      console.log("full form data", payload)
            const rawData = JSON.stringify(payload);
console.log("full form data", rawData)
router.push("/careseeker-onboarding/job-post/step-7");
      // const request = new Request("/job/");

      // request.post(rawData, (data, error) => {
      
      //   if (error) {
      //     console.log("data error", error);
      //     if (error.status == "failure") {
      //       return showToast(error.message, "error");
      //     }
      //     if (error.detail) {
      //       return showToast("Incorrect Email or Password", "error");
      //     }
      //     const errorMessages = formatErrorMessages(error);
      //     console.log("error", errorMessages);
      //     errorMessages.forEach((message) => showToast(message, "error"));
      //   } else {
      //     console.log("data")
      //   }
      // });
      // Handle the next button action here
      // router.push("/careseeker-onboarding/job-post/step-7");
    };

    watch(rate, updateFormattedRate);

    return {
      rate,
      formattedRate,
      sliderStyle,
      handleNext,
      // toastManager,
    };
  },
};
</script>

<style scoped>
.rate-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 60px;
  font-family: Arial, sans-serif;
}

h1 {
  color: #0a3828;
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 4px;
}

.rate-input {
  width: 100%;
  max-width: 400px;
  text-align: center;
  margin-bottom: 20px;
}

.rate-input label {
  display: block;
  font-size: 16px;
  color: #000000;
  margin-bottom: 56px;
  font-size: 14px;
  font-weight: 400;
  line-height: 23.8px;
  letter-spacing: 0.5px;
  text-align: left;
}

.slider-container {
  position: relative;
  width: 100%;
}

.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 10px;
  background: linear-gradient(to right, var(--s-green-normal) 50%, #e0e0e0 50%);
  border-radius: 5px;
  outline: none;
  padding: 0;
  margin: 0;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: #17865f;
  cursor: pointer;
  /* Inner white border and outer green border with 4px apart */
  box-shadow: 0 0 0 4px #ffffff, 0 0 0 8px #b7d9cd; /* Outer green border, 4px from white border */
}

.slider::-moz-range-thumb {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: #17865f;
  cursor: pointer;
  /* Inner white border and outer green border with 4px apart */
  box-shadow: 0 0 0 4px #ffffff, 0 0 0 8px #b7d9cd; /* Outer green border, 4px from white border */
}

.slider-container .slider-value {
  position: absolute;
  top: -40px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #17865f;
  color: white;
  padding: 5px 10px;
  border-radius: 20px;
  font-size: 14px;
}

.slider-value {
  position: absolute;
  top: -40px;
  left: 50%;
  transform: translateX(-50%);
  background-color: var(--s-green-normal);
  color: white;
  font-size: 14px;
  padding: 10px;
  gap: 10px;
  border-radius: 12px;
}

.rate-display-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.rate-display {
  font-size: 18px;
  color: #0a3828;
  margin-top: 23px;
  padding: 5px 10px;
  border: 1px solid #979797;
  border-radius: 5px;
  width: 146px;
  padding: 10px 12px 10px 12px;
  gap: 10px;
  border-radius: 12px;
}

.rate-info {
  display: flex;
  align-items: center;
  background-color: #0063f71a;
  color: #000000;
  padding: 10px 24px;
  border-radius: 12px;
  margin-bottom: 24px;
  max-width: 400px;
  text-align: left;
  gap: 16px;
  box-sizing: border-box;
}

.info-icon {
  background-color: #007bff;
  color: white;
  font-size: 14px;
  font-weight: bold;
  padding: 5px;
  border-radius: 50%;
  margin-right: 10px;
}

.next-button {
  background-color: #ff6978;
  color: white;
  font-size: 16px;
  padding: 10px 20px;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.next-button:disabled {
  background-color: #ececec;
  color: #979797;
  cursor: not-allowed;
}

.average-text {
  font-size: 14px;
  font-weight: 400;
  line-height: 23.8px;
  letter-spacing: 0.5px;
  text-align: left;
  color: #000000;
}
</style>
