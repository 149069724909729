<template>
  <LoginLayout>
    <div class="main-container">
      <h1>Frequently Asked Questions</h1>
      <div class="faq-container">
        <div class="sidebar">
          <ul>
            <li
              v-for="category in categories"
              :key="category.id"
              :class="{ active: category.id === selectedCategoryId }"
              @click="selectCategory(category.id)"
            >
              {{ category.name }}
            </li>
          </ul>
        </div>
        <div class="faq-content">
          <!-- <h2>{{ selectedCategory.name }}</h2> -->
          <div
            v-for="faq in selectedCategory.faqs"
            :key="faq.question"
            class="faq-item"
          >
            <h3>{{ faq.question }}</h3>
            <p>{{ faq.answer }}</p>
          </div>
        </div>
      </div>
    </div>
  </LoginLayout>
</template>

<script>
import { ref, computed } from "vue";
import LoginLayout from "@/layouts/LoginLayout.vue";

export default {
  name: "FAQPage",
  components: { LoginLayout },
  setup() {
    const categories = ref([
      {
        id: 1,
        name: "General Information",
        faqs: [
          {
            question: "What is Succour?",
            answer:
              "Succour is a mission-driven platform that connects caregivers with careseekers. Our goal is to match individuals needing care with verified caregivers to provide safe, compassionate, and high-quality services.",
          },
          {
            question: "How does Succour work?",
            answer:
              "Succour works by using a matching system that connects careseekers with qualified caregivers based on their specific needs. Careseekers can browse profiles, read reviews, and choose the caregiver that best fits their requirements.",
          },
          {
            question: "Who can use Succour?",
            answer:
              "Succour is designed for anyone seeking reliable caregiving services and for caregivers looking to offer their services. Whether you need help with elderly care, childcare, academic support, or housekeeping, Succour aims to connect you with the right professional.",
          },
          {
            question: "How does Succour ensure safety?",
            answer:
              "We take safety very seriously. All caregivers on our platform undergo a thorough verification process, including background checks and credential verification. We strongly advise careseekers to choose only verified caregivers.",
          },
          {
            question: "Is Succour available in my area?",
            answer:
              "Succour is continuously expanding. You can check our website for the most current list of locations we serve. If we are not yet in your area, please stay tuned as we grow our services.",
          },
          {
            question: "How do I sign up as a caregiver?",
            answer:
              "To become a caregiver on Succour, you can sign up on our website. You’ll be required to provide some personal details, proof of qualifications, and undergo a background check. Once verified, you can start offering your services.",
          },
          {
            question: "What types of care services does Succour provide?",
            answer:
              "Succour connects careseekers with caregivers across a range of services, including elderly care, childcare, academic support, and housekeeping. We strive to match every careseeker with a caregiver that meets their unique needs.",
          },
          {
            question: "How can I contact Succour for support?",
            answer:
              "If you need assistance, you can reach out to our customer support team via the contact form on our website, email us at succourcsc@gmail.com, or call our support hotline at +234 916 000 2370.",
          },
          {
            question: "How do I book a caregiver?",
            answer:
              "Once you've found a suitable caregiver profile, you can contact them directly through our platform to discuss your needs and schedule a service. Our platform provides tools for secure communication and booking.",
          },
          {
            question: "How are payments handled?",
            answer:
              "Payments are securely handled through our platform. Once you agree on terms with a caregiver, you can process the payment using our secure payment gateway. This ensures both parties are protected.",
          },
          {
            question: "What if I am not satisfied with the service provided?",
            answer:
              "If you're not satisfied with the service provided by a caregiver, please contact our customer support team. We take all feedback seriously and will work with you to resolve any issues.",
          },
          {
            question: "Can I trust the reviews and ratings on Succour?",
            answer:
              "Yes, our reviews and ratings are submitted by verified users of our platform. We encourage honest and constructive feedback to maintain a high standard of service quality.",
          },
        ],
      },
      {
        id: 2,
        name: "Services",
        faqs: [
         {
    question: "What types of services does Succour offer?",
    answer: "Succour offers a variety of services to meet different needs, including elderly care, childcare, academic support, and housekeeping. We aim to connect careseekers with caregivers who are skilled and experienced in these areas.",
  },
  {
    question: "How do I find a caregiver that meets my specific needs?",
    answer: "You can use our platform's search and filter options to find caregivers based on the type of service you need, such as elderly care, childcare, academic support, or housekeeping. You can also filter by location, availability, and specific skills or experience. Caregiver profiles and reviews are available to help you make an informed choice.",
  },
  {
    question: "Are caregivers available for both short-term and long-term arrangements?",
    answer: "Yes, Succour provides flexibility to accommodate both short-term and long-term care needs. Whether you need temporary help or ongoing support, you can find a caregiver that fits your schedule and requirements.",
  },
  {
    question: "How are caregivers verified on Succour?",
    answer: "All caregivers undergo a thorough verification process, including background checks and verification of qualifications. We ensure that our caregivers are trustworthy, reliable, and skilled in their respective areas of service.",
  },
  {
    question: "Can I request a specific caregiver?",
    answer: "Yes, if you find a caregiver whose profile and reviews align with your needs, you can directly request their services through our platform. You can communicate with them to discuss your requirements and availability before making a booking.",
  },
  {
    question: "What should I expect during the first visit from a caregiver?",
    answer: "During the first visit, the caregiver will introduce themselves, discuss the care plan, and understand the careseeker’s specific needs and preferences. This initial meeting is crucial for building trust and ensuring that the care provided meets your expectations.",
  },
  {
    question: "Can I change caregivers if I’m not satisfied?",
    answer: "Absolutely. If you're not satisfied with your current caregiver, you can search for and select a different caregiver through our platform. We value feedback to help us maintain high-quality service standards.",
  },
  {
    question: "What does elderly care include?",
    answer: "Elderly care services can include assistance with daily living activities, such as bathing, dressing, meal preparation, medication reminders, and companionship. Our caregivers are trained to provide compassionate support tailored to the needs of each elderly individual.",
  },
  {
    question: "What types of childcare services are available?",
    answer: "Our childcare services range from babysitting and daytime care to after-school care and support for special needs children. Caregivers are experienced in providing a safe, nurturing, and stimulating environment for children of all ages.",
  },
  {
    question: "How can caregivers help with academic support?",
    answer: "Caregivers offering academic support can assist with homework, tutoring in specific subjects, test preparation, and educational activities. They are experienced in working with children and teens to enhance their learning experience.",
  },
  {
    question: "What does housekeeping services include?",
    answer: "Housekeeping services can include tasks such as cleaning, laundry, organizing, and general household upkeep. Our caregivers are diligent and trustworthy, ensuring that your home is maintained to your standards.",
  },
  {
    question: "How do I provide feedback on the service I receive?",
    answer: "After each service, you can leave a review and rating on the caregiver’s profile through our platform. Your feedback helps us maintain high service standards and allows other careseekers to make informed decisions.",
  },
  {
    question: "Are there any additional costs for services provided by Succour?",
    answer: "All costs will be clearly outlined before you book a caregiver. There are no hidden fees, and you will be informed of any additional charges (e.g., for specialized care or extended hours) upfront.",
  },
  {
    question: "How can I cancel or reschedule a service?",
    answer: "You can cancel or reschedule a service through your account on our platform. We ask that you provide as much notice as possible to allow caregivers to adjust their schedules. Please review our cancellation policy for specific details.",
  },
        ],
      },
      {
        id: 3,
        name: "Billing and Payments",
        faqs: [
        {
    question: "How do I pay for services on Succour?",
    answer: "Payments are processed securely through our platform. Once you agree on the terms with a caregiver, you can complete the payment using our secure payment gateway, which supports various payment methods, including credit/debit cards and digital wallets.",
  },
  {
    question: "When will I be charged for the services?",
    answer: "You will be charged at the time of booking the service. This ensures that both parties have a confirmed commitment, and it allows us to provide a smooth and reliable service experience.",
  },
  {
    question: "Are there any hidden fees?",
    answer: "No, all fees and charges will be clearly outlined before you book a caregiver. We are committed to transparency, and you will be informed of any costs associated with the services upfront.",
  },
  {
    question: "Can I receive an invoice or receipt for my payment?",
    answer: "Yes, after completing a payment, you will receive an invoice or receipt via email. You can also access your payment history and download receipts from your account on our platform.",
  },
  {
    question: "How do refunds work?",
    answer: "If you are eligible for a refund under our cancellation policy or due to any other issue, the refund will be processed through the original payment method. Refunds typically take a few business days to reflect in your account.",
  },
  {
    question: "Can I pay caregivers directly?",
    answer: "No, all payments should be processed through our platform to ensure security and accountability. This also allows us to provide support and assistance if there are any issues with the service or payment.",
  },
  {
    question: "Are there any additional charges for last-minute bookings?",
    answer: "Additional charges may apply for last-minute bookings or urgent requests, depending on caregiver availability and the nature of the service. Any such charges will be clearly communicated to you before you confirm the booking.",
  },
  {
    question: "What should I do if there’s a problem with my payment?",
    answer: "If you encounter any issues with your payment, please contact our customer support team immediately. We are here to help resolve any payment-related problems and ensure a smooth experience for you.",
  },
  {
    question: "Can I set up recurring payments for ongoing services?",
    answer: "Yes, however we haven’t enabled the service yet. We will communicate with you when we do.",
  },
  {
    question: "Is my payment information secure?",
    answer: "Yes, we take the security of your payment information very seriously. Our platform uses industry-standard encryption and secure payment gateways to protect your financial data. We do not store sensitive payment information on our servers.",
  },
  {
    question: "Do you offer payment plans or financial assistance?",
    answer: "Currently, we do not offer payment plans or financial assistance. However, we strive to provide affordable care options and are constantly exploring ways to support our community. Please check our website for updates on this matter.",
  },
  {
    question: "What currencies do you accept for payment?",
    answer: "We primarily accept payments in local currency based on the region of service. The accepted currencies will be clearly indicated during the payment process. If you have any questions about payment currencies, please contact our support team.",
  },
  {
    question: "Can I split payments between multiple methods?",
    answer: "At this time, our payment system supports only one payment method per transaction. You can choose your preferred method during the payment process.",
  },
        ],
      },
      {
        id: 4,
        name: "Privacy and Security",
        faqs: [
      {
    question: "How does Succour protect my personal information?",
    answer: "We take your privacy seriously and use industry-standard security measures to protect your personal information. This includes encryption, secure servers, and strict access controls to ensure that your data is safe and only accessible to authorized personnel.",
  },
  {
    question: "What personal information does Succour collect?",
    answer: "We collect personal information necessary to provide our services, such as your name, contact details, and payment information. For caregivers, we may also collect details related to qualifications and background checks. We only collect information that is essential for delivering our services.",
  },
  {
    question: "How is my payment information protected?",
    answer: "Payment information is processed securely through our payment gateway, which uses encryption and other security protocols to protect your financial data. We do not store sensitive payment information on our servers.",
  },
  {
    question: "Does Succour share my personal information with third parties?",
    answer: "We do not sell or share your personal information with third parties for marketing purposes. We may share information with trusted service providers necessary for delivering our services, such as payment processors or background check agencies, under strict confidentiality agreements.",
  },
  {
    question: "How can I control my personal information?",
    answer: "You can manage and update your personal information by logging into your account on our platform. You have the right to access, correct, or delete your personal information at any time. If you need assistance, please contact our customer support team.",
  },
  {
    question: "Are communications on the Succour platform secure?",
    answer: "Yes, all communications between careseekers and caregivers on our platform are secured using encryption. This ensures that your messages and personal information are protected from unauthorized access.",
  },
  {
    question: "How does Succour handle data breaches?",
    answer: "In the unlikely event of a data breach, we will promptly notify affected users and take immediate steps to secure the data and prevent further unauthorized access. We will also follow all legal requirements for reporting and addressing data breaches.",
  },
  {
    question: "Can I delete my account and data from Succour?",
    answer: "Yes, you can request to delete your account and personal data at any time. Please contact our customer support team to initiate this process. We will delete your data in accordance with our privacy policy and applicable laws.",
  },
  {
    question: "What is Succour’s policy on cookies?",
    answer: "Our website uses cookies to enhance user experience and improve our services. Cookies help us understand how you interact with our site, remember your preferences, and provide personalized content. You can manage your cookie preferences through your browser settings.",
  },
  {
    question: "How does Succour ensure caregiver privacy?",
    answer: "Caregiver privacy is a top priority. We only collect information necessary for verification and service provision. Personal details shared with careseekers are limited to those required for service arrangement and are protected by our privacy policies.",
  },
  {
    question: "Is my location information safe with Succour?",
    answer: "Yes, any location information collected is used solely for matching careseekers with nearby caregivers and improving our services. We use secure methods to protect this data and do not share it with unauthorized parties.",
  },
  {
    question: "What are my data rights under the General Data Protection Regulation (GDPR), the Nigerian Data Protection Regulation (NDPR), the UK Data Protection Act, and US data protection laws?",
    answer: "If you are located in the European Union, the UK, Nigeria, or the USA, you have specific data rights under the applicable regulations: GDPR (EU): Right to access, rectify, or erase your personal data, right to restrict or object to processing, right to data portability, and right to withdraw consent at any time. NDPR (Nigeria): Right to access, correct, or delete your personal data, right to restrict processing, and right to object to processing. UK Data Protection Act: Right to access, correct, or delete your personal data, right to restrict processing, and right to object to processing. US Data Protection Laws: Rights vary by state, but may include access to personal data, request for correction, and opt-out options for certain types of data processing. You can exercise these rights by contacting us.",
  },
  {
    question: "How does Succour handle children's privacy?",
    answer: "Succour is committed to protecting children's privacy. We do not knowingly collect personal information from children under the age of 13 without parental consent. Caregivers providing childcare services must follow strict privacy guidelines to protect children's information.",
  },
  {
    question: "Can I trust the reviews and feedback left on the platform?",
    answer: "Yes, all reviews and feedback are from verified users who have used our services. We monitor reviews to ensure authenticity and maintain the integrity of our platform.",
  },
  {
    question: "How can I contact Succour about privacy concerns?",
    answer: "If you have any questions or concerns about your privacy or the security of your information, please contact our support team at succourcsc@gmail.com. We are committed to addressing your concerns and ensuring the safety of your personal data.",
  },
        ],
      },
      {
        id: 5,
        name: "Contact Support",
        faqs: [
       {
    question: "How can I contact Succour’s support team?",
    answer: "You can reach our support team via email at succourcsc@gmail.com or through WhatsApp at +234 916 000 2370 for quicker assistance. We are available to assist you with any questions, issues, or concerns you may have.",
  },
  {
    question: "What information should I include when contacting support?",
    answer: "When contacting support, please include your full name, email address, and a detailed description of your issue or question. Providing relevant information will help us address your concern more efficiently.",
  },
  {
    question: "What are the support team’s hours of operation?",
    answer: "Our support team is available Monday to Friday from 9 AM to 6 PM (local time). We strive to respond to all inquiries as quickly as possible during these hours.",
  },
  {
    question: "How long will it take to get a response from support?",
    answer: "We aim to respond to all support inquiries within 24 to 48 hours. If your issue is urgent, please indicate this in your message or WhatsApp, and we will prioritize your request.",
  },
  {
    question: "Can I contact support via phone or live chat?",
    answer: "Currently, support is available primarily through email and WhatsApp. We do not offer phone or live chat support at this time. If you have a preference for a different method of communication, please let us know, and we will do our best to accommodate your request.",
  },
  {
    question: "What should I do if I do not receive a response within the expected timeframe?",
    answer: "If you do not receive a response within the expected timeframe, please follow up with us by sending another email to succourcsc@gmail.com or a WhatsApp message to +234 916 000 2370. We may have missed your initial message or require additional information to assist you.",
  },
  {
    question: "How can I track the status of my support request?",
    answer: "You will receive a confirmation email or WhatsApp message when your support request is received. You can reply to this email or message for updates on your request.",
  },
  {
    question: "Can I provide feedback about my support experience?",
    answer: "Yes, we welcome feedback about your support experience. After your issue is resolved, we may send you a survey via email or WhatsApp to gather your feedback. Your input helps us improve our services and support.",
  },
  {
    question: "What should I do if I have a complaint about the support I received?",
    answer: "If you have a complaint about the support you received, please email us at succourlimited@gmail.com or send a WhatsApp message to +234 916 000 2370 with details of your experience. We take complaints seriously and will investigate and address any issues to ensure better service in the future.",
  },
        ],
      },
    ]);

    const selectedCategoryId = ref(categories.value[0].id);

    const selectedCategory = computed(() => {
      return categories.value.find(
        (category) => category.id === selectedCategoryId.value
      );
    });

    const selectCategory = (id) => {
      selectedCategoryId.value = id;
    };

    return {
      categories,
      selectedCategoryId,
      selectedCategory,
      selectCategory,
    };
  },
};
</script>

<style scoped>
.main-container {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 74px;
  flex-direction: column;
  align-items: center;
  gap: 80px;
}
h1 {
  text-align: left;

  font-size: 44px;
  font-weight: 700;
  line-height: 57.2px;
  letter-spacing: 0.5px;
  text-align: left;
  color: var(--s-green-normal);
  width: 1000px;
}
h3 {
  font-size: 16px;
  font-weight: 700;
  line-height: 27.2px;
  letter-spacing: 0.5px;
  text-align: justified;
}
p {
  font-size: 14px;
  font-weight: 400;
  line-height: 27.2px;
  letter-spacing: 0.5px;
  text-align: justified;
}
.faq-container {
  display: flex;
  width: 1000px;
  gap: 72px;
  margin-bottom: 218px;
}

.sidebar {
  width: 100%;
  max-width: 300px;
  background-color: #fef5f7;
  max-height: 420px;
}

.sidebar ul {
  list-style-type: none;
  padding: 0;
}

.sidebar li {
  cursor: pointer;
  padding: 24px 10px;
  border-bottom: 1px solid #ccc;

  font-size: 20px;
  font-weight: 400;
  line-height: 34px;
  letter-spacing: 0.5px;
  text-align: left;
}

.sidebar li.active {
  color: black;
  font-size: 20px;
  font-weight: 700;
  line-height: 34px;
  letter-spacing: 0.5px;
  text-align: left;
}

.faq-content {
  width: 100%;
}

.faq-item {
  margin-bottom: 20px;
}

.faq-item h3 {
  margin: 10px 0;
}

.faq-item p {
  margin: 0;
}
@media screen and (max-width: 800px) {
  .faq-container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .faq-content {
    width: 100%;
    max-width: 320px;
  }
  h1 {
    text-align: center;

    font-size: 20px;
    font-weight: 600;
    line-height: 14.2px;
    letter-spacing: 0.2px;

    color: var(--s-green-normal);
    width: 350px;
  }
}
</style>
