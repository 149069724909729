<template>
  <div class="toast-manager">
    <ToastMain
      v-for="(toast, index) in toasts"
      :key="index"
      :message="toast.message"
      :duration="toast.duration"
      :status="toast.status"
    />
  </div>
</template>

<script>
import { ref } from "vue";
import ToastMain from "@/components/ToastAlert/ToastMain.vue";

export default {
  components: {
    ToastMain,
  },
  setup() {
    const toasts = ref([]);

    const addToast = (message, status, duration = 5000) => {
      toasts.value.push({ message, status, duration });
      setTimeout(() => {
        toasts.value.shift();
      }, duration);
    };

    return {
      toasts,
      addToast,
    };
  },
};
</script>

<style scoped>
.toast-manager {
  position: fixed;
  top: 20px;
  right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
}
</style>
