<template>
  <div class="landing-container">
    <GenericModal
      :isVisible="isMainModalVisible"
      title="Succour Waitlist"
      @close="hideModal"
      padding="72.5px 15px"
    >
    <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSc_smoiCR4eaAXwQm9OVFqdprrggUcskSXj9MMBGNN4BKYldQ/viewform?embedded=true" width="100%" height="500" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
    </GenericModal>
    <div class="main">
      <header>
        <nav class="navbar">
          <img class="main-logo" src="@/assets/full_logo.svg" alt="Logo" />
          <div class="links">
            <!-- <router-link class="join-now" to="/onboarding"
              >Join now</router-link
            > -->
             <BaseButton
              label="Join now"
              backgroundColor="#F59CAB"
              textColor="white"
              width="120px"
              height="100%"
              @click="handleClick"
            />
          </div>
        </nav>
      </header>
      <!-- start main one -->

      <div class="main-one">
        <div class="waitlist-first-container">
          <div>
            <h4 class="waitlist-main-text">
              Find the Care <br />
              You Deserve, Now
            </h4>
          </div>
          <div style="display: flex; justify-content: center">
            <p class="waitlist-sub-text" style="text-align: center">
              Priority access to caregivers, personalized care matching. <br />
              Join our waitlist now
            </p>
          </div>
          <div>
            <BaseButton
              label="Join waitlist"
              backgroundColor="#F59CAB"
              textColor="white"
              width="243px"
              height="60px"
              @click="handleClick"
            />
          </div>
        </div>
        <div class="wait-list-main-image-container">
          <img src="@/assets/waitlist-image.png" alt="Waitlist Image" />
        </div>
        <!-- start care section -->
        <div class="start-care-div">
          <!-- Access a network of caregivers -->
          <div>
            <div class="access-network-div">
              <p class="access-network">
                Access a network of verified caregivers
              </p>
            </div>
            <div class="care-list">
              <div class="care-list-single">
                <img src="@/assets/carelist_first.png" alt="" />
                <div>
                  <p class="care-list-main-text">Senior Caregiver</p>
                  <p class="care-list-sub-text">Lagos, Nigeria</p>
                </div>
              </div>
              <div class="care-list-single">
                <img src="@/assets/carelist_second.png" alt="" />
                <div>
                  <p class="care-list-main-text">Child Caregiver</p>
                  <p class="care-list-sub-text">Lagos, Nigeria</p>
                </div>
              </div>
              <div class="care-list-single">
                <img src="@/assets/carelist_third.png" alt="" />
                <div>
                  <p class="care-list-main-text">Academic Tutor</p>
                  <p class="care-list-sub-text">Lagos, Nigeria</p>
                </div>
              </div>
              <div class="care-list-single">
                <img src="@/assets/carelist_fourth.png" alt="" />
                <div>
                  <p class="care-list-main-text">Home care</p>
                  <p class="care-list-sub-text">Lagos, Nigeria</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- end care section -->
      </div>
      <!-- end main one -->
    </div>
    <main>
      <div class="wait-list-benefits">
        <div class="wait-list-sub">
          <div style="padding-bottom: 75px">
            <div class="header-wait">
              <h5 class="wait-list-text-header">
                Benefits of Joining the Waitlist
              </h5>
              <p class="wait-list-text-body">
                We believe in a holistic approach to care, focusing on <br />the
                individual's overall well-being.
              </p>
            </div>
            <div class="waitlist-container">
              <div>
                <img src="@/assets/people_benefits.svg" />
                <p class="wait-list-text-body">Faster matching</p>
              </div>
              <div>
                <img src="@/assets/people_benefits.svg" />
                <p class="wait-list-text-body">Access to exclusive resources</p>
              </div>
              <div>
                <img src="@/assets/people_benefits.svg" />
                <p class="wait-list-text-body">Priority service</p>
              </div>
              <div>
                <img src="@/assets/people_benefits.svg" />
                <p class="wait-list-text-body">Excellence</p>
              </div>
            </div>
          </div>
          <div class="phone_mockup">
            <img src="@/assets/iphone15_mockup.png" alt="" />
          </div>
        </div>
      </div>
      <div class="main-two">
        <div class="find-job-care-provider">
          <div class="are-you">Looking to get Premium care?</div>
          <p>
            We believe in a holistic approach to care, focusing on the
            individual's overall <br />
            well-being.We believe in a holistic approach to care, focusing on
            the individual's <br />
            overall well-being.
          </p>
          <BaseButton
            label="Join the waitlist now"
            backgroundColor="var(--s-green-light)"
            textColor="var(--s-green-darker)"
            :width="isLargeScreen ? `281px` : `170px`"
            height="60px"
            @click="handleClick"
          />
        </div>
      </div>
    </main>
    <footer>
      <div class="footer">
        <div class="footer-content-container">
          <div class="logoandsocialmedia">
            <img
              src="@/assets/white_logo.svg"
              style="width: 167.32px; height: 60.05px; margin-bottom: 16px"
              alt="Logo"
            />
            <div style="margin-bottom: 61px">
              <p class="white-text">
                Succour helps families and individuals connect with trusted,
                verified caregivers
              </p>
            </div>
            <div class="social-media-links">
              <a
                href="https://www.linkedin.com"
                target="_blank"
                class="clickable-div"
              >
                <img src="../../assets/linkedin.svg" />
              </a>
              <a
                href="https://www.facebook.com"
                target="_blank"
                class="clickable-div"
              >
                <img src="../../assets/facebook.svg" />
              </a>
              <a
                href="https://www.instagram.com"
                target="_blank"
                class="clickable-div"
              >
                <img src="../../assets/instagram.svg" />
              </a>
              <a href="https://www.x.com" target="_blank" class="clickable-div">
                <img src="../../assets/x.svg" />
              </a>
            </div>
          </div>
          <div class="footer-section">
            <div>
              <p class="footer-header-text">About Succour</p>
            </div>
            <div>
              <router-link to="/">About us</router-link>
            </div>
            <div>
              <router-link to="/">Career</router-link>
            </div>
            <div>
              <router-link to="/">Terms of use</router-link>
            </div>
            <div>
              <router-link to="/">Privacy policy</router-link>
            </div>
          </div>
          <div class="footer-section">
            <div>
              <p class="footer-header-text">Get help</p>
            </div>
            <div>
              <router-link to="/">Safety</router-link>
            </div>
            <div>
              <router-link to="/">Articles and guidelines</router-link>
            </div>
            <div>
              <router-link to="/">Help center</router-link>
            </div>
          </div>
          <div class="footer-section">
            <div>
              <p class="footer-header-text">Services</p>
            </div>
            <div>
              <router-link to="/">Child care</router-link>
            </div>
            <div>
              <router-link to="/">Senior care</router-link>
            </div>
            <div>
              <router-link to="/">House care</router-link>
            </div>
            <div>
              <router-link to="/">Academic care</router-link>
            </div>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import { ref, onMounted, onBeforeUnmount } from "vue";
import BaseButton from "@/components/BaseButton.vue"; // Adjust the path as needed
import GenericModal from "@/components/GenericModal.vue";

export default {
  name: "LandingWaitlist",
  components: {
    BaseButton,
    GenericModal,
  },
  setup() {
    const isMainModalVisible = ref(false)
     const hideModal = () => {
      isMainModalVisible.value = false;
    };
 


//    const handleDocumentClick = (event) => {
//   const modalElement = document.querySelector('.modal-container'); 
//   if (isMainModalVisible.value && modalElement && !modalElement.contains(event.target)) {
//     hideModal();
//   }
// };

  
  
    const handleClick = () => {
      console.log("BaseButton clicked");

    //  isMainModalVisible.value = true;
    //   document.body.classList.add('modal-open');
    window.open('https://forms.gle/6rDXPBTfv5SbbJsT9', '_blank');
    };
    const isLargeScreen = ref(window.innerWidth > 1200);

    const handleResize = () => {
      isLargeScreen.value = window.innerWidth > 1200;
      console.log("inner", window.innerWidth);
    };

    onMounted(() => {
      window.addEventListener("resize", handleResize);
        // document.addEventListener("click", handleDocumentClick);
    });

    onBeforeUnmount(() => {
      window.removeEventListener("resize", handleResize);
    //    document.removeEventListener("click", handleDocumentClick);
    });
    return {
    
    handleClick,
      isLargeScreen,
      isMainModalVisible,
      hideModal
    };
  },
};
</script>

<style scoped>

.header-wait {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.wait-list-text-header {
  font-family: Inter;
  font-size: 36px;
  font-weight: 700;
  line-height: 52px;
  letter-spacing: 0.5px;
  color: #ffffff;
  text-align: left;
}
.wait-list-text-body {
  font-family: Inter;
  font-size: 20px;
  font-weight: 400;
  line-height: 34px;
  letter-spacing: 0.5px;
  text-align: left;
  color: #ffffff;
}
.phone_mockup {
  display: flex;
  align-items: flex-end;
}
.wait-list-sub {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 242px;
}
.find-job-care-provider {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: var(--s-pink-normal);
  padding-top: 108.5px;
  padding-bottom: 108.5px;
  gap: 48px;
}
.find-job-care-provider {
  background-image: url("@/assets/Layer1.png");
  background-size: cover; /* Ensure the image covers the entire background */
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Prevent the image from repeating */
  color: var(--state-white);
  font-size: 36px;
  font-weight: var(--font-weight-bold);
  line-height: 52px;
  letter-spacing: 0.5px;
  text-align: center;
}
.care-list-main-text {
  font-family: Inter;
  font-size: 20px;
  font-weight: 400;
  line-height: 34px;
  letter-spacing: 0.5px;
  text-align: left;
  color: var(--s-green-darker);
}
.care-list-sub-text {
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 27.2px;
  letter-spacing: 0.5px;
  text-align: left;
  color: var(--s-green-darker);
}
.care-list {
  display: flex;
  width: 100%;
  gap: 49px;
}
.care-list-single {
  gap: 25px;
  display: flex;
  flex-direction: column;
  width: 100%;
}
.care-list-single > div {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.care-list-single img {
  height: 242px;
  width: 244.88px;
}
.waitlist-container {
  display: flex;
  flex-direction: column;
  gap: 34px;
  margin-top: 42px;
}
.waitlist-container div {
  display: flex;
  align-items: center;
  gap: 16px;
}
.wait-list-benefits {
  display: flex;
  width: 100%;
  background-color: var(--s-green-normal);
  box-sizing: border-box;
  padding: 75px 130px 0px 130px;
  margin-bottom: 82px;
  justify-content: center;
}
.waitlist-sub-text {
  font-family: Inter;
  font-size: 20px;
  font-weight: 400;
  line-height: 34px;
  letter-spacing: 0.5px;
  text-align: center;
}
.wait-list-main-image-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 61px;
}
.waitlist-first-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 24px;
  padding-top: 61px;
}
.waitlist-main-text {
  color: var(--s-green-normal-active);
  font-size: 54.1px;
  font-weight: 700;
  line-height: 70.32px;
  letter-spacing: 0.5634920597076416px;
  text-align: center;
}
.smaller-screen-image {
  max-width: 90%;
  height: auto;
  display: block;
  margin: 0 auto;
  margin-bottom: 20px;
  margin-top: 20px;
}

.main-one-small {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.main-one-container-small {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.main-three-cont {
  display: flex;
  gap: 24px;
  flex-direction: column;
}
.landing-container {
  background-color: #fffbfc;
}
.main {
  background-image: url("@/assets/Layer2.png");
  background-position: center;
  background-repeat: no-repeat;
  margin-bottom: 80px;
  background-color: #fffbfc;
}
.navbar {
  display: flex;
  padding: 32px 128px;
  justify-content: space-between;
}
header {
  box-shadow: 0px 4px 1px 0px #0000001a;
}
.main-logo {
  width: 168px;
  height: 59px;
}
.links {
  display: flex;
  gap: 30px;
}

.normal-links {
  color: var(--s-green-normal-active);
  text-decoration: none;
  font-size: var(--font-body-normal-regular-size);
  line-height: var(--font-body-normal-regular-line-height);
  display: flex;
  align-items: center;
  transition: background-color 0.3s ease;
}

.links router-link:hover {
  background-color: #007bff;
  color: #fff;
}

.links router-link-active {
  font-weight: bold;
}
.join-now {
  background-color: var(--s-pink-normal);
  width: 150px;
  border-radius: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--state-white);
  text-decoration: none;
  font-size: var(--font-body-normal-regular-size);
  line-height: var(--font-body-normal-regular-line-height);
}
.join-now:hover {
  background-color: var(--s-pink-normal-hover);
}
header {
  background-color: #fff8f9;
}
.main-one {
  padding: 0px 48px 0px 48px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.main-one-container {
  position: relative; /* Ensure the container is positioned relatively */
  margin-top: 24px;
  max-width: 1300px;
  width: 100%;
}

.main-one-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: contain;
  background-image: url("@/assets/MotherandChild.png"); /* Replace with the actual path to your image */
  background-position: calc(100% - 70px) 0; /* Adjust the horizontal position by subtracting pixels from 100% */
  background-repeat: no-repeat;
  z-index: 1000; /* Ensure the background image is on top */
  pointer-events: none; /* Allow clicks to pass through the pseudo-element */
}

.main-one-container > * {
  position: relative; /* Ensure the content is above the pseudo-element */
  z-index: 2; /* Ensure the content is above the pseudo-element */
}

.find-care-container {
  background-color: var(--state-white);
  display: flex;
  justify-content: center;
  margin-top: 31px;
  padding-bottom: 51px;
  border-radius: 12px;
  width: 100%;
}

.header-text {
  color: var(--s-green-darker);
  font-size: 54.1px;
  font-weight: 700;
  line-height: 70.32px;
  letter-spacing: 0.9634920597076416px;
}
.inner-white-container {
  background-color: var(--state-white);
  padding: 0px 0px 0px 115px;
  border-radius: 12px;
  display: flex;
  justify-content: space-between;
  max-width: 1322px;
  width: 100%;
}
.tabs {
  display: flex;
  justify-content: flex-start;
  margin-top: 42px;
}
.tabs p {
  color: var(--s-green-dark);
  font-size: var(--font-body-normal-regular-size);
  line-height: var(--font-body-normal-regular-line-height);
  font-weight: var(--font-weight-regular);
}
.active {
  border-bottom: 2px solid var(--s-pink-normal);
}
.active p {
  color: var(--s-pink-normal);
  font-weight: var(--font-weight-bold);
}
.single-tab {
  padding: 0px 20.5px 4px 20.5px;
  cursor: pointer;
}
.search-box {
  border: 1px solid #979797;
  border-radius: 12px;
  padding: 16px 24px 16px 2px;
  max-width: 340px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 8px;
  position: relative;
}
.search-box input {
  border: none;
  color: #979797;
  height: 28px;
  max-width: 123px;
  padding-left: 22px;
  box-sizing: border-box;

  outline: none;
}
.separator {
  margin-right: 8px;
  height: 28px;
  border-right: 1px solid #000000;
}
.search-box-button-div {
  display: flex;
  max-width: 460px;
}
.inner-white-container-first-div {
  padding-top: 57px;
}
.job-search {
  color: var(--s-green-darker);
  font-size: 16px;
  font-weight: 700;
  line-height: 27.2px;
  letter-spacing: 0.5px;
  text-align: left;
}
.job-search span {
  color: var(--s-pink-normal);
}
.job-search a {
  text-decoration: none;
  color: var(--s-pink-normal);
}
.start-care-div {
  padding: 80px 150px 0px 150px;
}
.type-of-care-div {
  display: flex;
  gap: 48px;
  justify-content: center;
  width: 100%;
  margin-bottom: 80px;
}
.type-of-care {
  display: flex;
  box-shadow: 0px 4px 4px 0px #0000001a;
  background-color: var(--s-pink-light-hover);
  border-radius: 12px;
  width: 244.4px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 26px 10px;
  cursor: pointer;
}
.type-of-care img {
  height: 30px;
  width: 30px;
}
.type-of-care-header {
  font-size: 17px;
  font-weight: var(--font-weight-bold);
  line-height: var(--font-body-normal-regular-line-height);
  letter-spacing: 0.5px;
}
.type-of-care-text {
  font-size: var(--font-label-regular-size);
  font-weight: 400;
  line-height: var(--font-body-small-regular-line-height);
  letter-spacing: 0.4px;
  text-align: center;
}
.access-network-div {
  display: flex;
  justify-content: center;
  margin-bottom: 32px;
  gap: 32.6px;
}
.access-network {
  font-size: 36px;
  font-weight: var(--font-weight-bold);
  line-height: 52px;
  letter-spacing: 0.5px;
  text-align: center;
}
.caregivers-div {
  display: flex;
  justify-content: center;
  gap: 32.6px;
}
.caregivers {
  display: flex;
  flex-direction: column;
}

.main-three {
  background-image: url("@/assets/rectanglebackground.png");
  background-size: cover; /* Ensure the image covers the entire background */
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Prevent the image from repeating */
  display: flex;
  justify-content: flex-end;
  padding-top: 49px;
  padding-bottom: 49px;
  padding-right: 91px;
}
.get-started-card {
  display: flex;
  flex-direction: column;
  background-color: var(--state-white);
  border-radius: 12px;
  padding: 16px 0px 16px 16px;
  gap: 8px;
}
.get-started-card-image-div {
  display: flex;
  justify-content: flex-start;
  gap: 16px;
  align-items: center;
  width: 546px;
}
.get-started-card-image-div p {
  font-size: 24px;
  font-weight: 700;
  line-height: 31.2px;
  letter-spacing: 0.5px;
  text-align: left;
}
.get-started-card-image-div img {
  height: 40px;
  width: 40px;
}
.get-started-card-extra-text {
  font-size: 16px;
  font-weight: 400;
  line-height: 27.2px;
  letter-spacing: 0.5px;
  text-align: left;
}
.button-get-started {
  background-color: var(--s-pink-normal);
  color: var(--state-white);
  width: 546px;
  padding: 16px 24px 16px 24px;
  gap: 10px;
  border-radius: 40px;

  height: 60px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
}
.button-get-started a {
  outline: none;
  color: var (--state-white);
  text-decoration: none;
}
.main-three-header p {
  font-size: 36px;
  font-weight: 700;
  line-height: 52px;
  letter-spacing: 0.5px;
  color: var(--s-green-darker);
}
.main-three-header {
  margin-top: 80px;
  display: flex;
  justify-content: center;
  margin-bottom: 32px;
}
.footer {
  padding: 52px 134px 59px 157px;
  background-color: var(--s-green-normal);
}
.footer-content-container {
  display: flex;
  justify-content: space-between;
}
.social-media-links {
  display: flex;
  justify-content: flex-start;
  gap: 20.58px;
}
.social-media-links a {
  outline: none;
}
.logoandsocialmedia {
  display: flex;
  flex-direction: column;
}
.footer-content-container > div {
  max-width: 220px;
}
.white-text {
  color: #ffffff;
  font-size: 15.22px;
  font-weight: 400;
  line-height: 25.87px;
  letter-spacing: 0.5434070825576782px;
  text-align: justified;
}
.footer-header-text {
  font-size: 20px;
  font-weight: 700;
  line-height: 34px;
  letter-spacing: 0.5px;
  color: var(--state-white);
}
.footer-section > div:first-child {
  margin-bottom: 16px;
}
.footer-section > div:not(:first-child) {
  margin-bottom: 8px;
}
.footer-section > div:not(:first-child) a {
  font-size: 16px;
  font-weight: 400;
  line-height: 27.2px;
  letter-spacing: 0.5px;
  color: var(--state-white);
  text-decoration: none;
}

.statedropdown {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  border: 1px solid #ddd;
  border-radius: 8px;
  margin-top: 5px;
  background-color: white;
  z-index: 10000;
  max-height: 200px;
  overflow-y: auto;
}
.statedropdown ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.statedropdown li {
  padding: 10px;
  cursor: pointer;
}
.statedropdown li:hover {
  background-color: #f0f0f0;
}
.citydropdown {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  border: 1px solid #ddd;
  border-radius: 8px;
  margin-top: 5px;
  background-color: white;
  z-index: 10000;
  max-height: 200px;
  overflow-y: auto;
}
.citydropdown ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.citydropdown li {
  padding: 10px;
  cursor: pointer;
}
.citydropdown li:hover {
  background-color: #f0f0f0;
}
.icon img {
  width: 16px;
  height: 24px;
}
.care-by-location {
  margin-top: 26px;
  margin-bottom: 10px;
  padding-left: 24px;
}
.find-job-care-provider p {
  font-family: Inter;
  font-size: 20px;
  font-weight: 400;
  line-height: 34px;
  letter-spacing: 0.5px;
  text-align: center;
}
.main-two {
  margin-bottom: 82px;
}


@media screen and (max-width: 1100px) {
.wait-list-sub {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 24px;
  flex-direction: column;
}
.care-list {
  display: flex;
  width: 100%;
  gap: 49px;
   align-items: center;
  
  flex-direction: column;
}
.care-list-single {
  gap: 25px;
  display: flex;
 align-items: center;
  flex-direction: column;
  width: 100%;
}
.wait-list-main-image-container img{
    width:300px;
    height: 100%;
    object-fit: contain;
}
.care-list-main-text {
  font-family: Inter;
  font-size: 15px;
  font-weight: 400;
  line-height: 34px;
  letter-spacing: 0.5px;
  text-align: left;
  color: var(--s-green-darker);
}
.care-list-sub-text {
  font-family: Inter;
  font-size: 13px;
  font-weight: 400;
  line-height: 27.2px;
  letter-spacing: 0.5px;
  text-align: left;
  color: var(--s-green-darker);
}
.waitlist-main-text{
    font-size: 48px;
}
.access-network{
    font-size: 28px;
}
.base-button{
    padding: 25px 15px;
}
.navbar{
    
    padding: 20px !important;
    box-sizing: border-box;
    flex-direction: row;
    align-items: center;

}

  
  .footer {
    padding: 16px;
    background-color: var(--s-green-normal);
    width: 100%;
  }
  .footer-content-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  .start-care-div {
    padding: 20px !important;
    width: 100%;
  }
  .type-of-care-div {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .caregivers-div {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .main-three-header,
  .access-network-div,
  .header-text {
    text-align: center;
  }
  .header-text {
    font-size: 32px;
    line-height: 40px;
  }
 
  .type-of-care {
    width: 100%;
  }
  .get-started-card-image-div {
    width: 100%;
  }
  .button-get-started {
    width: 100%;
  }
  .main {
    background-position: center;
    background-repeat: no-repeat;
    margin-bottom: 80px;
    background-color: #fffbfc;
    width: 100%;
  }

  .normal-links {
    font-size: 10px !important;
  }
 
 
  .header-text {
    font-size: 44px;
  }
  .find-care-container-div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .footer {
    padding: 40px !important;
  }
  .search-box {
    border: 1px solid #979797;
    border-radius: 12px;
    padding: 4px !important;
    max-width: 340px;
  }
  .search-box input {
    border: none;
    color: #979797;
    height: 28px;
    max-width: 123px;
    padding-left: 22px;
    box-sizing: border-box;

    outline: none;
  }
  .base-button {
    height: 50px;
  }
  .search-box {
    height: 50px;
    flex-direction: row;
    /* width: 50px; */
  }
  
  .main-three {
    justify-content: center !important;
    padding-right: 0px !important;
    padding-left: 0px !important;
  }
  .get-started-card {
    max-width: 350px;
    width: 100%;
  }
  .main-three-cont {
    align-items: center;
  }
  .button-get-started {
    width: 350px !important;
  }
  .join-now {
    width: 80px !important;
    font-size: 12px !important;
    height: 40px;
  }
  .links {
    align-items: center;
    gap: 15px !important;
  }
}
</style>


