<template>
  <LoginLayout>
    <div class="main-container">
      <h1>Terms of Use</h1>
      <div class="about-us-main">
        <div class="about-us-text">
          <p class="main-text">
            Last Updated: August 25, 2024 <br />
            These Terms of Use (the “Terms”) set forth the terms and conditions
            under which individuals residing in Nigeria may use the Succour Site
            and/or the Succour Services, including related memberships and
            content (described below). Please read these Terms before using the
            Site or the Services. By using the Site or the Services, you hereby
            represent, warrant, understand, agree to and accept these Terms in
            their entirety whether or not you register as a user of the Site or
            Services (“Registered Users”). These Terms include the Succour
            Privacy Policy and all other policies, rules and terms and
            conditions as set forth in Service materials from time to time,
            either in print or on our Site (collectively, the “Policies”), all
            of which are incorporated herein. If you object to anything in these
            Terms, the Privacy Policy or the other Policies, do not use the Site
            or the Services. These Terms, including the Privacy Policy and the
            other Policies, are subject to change by Succour at any time. We
            will notify you about significant changes in these Terms by sending
            a notice to the email address registered in your account, or by
            placing a prominent notice on our Site, so that you can choose
            whether to continue using our Services. Significant changes will go
            into effect no less than 30 days after we notify you. Non-material
            changes or clarifications will take effect immediately upon posting
            of the updated Terms on our Site. You should periodically check
            www.succour.care for updates. Your continued use of the Site or the
            Services after such effective date will constitute acceptance by you
            of such changes. For purposes of these Terms, the “Site” shall mean
            the www.succour.care web site and any affiliated websites, web
            pages, mobile applications and mobile websites operated by Succour
            Support Systems Limited, a private limited company with the The
            Registrar-General of Corporate Affairs Commission of Nigeria, with a
            registered address at 2b, Fatai Idowu Arobieke Street, Lekki,Lagos
            State, Nigeria (Company number: 7382957) (“Succour”). The “Services”
            shall mean any of the various services that Succour provides through
            the Site or any other channels, including over the telephone.
            <br />
            1. DESCRIPTION OF SERVICES; LIMITATIONS; USER RESPONSIBILITIES 1.1.
            About Our Services Succour’s mission is to provide an online venue
            for families and Caregivers to connect with each other, arrange
            care, and share advice. We work to fulfill this mission by making
            available online communication tools, information, and an extended
            network of care seekers and Care Givers that help our users find and
            coordinate quality care for children, older people, tutors and home.
            The Services we offer include, among others: We enable individuals
            seeking care services such as child care, care for older people,
            academic support care and housekeeping services (“Care Seekers”) to
            post jobs on the Site and individuals and entities who provide care
            services (“Care Givers”) to post profiles on the Site. We provide
            search tools to help Care Seekers find individual Care Givers that
            meet their specific needs and preferences and enable Care Seekers
            and individual Care Givers to communicate through a private
            messaging platform without sharing personal identification or
            contact information. We provide tools and information to help Care
            Seekers and Care Givers make informed decisions, such as (i) our
            safety centre, which includes a safety guide and safety resources.
            Our Services continue to grow and change. Please refer to our Site
            for further information about the Services we provide.  <br /> 1.2.
            Limitations of Our Services Succour does not introduce or supply
            Care Givers to Care Seekers. Instead, we offer a digital
            tool/platform that among other things matches Care Seekers to Care
            Givers, based on parameters provided by both parties , the platform
            also enables Care Seekers and Care Givers to interact regarding
            potential work opportunities. Through our Site, Care Seekers may
            post jobs describing the services for which they are seeking care,
            the terms of such services and related information, and Care Givers
            may post profiles describing the services they provide as well as
            their experience, training, qualifications and related information.
            Due to the difficulty of verifying information over the Internet, we
            do not and cannot reasonably verify the authenticity or completeness
            of job postings or whether Care Givers have the experience,
            training, qualifications and authorisations they post in their
            profiles. Our business model also makes it unreasonable for us to
            perform reference checks on all Care Givers as a general matter or
            confirm that Care Givers are suitable to work with the population
            they apply to work (e.g., children and older people). Any screening
            of a Care Seeker or Care Giver and his or her information by Succour
            through verification checks, as further described in section 4, or
            otherwise, is limited and should not be taken as complete, accurate,
            up-to-date or conclusive of the individual’s or entity’s suitability
            as an employer or Care Giver. Further, we do not have control over
            the quality, timing, or legality of the services actually delivered
            by Care Givers, nor of the integrity, responsibility or actions of
            Care Seekers or Care Givers. We do not make any representations
            about the suitability, reliability, timeliness, and accuracy of the
            services provided by Care Givers or the integrity, responsibility or
            actions of Care Seekers or Care Givers whether in public, private or
            offline interactions. Care Seeker and Care Giver content is
            primarily user generated, and Succour does not assume any
            responsibility for the accuracy or reliability of any information
            provided by Care Givers or Care Seekers on or off this Site. Succour
            is not responsible for the conduct, whether online or offline, of
            any user of the Site or the Services. Moreover, Succour does not
            assume and expressly disclaims any liability that may result from
            the use of information provided on our Site or any other venue. All
            users, including both Care Seekers and Care Givers, do hereby
            expressly agree not to hold Succour (or Succour’s officers,
            directors, investors, subsidiaries, agents, assignees,
            representatives, advertisers, marketing partners, licensors,
            independent contractors, recruiters, corporate partners, resellers,
            or employees, hereinafter “Affiliates”) liable for the actions or
            omissions of any Care Seeker or Care Giver or for any information,
            instruction, advice which originated or services that were arranged
            through the Site, and, to the maximum extent permissible under
            applicable law, each Care Seeker and Care Giver hereby irrevocably
            waives any right they may have to bring a claim against Succour and
            Succour and its Affiliates expressly disclaims any liability
            whatsoever for any damage, suits, claims, and/or controversies that
            have arisen or may arise, whether known or unknown therefrom.  <br /> 1.3.
            User Responsibilities As we verify the identity of or any
            information provided by Care Seekers or Care Givers (e.g., the
            authenticity or completeness of Care Seeker jobs or the
            qualifications, the experience or suitability of Care Givers, etc.)
            to the best of our abilities, registered users are also responsible
            for selecting an appropriate Care Seeker or Care Giver for
            themselves or their family. For Care Giver Seekers this should
            include: (i) interviewing Care Givers, (ii) verifying their identity
            via photographic ID, asking for proof of experience, training,
            qualifications, authorisations and suitability for the position they
            are applying and (iii) following up on references on the Care Givers
            they are interested in hiring. We recommend you ask Care Givers to
            bring to their interview photographic ID to verify their identity
            and original copies of documentation to verify their identity,
            experience, training and qualifications. Care Givers are also
            responsible for interviewing and verifying the identity of Care
            Seekers for whom they are considering working and the terms of
            engagement. Please consider requesting Care Seekers to provide
            documentation to verify their identity and character references from
            previous employees or professionals that know them such as a
            solicitor. Please also visit our Safety Centre for recommendations
            on how to make more informed hiring decisions. Any screening of a
            Care Seeker or Care Giver and his or her information by Succour
            through verification checks, as further described in section 4, or
            otherwise, is limited and should not be taken as complete, accurate,
            up-to-date or conclusive of the individual’s or entity’s suitability
            as an employer or care provider. Each Care Seeker is also
            responsible for complying with all applicable employment,
            anti-discrimination and other laws in connection with any
            relationship they establish with a Care Giver. Among other things,
            Care Seekers must verify that the Care Giver they select is legally
            entitled to live and work in Nigeria , and perform any other
            verification or other checks for the position they are hiring, such
            as identity, qualification, background and reference checks. In
            addition, Care Seekers must comply with all applicable
            anti-discrimination legislation. Accordingly, Care Seekers shall not
            post any job that directly or indirectly promotes age, sex, race,
            religion or belief, disability or other forms of prohibited.  <br /> 2.
            ELIGIBILITY TO USE THE SITE AND SERVICES; REPRESENTATIONS AND
            WARRANTIES To be eligible to use our Services, you must meet the
            following criteria: Our Services are available only to individuals
            who are eighteen (18) years of age or older and can form legally
            binding contracts under applicable law. If you are not eighteen (18)
            years of age or older do not register to use the Site or Services.
            If you registering to be a Care Seeker, you must be a citizen or
            legal resident of the Federal Republic of Nigeria. If you are
            registering to be a Care Giver, you must be permitted to legally
            work within the Federal Republic of Nigeria and you must have the
            experience, training, qualifications and authorisations required by
            law or otherwise for the positions for which they apply. In
            addition, if you are a Care Giver registered on the Site seeking to
            work as an au pair in a country outside of the Federal Republic of
            Nigeria with a family registered as a Care Seeker on a website
            operated by a Succour affiliate outside of the Federal Republic of
            Nigeria, you must also satisfy all legal requirements, including,
            without limitation, visa requirements, in order to work as an au
            pair in such other country. By requesting to use, registering to use
            and/or using the Site or the Services, you represent and warrant
            that you have the right, authority and capacity to enter into these
            Terms and you commit to abide by all of the terms and conditions
            hereof. In addition, you represent and warrant that you and each
            member of your household (i) have never been the subject of a
            complaint, restraining order or any other legal action involving,
            arrested for, charged with, or convicted of any criminal offense
            involving violence, abuse, neglect, theft or fraud, or any offense
            that involves endangering the safety of others, dishonesty,
            negligence or drugs, and (ii) are not nor have ever been on any
            Independent Safeguarding Authority barred list or the sex offenders
            register or other similar list.  <br /> 3. RULES FOR USER CONDUCT AND USE OF
            SERVICES 3.1. Registration, Posting, and Content Restrictions The
            following rules pertain to User Content, defined as any
            communications, images, sounds, videos, and all the material, data,
            and information that you upload or transmit through Succour’s
            Services, or that other users upload or transmit, including without
            limitation any content, messages, photos, audios, videos, reviews or
            profiles (collectively, “Content”) that you publish or display
            (hereinafter, “post”). By transmitting and submitting any User
            Content while using our Service, you agree, represent and warrant as
            follows: You are responsible for providing accurate, current and
            complete information in connection with your registration for use of
            the Site and the Services. You will register your account in your
            own legal name, even if you are seeking care for another individual
            or family member. You are solely responsible for any content,
            messages, photos, videos, reviews or profiles (collectively,
            “Content”) that you publish or display (hereinafter, “post”) on the
            Site, or transmit to other users of the Site. You will not post on
            the Site, or transmit to other users, any defamatory, inaccurate,
            abusive, obscene, profane, offensive, sexually oriented,
            threatening, harassing, defamatory, racially offensive, or illegal
            material, or any material that infringes or violates another party’s
            rights (including, but not limited to, intellectual property rights,
            and rights of privacy and publicity), or advocate, promote or assist
            any unlawful act such as (by way of example only) copyright
            infringement or computer misuse, or give the impression that any
            User Content emanates from Succour where this is not the case. You
            will not provide inaccurate, misleading, defamatory or false
            information to Succour or to any other user of the Site, and all
            opinions stated as part of User Content must be genuinely held.
            Without limiting the foregoing, you represent and warrant to us that
            you have the right and authority to post all information you post
            about yourself or others. You understand and agree that Succour may,
            in its sole discretion, review and delete any Content, in each case
            in whole or in part, that in the sole judgment of Succour violates
            this Agreement or which Succour determines in its sole discretion
            might be offensive, illegal, or that might violate the rights, harm,
            or threaten the safety of users of the Site. You have the right, and
            hereby grant to Succour, its Affiliates, licensees and successors,
            an irrevocable, perpetual, non-exclusive, fully paid, worldwide
            license to use, copy, perform, display, reproduce, adapt, modify and
            distribute such information and content and to prepare derivative
            works of, or incorporate into other works, such information and
            content, and to grant and authorize sublicenses of the foregoing.
            You further represent and warrant that public posting and use of
            your Content by Succour will not infringe or violate the rights of
            any third party. Your use of the Service, including but not limited
            to the Content you post on the Service, must be in accordance with
            any and all applicable laws and regulations. Succour is not
            responsible for any claims relating to any inaccurate, untimely or
            incomplete information provided by users of the Site. You agree that
            Succour has no obligation to remove any reviews or other information
            posted on the Site about you or any other person or entity. If you
            disagree with a review, you may post one rebuttal to the review,
            provided your rebuttal complies with these terms. You may not
            terminate your registration and re-register in order to prevent a
            review from being associated with your account. Opinions, advice,
            statements, offers, or other information or content made available
            on the Site or through the Service, but not directly by Succour, are
            those of their respective authors. Such authors are solely
            responsible for such content. Succour does not: (i) guarantee the
            accuracy, completeness, or usefulness of any information on the Site
            or available through the Service or any other venue, or (ii) adopt,
            endorse or accept responsibility for the accuracy or reliability of
            any opinion, advice, or statement made by any party that appears on
            the Site or through the Service or any other venue. Under no
            circumstances will Succour or its Affiliates be responsible for any
            loss or damage resulting from: a) your reliance on information or
            other content posted on the Site or transmitted to or by any user of
            the Site, Service, or any other venue; or b) reviews or comments
            made about you on the Site or other venue by other users. TO THE
            MAXIMUM EXTENT PERMITTED BY LAW, Succour DISCLAIMS ANY LIABILITY
            WHATSOEVER FOR ANY MISSTATEMENTS AND/OR MISREPRESENTATIONS MADE BY
            ANY USERS OF THE SITE OR ANY OTHER VENUE. USERS DO HEREBY REPRESENT,
            UNDERSTAND AND AGREE TO HOLD Succour HARMLESS FOR ANY MISSTATEMENTS
            AND/OR MISREPRESENTATIONS MADE BY OR ON BEHALF OF THEM ON THIS SITE
            OR IN ANY OTHER VENUE.  <br /> 3.2. Exclusive Use Your account is for your
            sole, personal use. You are responsible for all activity on and use
            of your account, and you may not assign or otherwise transfer your
            account to any other person or entity.  <br /> 3.3. Prohibited Uses By using
            the Site or Services of Succour, you agree that you will not under
            any circumstances: use the Site, Services, or other venue, or any
            information contained therein, in any way that is abusive,
            threatening, obscene, defamatory, libellous, or racially, sexually,
            religiously, or otherwise objectionable and offensive; use the
            Services for any unlawful purpose or for the promotion of illegal
            activities; attempt to, or harass, abuse or harm another person or
            group; use another user’s Succour account without permission;
            provide false or inaccurate information when registering an account
            on Succour, using our Services or communicating with other
            Registered Users; post any job where there is a risk to the health
            and safety of any Care Giver at the location where the work is to be
            performed; except where allowed by applicable law, post jobs which
            require the Care Giver to provide information relating to his/her:
            (i) racial or ethnic origin, (ii) political beliefs, (iii)
            philosophical or religious beliefs, (iv) membership of a trade
            union, (v) physical or mental health, (vi) sexual life, (vii) the
            commission of criminal offences or proceedings, or (vii) age;
            attempt to re-register with Succour if we have terminated your
            account for any or no reason; interfere or attempt to interfere with
            the proper functioning of Succour’s Services; make any automated use
            of the system, or take any action that we deem to impose or to
            potentially impose an unreasonable or disproportionately large load
            on our servers or network infrastructure; bypass any robot exclusion
            headers or other measures we take to restrict access to the Service
            or use any software, technology, or device to scrape, spider, or
            crawl the Service or harvest or manipulate data; use the
            communication systems provided by or contacts made on Succour for
            any commercial solicitation purposes; publish or link to malicious
            content intended to damage or disrupt another user’s browser or
            computer. In order to protect our users from prohibited activity, we
            reserve the right to take appropriate actions, including but not
            limited to restricting the number of emails which a user may send to
            other users in any 24-hour period to a number which we deem
            appropriate in our sole discretion. Should Succour find that you
            violated the terms of this Section or any terms stated herein,
            Succour reserves the right, at its sole discretion, to immediately
            terminate your use of the Site and Services. Further, in order to
            protect the integrity of the Site and the Services, Succour reserves
            the right at any time in its sole discretion to block users from
            certain IP addresses from accessing the Site.  <br /> 4. VERIFICATION CHECKS
              <br />4.1 Consent to run verification checks By using the Site or
            Services, you hereby authorize Succour, and acknowledge that Succour
            reserves the right, but not the obligation, to use a third party to
            use your personal information to scan on an ongoing basis a variety
            of sources, which may include, but are not limited to, sex offender
            registries, certain media streams, terrorist watch lists, criminal
            and fugitive watch lists, fraud watch lists, law enforcement
            reports, and other data to assist us in verifying the information
            you provide us and the representations and warranties you make in
            the Terms of Use and on the Site and in evaluating your suitability
            for Succour membership (“Verification Checks”). By using this Site
            or Services, you understand and agree that Succour may review the
            information provided by the third party verification service and
            that Succour retains the right to terminate your Succour
            registration based on the information it receives. You also hereby
            represent, understand and expressly agree that Succour does not have
            control over or assume any responsibility for the quality, accuracy,
            or reliability of the information included in a Verification Check.
            BY AGREEING TO THESE TERMS, YOU AGREE TO ALLOW Succour TO PERFORM
            THE VERIFICATION CHECKS DESCRIBED ABOVE. IF YOU DO NOT WANT THESE
            VERIFICATION CHECKS TO BE PERFORMED, YOU SHOULD NOT USE Succour. NO
            FURTHER CONSENT FROM YOU IS REQUIRED FOR THESE VERIFICATION CHECKS
            TO BE PERFORMED.  <br /> 4.2. Release of Liability for Results of
            Verification Checks Succour has no obligation to perform
            Verification Checks on any Registered Users. To the extent Succour
            performs such checks on certain Registered Users, the checks are
            limited and should not be taken as complete, accurate, up-to-date or
            conclusive of accuracy of any of the information those users have
            provided or of their eligibility to use the Services. The results of
            any Verification Checks on you may impact your ability to access and
            use Succour’s Services. You are not eligible to register with
            Succour if any of your Verification Check results indicate that you
            have falsified your legal name, address or date of birth, or
            indicate any background information that in our sole discretion
            disqualifies you from using our Site or Services. You understand
            that Succour may review the information provided by any third
            parties that provide Verification Checks, and that Succour retains
            the right in its sole discretion to terminate your Succour
            registration if any of the information it receives indicates you are
            not suitable for our services. You do hereby represent, understand
            and expressly agree that any Verification Checks are performed by
            separate third party verification services, and that Succour does
            not have control over or assume, and expressly disclaims, any
            responsibility for the quality, accuracy, or reliability of, and any
            liability that may result from the use of, or reliance on, the
            information such third party services provide.  <br /> 5. TERMINATION OF
            REGISTRATION Should Succour determine that you are not eligible to
            use the Services, have violated any terms stated herein or in any of
            the Policies, are not suitable for participation as a Registered
            User, or have mis-used or mis-appropriated Site content, including
            but not limited to, use on a “mirrored”, competitive, or third party
            site, Succour reserves the right, at its sole discretion, to
            immediately terminate your access to all or part of the Succour
            Site, to remove your profile and/or any content posted by or about
            you, from the Site, and/or to terminate your registration in
            Succour, with or without notice. In any event, Succour also reserves
            the right, in its sole discretion, to terminate your access to all
            or part of the Succour Site, to remove your profile and/or any
            content posted by or about you, from the Site, and/or to terminate
            your registration in Succour, for any reason or no reason, with or
            without notice. If we terminate your registration, we have no
            obligation to notify you of the reason, if any, for your
            termination. Following any termination of any individual’s use of
            the Site or the Services, Succour reserves the right to send a
            notice thereof to other Registered Users with whom we believe the
            individual has corresponded.  <br /> 6. PRIVACY Succour will only use the
            information you provide on the Site or via other channels in
            accordance with our Privacy Policy. For more information, see our
            full Privacy Policy, the terms of which are incorporated herein.  <br /> 7.
            LINKS TO EXTERNAL SITES Links from the Site to external sites
            (including external sites that are framed by Succour) or inclusion
            of advertisements do not constitute an endorsement by Succour of
            such sites or the content, products, advertising and other materials
            presented on such sites or of the products and services that are the
            subject of such advertisements, but are for users’ reference and
            convenience. Users access them at their own risk. It is the
            responsibility of the user to evaluate the content and usefulness of
            the information obtained from other sites. Succour does not control
            such sites, and is not responsible for their content. Just because
            Succour has hyperlinks to such sites does not mean that Succour
            endorses any of the material on such sites, or has any association
            with their operators. Users further acknowledge that use of any site
            controlled, owned or operated by third parties is governed by the
            terms and conditions of use for those sites, and not by Succour’s
            Terms of Use and Privacy Policy. Succour expressly disclaims any
            liability derived from the use and/or viewing of links that may
            appear on this Site. All users hereby agree to hold Succour harmless
            from any liability that may result from the use of links that may
            appear on the Site.   <br />8. PAYMENT In order to utilize some of Succour’s
            Services, Care Seekers must pay an Access Fee, a Screening Fee and
            leverage the designated account number assigned to Care Givers for
            subsequent salary payments/wages  <br /> 8.1 Billing and Payment for Care
            Seekers Care Seekers that pay the Access Fee (and the content
            available through that payment) agree to pay Succour all charges
            associated with the plan they paid for. This payment grants them
            access to 5 Care Givers to be matched to them for a period of 2
            weeks after which access to these Care Givers will be revoked. It is
            expected that within 2 weeks, Care Seekers would have discussed,
            negotiated and concluded on the preferred Care Giver to be hired.
            Care Seekers that pay the Screening Fee (and the content available
            through that payment) agree to pay Succour all charges associated
            with the plan they paid for. The payment covers the comprehensive
            health screening, security/background check, and guarantor
            verification  <br /> 8.3 Billing and Payment for Care Givers Certain
            Services and offerings made available to Care Givers may require the
            payment of a fee by such Care Givers as described hereon or
            otherwise on the Site. To the extent you elect to purchase other
            chargeable Services and offerings, you authorize Succour to charge
            your chosen payment provider for the Services you purchase. Succour
            may allow Care Givers to pay a fee to be featured more prominently
            on the Site. In addition, Succour may give Care Givers the ability
            to purchase credits that can be used by such Care Givers to exchange
            information with certain Care Seekers. To the extent applicable, the
            number of credits necessary to contact a Care Seeker and the
            expiration date of such credits is disclosed on the Site. Such
            credits do not have any monetary value and do not constitute a pre
            funding of stored value that can be used in the future.  <br /> 8.4 Refund
            Policy To the extent permitted by law, Succour does not as a general
            rule provide refunds for payments made. However, Succour may in its
            sole discretion issue refunds or credits when it believes they are
            warranted.  <br /> 8.5 Free Trial Offers Succour may offer a limited-time
            free trial to Care Seekers and Care Givers from time-to-time.
              <br /> RELEASE OF LIABILITY FOR USER CONDUCT AND DISPUTES  <br /> Succour does not
            introduce or supply Care Givers to Care Seekers. Instead, we offer a
            digital tool/platform that among other things matches Care Seekers
            to Care Givers, based on parameters provided by both parties , the
            platform also enables Care Seekers and Care Givers to interact
            regarding potential work opportunities. We also enable (i) Care
            Seekers to seek the services of a Care Giver through the use of the
            Site or Services, (ii) Care Givers to post profiles and submit
            proposals to Care Seekers regarding their services. Any agreement
            between a Care Seeker and a Care Giver regarding the provision of
            services is solely between the Care Seeker and the Care Giver;
            Succour is not a party to any such agreement. Any issues concerning
            the conduct of a Care Seeker or Care Giver including, without
            limitation, the services received by the Care Seeker or payment due
            to the Care Giver, must be resolved directly by the Care Seeker and
            the Care Giver. Succour will not be held responsible and expressly
            disclaims any liability whatsoever for any claims, demands or
            damages direct or indirect of every kind and nature, known and
            unknown, suspected and unsuspected, disclosed and undisclosed,
            arising out of or in any way connected with such issues. By using
            this Site or our Services, you do hereby represent, understand, and
            expressly agree to indemnify and hold Succour harmless for any claim
            or controversy that may arise from any disputes between you and any
            other user(s) of the Site. Succour will use its reasonable efforts
            to monitor Care Giver and Care Seeker profiles, comments, and
            general usage of the Site and suspend privileges to any Care Seeker
            or Care Giver not adhering to the Policies. You agree to take
            reasonable precautions in all interactions with other users of the
            Site or the Services, particularly if you decide to meet offline or
            in person. In addition, you agree to visit the Succour Safety Centre
            prior to using the Service. By using the Site or the Services, you
            do hereby agree to report any alleged improprieties of any users
            therein to Succour immediately by notifying Succour of the same via
            electronic correspondence.  <br /> 10. AGE RESTRICTIONS Succour is intended
            for people 18 or over. Care Seekers and Care Givers should monitor
            children’s use of the Internet and deny access to the Site to anyone
            under the age of 18. Succour will not knowingly collect any
            information from individuals under 18. You must identify your age
            during the registration process, and we do not assume any
            responsibility for any misrepresentations regarding your age when
            using this Site. Should we determine that you are under the age of
            18, your registration will be terminated immediately.  <br /> 11.
            DISCLAIMERS; LIMITATIONS; WAIVERS; INDEMNIFICATION 11.1. No Warranty
            The information and materials contained on the Site, including text,
            graphics, information, links or other items are provided “as is”,
            “as available”. Further, opinions, advice, statements, offers, or
            other information or content made available through the Services,
            but not directly by Succour, are those of their respective authors,
            and should not necessarily be relied upon. Such authors are solely
            responsible for such content. TO THE MAXIMUM EXTENT PERMITTED BY
            APPLICABLE LAW, Succour DOES NOT: (1) WARRANT THE ACCURACY, ADEQUACY
            OR COMPLETENESS OF THIS INFORMATION AND MATERIALS; (2) ADOPT,
            ENDORSE OR ACCEPT RESPONSIBILITY FOR THE ACCURACY OR RELIABILITY OF
            ANY OPINION, ADVICE, OR STATEMENT MADE BY ANY PARTY OTHER THAN
            Succour; (3) WARRANT THAT YOUR USE OF THE SERVICES WILL BE SECURE,
            FREE FROM COMPUTER VIRUSES, UNINTERRUPTED, ALWAYS AVAILABLE,
            ERROR-FREE OR WILL MEET YOUR REQUIREMENTS, OR THAT ANY DEFECTS IN
            THE SERVICES WILL BE CORRECTED; OR (4) GIVE ANY WARRANTIES OF
            FITNESS FOR A PARTICULAR PURPOSE OR NON-INFRINGEMENT OF THIRD PARTY
            RIGHTS. TO THE EXTENT PERMITTED BY APPLICABLE LAW, Succour EXPRESSLY
            excludes all conditions, warranties and other terms which might
            otherwise be implied by statute, common law or the law of equity and
            DISCLAIMS LIABILITY FOR ERRORS OR OMISSIONS IN THIS INFORMATION AND
            MATERIALS. IN ADDITION AND WITHOUT LIMITING THE FOREGOING, Succour
            MAKES NO REPRESENTATION OR WARRANTIES OF ANY KIND WHETHER EXPRESS OR
            IMPLIED REGARDING THE SUITABILITY OF ANY REGISTERED USER OF OUR SITE
            TO PROVIDE SERVICES AS A CAREGIVER OR TO EMPLOY THE SERVICES OF A
            CAREGIVER.  <br /> 11.2. Assumption of Risk You assume all risk when using
            the Site and the Services, including but not limited to all of the
            risks associated with any online or offline interactions with
            Registered Users of the Site or the Services. You agree to take all
            necessary precautions when interacting with other Site Visitors or
            Registered Users.  <br /> 11.3. Limitation of Liability Incidental Damages
            and Aggregate Liability. In no event will Succour be liable for any
            indirect or consequential damages, losses or expenses arising out of
            or relating to the use or inability to use the Site or Services,
            including without limitation damages related to any information
            received from the Site or Services, removal of content from the
            Site, including profile information, any email distributed to any
            user or any linked web site or use thereof or inability to use by
            any party, or in connection with any termination of your
            subscription or ability to access the Site or Services, failure of
            performance, error, omission, interruption, defect, delay in
            operation or transmission, computer virus or line or system failure,
            even if Succour, or representatives thereof, are advised of the
            possibility of such damages, losses or expenses. UNDER NO
            CIRCUMSTANCES WILL Succour’S AGGREGATE LIABILITY, IN ANY FORM OF
            ACTION WHATSOEVER IN CONNECTION WITH THIS AGREEMENT OR THE USE OF
            THE SERVICES OR THE SITE, EXCEED THE PRICE PAID BY YOU FOR YOUR
            ACCOUNT, OR, IF YOU HAVE NOT PAID Succour FOR THE USE OF ANY
            SERVICES, THE AMOUNT OF NGN10,000 OR ITS EQUIVALENT. No Liability
            for non-Succour Actions. TO THE MAXIMUM EXTENT PERMITTED BY
            APPLICABLE LAW, IN NO EVENT WILL Succour BE LIABLE FOR ANY DAMAGES
            WHATSOEVER, WHETHER DIRECT, INDIRECT AND/OR CONSEQUENTIAL, ARISING
            OUT OF OR RELATING TO THE CONDUCT OF YOU OR ANYONE ELSE IN
            CONNECTION WITH THE USE OF THE SITE OR THE SERVICES, INCLUDING
            WITHOUT LIMITATION, BODILY INJURY, EMOTIONAL DISTRESS, AND/OR ANY
            OTHER DAMAGES RESULTING FROM ANYONE’S RELIANCE ON INFORMATION OR
            OTHER CONTENT POSTED ON THE SITE, OR TRANSMITTED TO OR BY ANY USERS
            OR ANY OTHER INTERACTIONS WITH OTHER REGISTERED USERS OF THE SITE OR
            SERVICES, WHETHER ONLINE OR OFFLINE. THIS INCLUDES ANY CLAIMS,
            LOSSES OR DAMAGES ARISING FROM THE CONDUCT OF USERS WHO HAVE
            REGISTERED UNDER FALSE PRETENSES OR WHO ATTEMPT TO DEFRAUD OR HARM
            YOU. In addition to the preceding paragraphs of this section and
            other provisions of these Terms, any advice that may be posted on
            the Site is for informational purposes only and is not intended to
            replace or substitute for any professional financial, medical,
            legal, or other advice. Succour makes no representations or
            warranties and expressly disclaims any and all liability concerning
            any treatment, action by, or effect on any person following the
            information offered or provided within or through the Site. If you
            have specific concerns or a situation arises in which you require
            professional or medical advice, you should consult with an
            appropriately trained and qualified specialist.  <br /> 11.4.
            Indemnification By agreeing to the terms of this Terms of Use, users
            of the Site and Services agree to indemnify, defend and hold
            harmless Succour and its officers, subsidiaries, affiliates,
            successors, assigns, directors, officers, agents, service providers
            and employees from and against any and all claims, losses, expenses
            or demands of liability, including reasonable attorneys’ fees and
            costs incurred by Succour and its Affiliates in connection with any
            claim by a third party (including an intellectual property claim)
            arising out of (i) materials and content you submit, post or
            transmit through the Site, or (ii) use of the Site or Services by
            you in violation of these Terms of Use or in violation of any
            applicable law. Users further agree that they will cooperate as
            reasonably required in the defence of such claims. Succour and its
            Affiliates reserve the right, at their own expense, to assume the
            exclusive defence and control of any matter otherwise subject to
            indemnification by users, and users shall not, in any event, settle
            any claim or matter without the written consent of Succour. Users
            further agree to hold harmless Succour and its Affiliates from any
            claim arising from a third party’s use of information or materials
            of any kind that users post to the Site.  <br /> 12. JURISDICTION AND CHOICE
            OF LAW If there is any dispute arising out of the Site and/or the
            Services, by using the Site or the Services, you expressly agree
            that any such dispute shall be governed by the laws of England and
            Wales, without regard to its conflict of law provisions, and you
            expressly agree and consent to the exclusive jurisdiction and venue
            of the courts of England and Wales, for the resolution of any such
            dispute.  <br /> 13. MISCELLANEOUS Nothing in this Agreement shall be
            construed as making either party the partner, joint venturer, agent,
            legal representative, employer, contractor or employee of the other.
            Neither party shall have, or hold itself out to any third party as
            having any authority to make any statements, representations or
            commitments of any kind, or to take any action, that shall be
            binding on the other, except as provided for herein or authorized in
            writing by the party to be bound. The invalidity, illegality or
            unenforceability of any term or provision of these Terms shall in no
            way effect the validity, legality or enforceability of any other
            term or provision of these Terms. This Agreement will be binding on
            and will inure to the benefit of the legal representatives,
            successors and assigns of the parties hereto. Nothing express or
            implied in this Agreement is intended to confer, nor shall anything
            herein confer, upon any person other than the parties and the
            respective permitted successors or assigns of the parties, any
            rights, remedies, obligations or liabilities whatsoever. 14. CONTACT
            INFORMATION If you have any questions or need further information as
            to the Site or Services provided by Succour, or need to notify
            Succour as to any matters relating to the Site or Services please
            contact Succour at: Legal Department Succour Support Systems
            Limited, 10th Floor, Standard Chartered Building 142, Ahmadu Bello
            Way, Victoria Island, Lagos
          </p>
        </div>
      </div>
      <!-- <div class="faq-container">
        <div class="sidebar">
          <ul>
            <li
              v-for="category in categories"
              :key="category.id"
              :class="{ active: category.id === selectedCategoryId }"
              @click="selectCategory(category.id)"
            >
              {{ category.name }}
            </li>
          </ul>
        </div>
        <div class="faq-content"> -->
          <!-- <h2>{{ selectedCategory.name }}</h2> -->
          <!--   <div
            v-for="faq in selectedCategory.faqs"
            :key="faq.question"
            class="faq-item"
          >
            <h3>{{ faq.question }}</h3>
            <p>{{ faq.answer }}</p>
          </div>
        </div>
      </div> -->
    </div>
  </LoginLayout>
</template>

<script>
import { ref, computed } from "vue";
import LoginLayout from "@/layouts/LoginLayout.vue";

export default {
  name: "TermsOfUse",
  components: { LoginLayout },
  setup() {
    const categories = ref([
      {
        id: 1,
        name: "Introduction",
        faqs: [
          {
            question: "How do you make holy water?",
            answer:
              "You boil the hell out of it. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas cupiditate laboriosam fugiat..",
          },
          {
            question: "What's the best thing about Switzerland?",
            answer:
              "You boil the hell out of it. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas cupiditate laboriosam fugiat..",
          },
          {
            question: "What do you call someone with no body and no nose?",
            answer:
              "You boil the hell out of it. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas cupiditate laboriosam fugiat..",
          },
          {
            question: "How do you make holy water?",
            answer:
              "You boil the hell out of it. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas cupiditate laboriosam fugiat..",
          },
          {
            question: "What's the best thing about Switzerland?",
            answer:
              "You boil the hell out of it. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas cupiditate laboriosam fugiat..",
          },
          {
            question: "What do you call someone with no body and no nose?",
            answer:
              "You boil the hell out of it. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas cupiditate laboriosam fugiat..",
          },
        ],
      },
      {
        id: 2,
        name: "User Conduct",
        faqs: [
          {
            question: "How do you make holy water?",
            answer:
              "You boil the hell out of it. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas cupiditate laboriosam fugiat..",
          },
          {
            question: "What's the best thing about Switzerland?",
            answer:
              "You boil the hell out of it. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas cupiditate laboriosam fugiat..",
          },
          {
            question: "What do you call someone with no body and no nose?",
            answer:
              "You boil the hell out of it. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas cupiditate laboriosam fugiat..",
          },
          {
            question: "How do you make holy water?",
            answer:
              "You boil the hell out of it. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas cupiditate laboriosam fugiat..",
          },
          {
            question: "What's the best thing about Switzerland?",
            answer:
              "You boil the hell out of it. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas cupiditate laboriosam fugiat..",
          },
          {
            question: "What do you call someone with no body and no nose?",
            answer:
              "You boil the hell out of it. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas cupiditate laboriosam fugiat..",
          },
        ],
      },
      {
        id: 3,
        name: "Disclaimer of Warranties",
        faqs: [
          {
            question: "How do you make holy water?",
            answer:
              "You boil the hell out of it. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas cupiditate laboriosam fugiat..",
          },
          {
            question: "What's the best thing about Switzerland?",
            answer:
              "You boil the hell out of it. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas cupiditate laboriosam fugiat..",
          },
          {
            question: "What do you call someone with no body and no nose?",
            answer:
              "You boil the hell out of it. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas cupiditate laboriosam fugiat..",
          },
          {
            question: "How do you make holy water?",
            answer:
              "You boil the hell out of it. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas cupiditate laboriosam fugiat..",
          },
          {
            question: "What's the best thing about Switzerland?",
            answer:
              "You boil the hell out of it. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas cupiditate laboriosam fugiat..",
          },
          {
            question: "What do you call someone with no body and no nose?",
            answer:
              "You boil the hell out of it. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas cupiditate laboriosam fugiat..",
          },
        ],
      },
      {
        id: 4,
        name: "Indemnification",
        faqs: [
          {
            question: "How do you make holy water?",
            answer:
              "You boil the hell out of it. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas cupiditate laboriosam fugiat..",
          },
          {
            question: "What's the best thing about Switzerland?",
            answer:
              "You boil the hell out of it. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas cupiditate laboriosam fugiat..",
          },
          {
            question: "What do you call someone with no body and no nose?",
            answer:
              "You boil the hell out of it. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas cupiditate laboriosam fugiat..",
          },
          {
            question: "How do you make holy water?",
            answer:
              "You boil the hell out of it. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas cupiditate laboriosam fugiat..",
          },
          {
            question: "What's the best thing about Switzerland?",
            answer:
              "You boil the hell out of it. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas cupiditate laboriosam fugiat..",
          },
          {
            question: "What do you call someone with no body and no nose?",
            answer:
              "You boil the hell out of it. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas cupiditate laboriosam fugiat..",
          },
        ],
      },
      {
        id: 5,
        name: "Governing Law and Jurisdiction",
        faqs: [
          {
            question: "How do you make holy water?",
            answer:
              "You boil the hell out of it. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas cupiditate laboriosam fugiat..",
          },
          {
            question: "What's the best thing about Switzerland?",
            answer:
              "You boil the hell out of it. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas cupiditate laboriosam fugiat..",
          },
          {
            question: "What do you call someone with no body and no nose?",
            answer:
              "You boil the hell out of it. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas cupiditate laboriosam fugiat..",
          },
          {
            question: "How do you make holy water?",
            answer:
              "You boil the hell out of it. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas cupiditate laboriosam fugiat..",
          },
          {
            question: "What's the best thing about Switzerland?",
            answer:
              "You boil the hell out of it. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas cupiditate laboriosam fugiat..",
          },
          {
            question: "What do you call someone with no body and no nose?",
            answer:
              "You boil the hell out of it. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas cupiditate laboriosam fugiat..",
          },
        ],
      },
      {
        id: 6,
        name: "Contact Information",
        faqs: [
          {
            question: "How do you make holy water?",
            answer:
              "You boil the hell out of it. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas cupiditate laboriosam fugiat..",
          },
          {
            question: "What's the best thing about Switzerland?",
            answer:
              "You boil the hell out of it. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas cupiditate laboriosam fugiat..",
          },
          {
            question: "What do you call someone with no body and no nose?",
            answer:
              "You boil the hell out of it. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas cupiditate laboriosam fugiat..",
          },
          {
            question: "How do you make holy water?",
            answer:
              "You boil the hell out of it. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas cupiditate laboriosam fugiat..",
          },
          {
            question: "What's the best thing about Switzerland?",
            answer:
              "You boil the hell out of it. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas cupiditate laboriosam fugiat..",
          },
          {
            question: "What do you call someone with no body and no nose?",
            answer:
              "You boil the hell out of it. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas cupiditate laboriosam fugiat..",
          },
        ],
      },
    ]);

    const selectedCategoryId = ref(categories.value[0].id);

    const selectedCategory = computed(() => {
      return categories.value.find(
        (category) => category.id === selectedCategoryId.value
      );
    });

    const selectCategory = (id) => {
      selectedCategoryId.value = id;
    };

    return {
      categories,
      selectedCategoryId,
      selectedCategory,
      selectCategory,
    };
  },
};
</script>

<style scoped>
.main-container {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 74px;
  flex-direction: column;
  align-items: center;
  gap: 80px;
}
h1 {
  text-align: left;

  font-size: 44px;
  font-weight: 700;
  line-height: 57.2px;
  letter-spacing: 0.5px;
  text-align: left;
  color: var(--s-green-normal);
  width: 1000px;
}
h3 {
  font-size: 16px;
  font-weight: 700;
  line-height: 27.2px;
  letter-spacing: 0.5px;
  text-align: justified;
}
p {
  font-size: 14px;
  font-weight: 400;
  line-height: 27.2px;
  letter-spacing: 0.5px;
  text-align: justified;
}
.faq-container {
  display: flex;
  width: 1000px;
  gap: 72px;
  margin-bottom: 218px;
}

.sidebar {
  width: 100%;
  max-width: 300px;
  background-color: #fef5f7;
  max-height: 550px;
}

.sidebar ul {
  list-style-type: none;
  padding: 0;
}

.sidebar li {
  cursor: pointer;
  padding: 24px 10px;
  border-bottom: 1px solid #ccc;

  font-size: 20px;
  font-weight: 400;
  line-height: 34px;
  letter-spacing: 0.5px;
  text-align: left;
}

.sidebar li.active {
  color: black;
  font-size: 20px;
  font-weight: 700;
  line-height: 34px;
  letter-spacing: 0.5px;
  text-align: left;
}

.faq-content {
  width: 100%;
}

.faq-item {
  margin-bottom: 20px;
}

.faq-item h3 {
  margin: 10px 0;
}

.faq-item p {
  margin: 0;
}
.main-text {
  font-size: 20px;
  font-weight: 400;
  line-height: 34px;
  letter-spacing: 0.5px;
  text-align: left;
}
.about-us-main {
  display: flex;
  margin-top: 4px;
  max-width: 1061px;
  width: 100%;
  gap: 60px;
  margin-bottom: 132px;
}
.about-us-text {
  padding: 0px 0px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}
@media screen and (max-width: 800px) {
  .faq-container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .faq-content {
    width: 100%;
    max-width: 320px;
  }
  h1 {
    text-align: center;

    font-size: 20px;
    font-weight: 600;
    line-height: 14.2px;
    letter-spacing: 0.2px;

    color: var(--s-green-normal);
    width: 350px;
  }
}
</style>
