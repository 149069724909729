<template>
  <LoginLayout>
    <div class="login-form">
      <LoadingSpinner :isLoading="isLoading" />
      <div v-if="!isLoading">
        <div v-if="emailVerified === 'success'">
          <div class="notification">
            <span><img src="@/assets/success.svg" /></span> Your email has been
            verified successfully
          </div>
          <div style="margin-top: 10px; width: 100%; display: flex; justify-content: center">Redirecting to login ...</div>
        </div>
        <div v-else-if="emailVerified === 'already_verified'" class="fail-notification">
          <!-- <span><img src="@/assets/error.svg" /></span>  -->
          Email already verified
        </div>
          <div v-else class="fail-notification">
          <!-- <span><img src="@/assets/error.svg" /></span>  -->
          Email verification failed
        </div>
      </div>
    </div>
  </LoginLayout>
</template>

<script>
import { useRouter } from "vue-router";
import LoginLayout from "@/layouts/LoginLayout.vue";
import { onMounted, ref } from "vue";
import Request from "@/utils/apiclient.js";
import { useRoute } from "vue-router";
import LoadingSpinner from "@/components/LoadingSpinner.vue";

export default {
  name: "VerifyEmail",
  components: {
    LoginLayout,
    LoadingSpinner,
  },
  setup() {
    const router = useRouter();
    const isLoading = ref(true);
    const route = useRoute();
    const emailVerified = ref("");

    const token = route.query.token;

    if (!token) {
      console.error("No token provided in the URL");
      isLoading.value = false;
      return;
    }

    const request = new Request(`/user/verify_email/?token=${token}`);

    onMounted(() => {
      request.get((res, error) => {
        isLoading.value = false;
        if (error) {
           
          console.error("Error:", error);
           console.log("error here", error)
          if (error.message == "Email already verified.") {
            emailVerified.value = "already_verified";
          } else {
            emailVerified.value = "failed";
          }
        } else {
          console.log("Email verification response:", res);
          emailVerified.value = "success";
          setTimeout(() => {
            router.push("/login");
          }, 3000);
        }
      });
    });

    return {
      emailVerified,
      isLoading,
    };
  },
};
</script>

<style scoped>
.login-container {
  max-width: 400px;
  margin: auto;
}

h2 {
  margin-bottom: 20px;
  margin-top: 32px;
  font-size: 24px;
  font-weight: 700;
  line-height: 29.05px;
  letter-spacing: 0.5px;
  text-align: center;
}

.input-group {
  margin-bottom: 32px;
  text-align: left;
}

.input-group label {
  display: block;
  margin-bottom: 5px;
  color: #979797;
  font-size: 12px;
  font-weight: 400;
  line-height: 20.4px;
  letter-spacing: 0.5px;
  text-align: left;
}

.input-group input {
  width: 100%;
  padding: 16px 10px;
  border: 1px solid #979797;
  border-radius: 12px;
  background-color: transparent;
  box-sizing: border-box;
}
.input-group input::placeholder {
  font-size: 16px;
  font-weight: 400;
  line-height: 27.2px;
  letter-spacing: 0.5px;
  text-align: left;
  color: #979797;
}

.login-button {
  width: 100%;
  background-color: var(--s-pink-normal);
  border: none;
  border-radius: 40px;
  color: white;
  font-size: 16px;
  cursor: pointer;
  margin-bottom: 10px;
  padding: 16px 24px 16px 24px;
}
.login-button:disabled {
  width: 100%;
  background-color: var(--s-pink-normal);
  border: none;
  border-radius: 40px;
  color: white;
  font-size: 16px;
  cursor: pointer;
  margin-bottom: 10px;
  padding: 16px 24px 16px 24px;
  cursor: not-allowed;
  opacity: 0.6;
}
.login-button:hover {
  background-color: var(--s-pink-normal-hover);
}

.forgot-password {
  display: block;
  margin-bottom: 34px;
  color: var(--s-green-normal);
  text-decoration: none;
  text-align: center;

  font-size: 14px;
  font-weight: 400;
  line-height: 23.8px;
  letter-spacing: 0.5px;
  text-align: center;
}

.forgot-password:hover {
  color: var(--s-green-normal-hover);
}

.google-login,
.apple-login {
  width: 100%;
  padding: 10px 67px;
  border-radius: 12px;
  border: 1px solid var(--s-green-normal);
  color: #000;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  gap: 10px;
  font-size: 20px;
  font-weight: var(--font-weight-regular);
  line-height: 34px;
  letter-spacing: 0.5px;
  text-align: left;
  background-color: transparent;
}

.google-login img,
.apple-login img {
  width: 40.67px;
  height: 40.67px;
}

.separator {
  display: flex;
  align-items: center;
  text-align: center;
  color: #000;

  font-size: 20px;
  font-weight: 400;
  line-height: 34px;
  letter-spacing: 0.5px;
  text-align: left;
  margin-bottom: 16px;
}
.separator::before,
.separator::after {
  content: "";
  flex: 1;
  border-bottom: 1px solid #979797;
}
.separator:not(:empty)::before {
  margin-right: 8px;
}
.separator:not(:empty)::after {
  margin-left: 8px;
}
.join-now:hover {
  background-color: var(--s-pink-normal-hover);
}
.login-container p {
  margin-bottom: 32px;
  color: var(--s-green-darker);
  font-size: 14px;
  font-weight: 400;
  line-height: 23.8px;
  letter-spacing: 0.5px;
  text-align: center;
}

.notification {
  background-color: #e6f7e6;
  color: #2c662d;
  padding: 10px;
  border-radius: 5px;
  text-align: center;
  margin-bottom: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  margin-top: 40px;
}
.fail-notification {
  background-color: var(--state-error);
  color: white;
  padding: 10px;
  border-radius: 5px;
  text-align: center;
  margin-bottom: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  margin-top: 40px;
}
.login-form {
  padding-bottom: 500px;
 
}
@media screen and (max-width: 800px) {
  .login-form {
  padding-bottom: 500px;
  max-width: 400px;
}
}
</style>
