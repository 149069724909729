export const authHeader = () => {
    const item = localStorage.getItem("_user");
    const headers = {
      "Content-Type": "application/json",
    };
    if (item) {
      console.log("item here", item);
      const bearer = item; // Use the JWT directly
      console.log("bearer here", bearer);
      headers.Authorization = `Bearer ${bearer}`;
    }
    return headers;
  };
  
  export const authHeader2 = () => {
    const item = localStorage.getItem("_user");
    const headers = {
      "Content-Type": "multipart/form-data",
    };
    if (item) {
      const bearer = item; // Use the JWT directly
      headers.Authorization = `Bearer ${bearer}`;
    }
    return headers;
  };
  
  export const uploadAuthHeader = () => {
    const item = localStorage.getItem("_user");
    const headers = {};
    if (item) {
      const bearer = item; // Use the JWT directly
      headers.Authorization = `Bearer ${bearer}`;
    }
    return headers;
  };
  
  export const getQueryParams = (queryObj) => {
    if (!queryObj) return "";
    let q = "?";
    for (let k in queryObj) {
      q += k + "=" + encodeURIComponent(queryObj[k]) + "&";
    }
    return q.replace(/&$/, '');
  };
  