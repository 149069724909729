// utils/errorHandler.js

export const formatErrorMessages = (error) => {
    let errorMessages = [];
  
    if (typeof error === 'object' && error !== null) {
      for (const key in error) {
        if (Object.hasOwn(error, key) && Array.isArray(error[key])) {
          error[key].forEach(errorMessage => {
            errorMessages.push(`${errorMessage}`);
          });
        }
      }
    } else if (error.message) {
      errorMessages.push(error.message);
    } else {
      errorMessages.push("An unknown error occurred");
    }
  
    return errorMessages;
  };
  