<template>
  <div>
    <h1>Almost done, let’s verify your Identity</h1>

    <div class="form-container">
      <div>
        <SelectDropdown
          label="Select document type"
          id="document_type"
          :options="documentOptions"
          width="100%"
          height="60px"
          @update:selectedOption="handleDocumentSelection"
        />
      </div>
      <div style="margin-top: 24px">
        <CustomInput
          label="Document Number"
          v-model="documentNumber"
          placeholder="Document Number"
          type="text"
          focusColor="#17865F"
          defaultColor="#979797"
        />
      </div>
      <div>
        <BaseButton
          label="Next"
          :disabled="!formValid"
          backgroundColor="var(--s-pink-normal)"
          textColor="var(--state-white)"
          disabledBackgroundColor="#ECECEC"
          disabledColor="#ECECEC"
          disabledTextColor="#979797"
          height="60px"
          width="100%"
          @click="handleClick"
        />
      </div>
    </div>
  </div>
</template>
<script>
import SelectDropdown from "@/components/SelectDropdown.vue";
import BaseButton from "@/components/BaseButton.vue";
import CustomInput from "@/components/CustomInput.vue";
import { useRouter } from "vue-router";
import { ref, computed,inject, watch } from "vue";
export default {
  name: "CareSeekerCreateAccountStepTwo",
  components: { CustomInput, SelectDropdown, BaseButton },
  setup() {
  const formData = inject("onboardingCareSeekerformData");
    const updateFormData = inject("onboardingUpdateCareSeekerformData");
    const localData = ref({
      ...formData.value.stepTwoData,
    });

        const router = useRouter();
    const documentOptions = ref([
      {
        value: "NIN",
        text: "NIN",
      },
      {
        value: "International Passport",
        text: "International Passport",
      },
    ]);
    const document = ref("");
    const documentNumber = ref("");
    const handleDocumentSelection = (value) => {
      document.value = value;
    };
   const handleClick = () => {
      updateFormData("stepTwoData", localData.value);

      console.log("formdata here", formData);
       router.push("/careseeker-onboarding/create-account/step-3");
    }
    const formValid = computed(() => {
      return document.value && documentNumber.value;
    });
     watch(
      [document, documentNumber],
      ([newdocument, newdocomentNumber]) => {
        localData.value.document_type = newdocument;
        localData.value.document_number = newdocomentNumber;
     
      }
    );
    return {
      document,
      documentOptions,
      handleDocumentSelection,
      formValid,
      documentNumber,
      handleClick
    };
  },
};
</script>
<style scoped>
.form-container {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  padding: 20px;
}
.inputs-div {
  display: flex;
  gap: 16px;
}
.inputs-div .input-wrapper {
  width: 100%;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}
input[type="date"]::placeholder {
  /* Standard */
  color: #979797;
  font-family: "Inter", sans-serif; /* Ensure the font is applied to the placeholder */
}

input[type="date"]::-webkit-input-placeholder {
  /* WebKit browsers */
  color: #979797;
  font-family: "Inter", sans-serif; /* Ensure the font is applied to the placeholder */
}

input[type="date"]:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #979797;
  font-family: "Inter", sans-serif; /* Ensure the font is applied to the placeholder */
}

input[type="date"]::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #979797;
  font-family: "Inter", sans-serif; /* Ensure the font is applied to the placeholder */
}

input[type="date"]:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  color: #979797;
  font-family: "Inter", sans-serif; /* Ensure the font is applied to the placeholder */
}
.form-group {
  margin-bottom: 24px;
}
.form-group input {
  background-color: transparent;
  width: 100%;
  padding: 10px;
  font-size: 16px;
  transition: border-color 0.3s;
  padding: 16px 24px 16px 24px;
  border-radius: 12px;
  border: 1px solid #979797;
  box-sizing: border-box;
  font-family: var(--font-family);
}
.form-group input::placeholder {
  color: #979797;
}

.form-group.has-value label.active,
.form-group.has-value input:focus {
  color: var(--s-green-normal);
}

.form-group.has-value input {
  border-color: var(--s-green-normal);
}

.input-container {
  position: relative;
}

.input-container .icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

.terms {
  color: #000000;
  text-align: center;
  margin-top: 48px;
  font-size: 14px;
  font-weight: 400;
  line-height: 23.8px;
  letter-spacing: 0.5px;

  text-align: left;
  margin-bottom: 20px;
}

.terms a {
  color: #0063f7;
  text-decoration: none;

  font-size: 14px;
  font-weight: 400;
  line-height: 23.8px;
  letter-spacing: 0.5px;
}
.dob-class {
  color: #979797;
  font-size: 12px;
  font-weight: 400;
  line-height: 20.4px;
  letter-spacing: 0.5px;
  text-align: left;
}
h1 {
  color: #0a3828;
  font-size: 24px;
  font-weight: 700;
  line-height: 31.2px;
  letter-spacing: 0.5px;
  text-align: center;
  margin-top: 60px;
}
</style>