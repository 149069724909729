<template>
  <div
    class="message-item"
    :class="{ selected: isSelected }"
    @click="selectMessage"
  >
    <Avatar :src="avatarSrc" :initials="initials" />
    <div class="message-content">
      <div class="message-header">
        <span class="name">{{ name }}</span>
        <span class="date">{{ date }}</span>
      </div>
      <div class="message-body">{{ message }}</div>
    </div>
  </div>
</template>

<script>
import Avatar from '@/components/AvatarComponent.vue';

export default {
  components: {
    Avatar,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    date: {
      type: String,
      required: true,
    },
    message: {
      type: String,
      required: true,
    },
    avatarSrc: {
      type: String,
      default: '',
    },
    initials: {
      type: String,
      default: 'AV',
    },
    isSelected: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    selectMessage() {
      this.$emit('select', this.name);
    },
  },
};
</script>

<style scoped>
.message-item {
  display: flex;
  margin-bottom: 10px;
  padding: 10px;
  box-sizing: border-box;
  background-color:transparent;
  gap: 8px;
  border-radius: 12px;
}

.selected {
  background-color: #f0f0f0;
}

.message-content {
  background-color: transparent;
  border-radius: 5px;
  box-sizing: border-box;
  width: 100%;
}

.message-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;
}

.name {
  font-size: 20px;
  font-weight: 700;
  line-height: 34px;
  letter-spacing: 0.5px;
  text-align: left;
}

.date {
  color: #000000;
  font-size: 16px;
  font-weight: 400;
  line-height: 27.2px;
  letter-spacing: 0.5px;
  text-align: center;
}

.message-body {
  color: #555;
  font-size: 14px;
  font-weight: 400;
  line-height: 23.8px;
  letter-spacing: 0.5px;
}
</style>
