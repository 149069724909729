<template>
  <div class="login-layout-container">
    <div class="main">
      <div class="navbar">
        <img
          @click="redirectToHome"
          class="main-logo"
          src="@/assets/full_logo.svg"
          alt="Logo"
        />
        <div class="create-account">
          <p>{{ currentSection.message }}</p>
          <p>Step {{ currentStep }} of {{ currentSection.steps }}</p>
        </div>
      </div>
      <div class="progress-bar">
        <div class="progress" :style="{ width: progressWidth + '%' }"></div>
      </div>
      <router-view></router-view>
       <div class="create-account-small">
          <p>{{ currentSection.message }}</p>
          <p>Step {{ currentStep }} of {{ currentSection.steps }}</p>
        </div>
    </div>
  </div>
</template>

<script>
import { ref, provide, computed, watch } from 'vue';
import { useRoute } from 'vue-router';

export default {

  setup() {
    // Reactive object to hold form data for various steps
    const formData = ref({
      stepOneData: { type_of_care: "" },
      stepTwoData: { state: "", local_government: "", work_preference: "" },
      stepThreeData: { type_of_care: [] },
      stepFourData: { monthly_rate: "" },
      stepFiveData: { days: [], time: "" },
      stepSixData: {  bio: "", care_sub_category: [] },
      stepSevenData: { institution: "", qualification: "", work_preference: "", language: [], certificate: null }
    });

    // Function to update form data for a specific step
    const updateFormData = (step, data) => {
      formData.value[step] = data;
    };

    // Provide formData and updateFormData to child components
    provide('formData', formData);
    provide('updateFormData', updateFormData);

    // Access the current route
    const route = useRoute();

    // Define sections with their respective steps and messages
    const sections = [
      { section: "Caregiver", steps: 3, message: "Create your account" },
      { section: "BuildProfile", steps: 4, message: "Build your Profile" },
      { section: "Verification", steps: 2, message: "Complete your verification" }
    ];

    // Compute the current section based on the route path
    const currentSection = computed(() => {
      if (route.path.includes('create-account')) return sections[0];
      if (route.path.includes('build-profile')) return sections[1];
      if (route.path.includes('complete-verification')) return sections[2];
      return { steps: 0, message: "" };
    });

    // Compute the current step based on the route path
    const currentStep = computed(() => {
      const pathParts = route.path.split('/');
      const stepPart = pathParts[pathParts.length - 1];
      if (stepPart.includes('step-')) {
        return parseInt(stepPart.split('-')[1]);
      }
      return 1;
    });

    // Compute the progress width as a percentage of the total steps
    const progressWidth = computed(() => {
      return (currentStep.value / currentSection.value.steps) * 100;
    });

    // Watch for changes in the route path to ensure reactivity
    watch(
      () => route.path,
      () => {
        // This will force the computed properties to re-evaluate
        console.log("Route path changed:", route.path);
      }
    );

    return {
      progressWidth,
      currentSection,
      currentStep
    };
  }
};
</script>

<style scoped>
.create-account-small{
  display: none;
}
.onboarding-container {
  padding-top: 56px;
}
.care-card {
  display: flex;
  gap: 40px;
  justify-content: center;
}
.care-card-single {
  padding: 24px;
  gap: 10px;
  border-radius: 12px;
  border: 1px solid var(--s-green-normal);

  width: 360px;
}
.login-layout-container {
  background-color: #fffbfc;
  min-height: 100vh;
}
.main {
  background-color: #fff8f9;
  min-height: 100vh;
  width: 100%;
  padding-bottom: 200px;
}
.navbar {
  display: flex;
  padding: 32px 128px;
  justify-content: space-between;
}
header {
  box-shadow: 0px 4px 1px 0px #0000001a;
}
.main-logo {
  width: 168px;
  height: 59px;
  cursor: pointer;
}
.onboarding-header-text {
  font-size: 24px;
  font-weight: 700;
  line-height: 31.2px;
  letter-spacing: 0.5px;
  text-align: center;
  margin-bottom: 60px;
}
.stepper {
  width: 100%;
  margin: 0 auto;
  padding: 20px;
}
.progress-bar {
  width: 100%;
  background-color: #979797;
  height: 5px;
}
.progress {
  background-color: #17865f;
  height: 5px;
  transition: width 0.3s;
}
.create-account {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.create-account p {
  color: #116547;
}
.create-account p:first-child {
  font-weight: 700;
  line-height: 27.2px;
  letter-spacing: 0.5px;
  text-align: left;
  margin-right: 8px;
}
@media screen and (max-width: 800px) {
  .create-account{
    display: none;
  }
  .navbar{
    padding: 20px;
    justify-content: space-between;
  }
  /* .main-logo{
    width: 100px;
    height: 70px
  } */
  .main-logo {
  width: 140px;
  height: 50px;
  cursor: pointer;
}
.create-account-small {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  flex-direction: column;
  margin-top: 40px
}
.create-account-small p {
  color: #979797;
  font-family: Inter;
font-size: 12px;
font-weight: 400;
line-height: 27.2px;
letter-spacing: 0.5px;
text-align: left;

}

}
</style>
