<template>
  <div class="custom-select-container" :style="containerStyles">
    <label :for="id" :class="['custom-select-label', { 'custom-select-label-selected': selectedValue }]">{{ label }}</label>
    <div class="custom-select" @click="toggleDropdown">
      <div class="custom-select-trigger" :style="buttonStyles">
        <span>{{ selectedText }}</span>
        <div>
          <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 1.70492L10.59 0.294922L6 4.87492L1.41 0.294921L-6.16331e-08 1.70492L6 7.70492L12 1.70492Z" :fill="svgFillColor" />
          </svg>
        </div>
      </div>
      <div v-if="isDropdownOpen" class="custom-options" @click.stop>
        <div
          v-for="option in options"
          :key="option.value"
          :class="['custom-option', { selected: option.value === selectedValue }]"
          @click="selectOption(option)"
        >
          {{ option.text }}
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, defineProps, toRefs, computed, defineEmits, watch } from 'vue';

const props = defineProps({
  label: {
    type: String,
    default: 'Select'
  },
  id: {
    type: String,
    required: true
  },
  options: {
    type: Array,
    required: true
  },
  width: {
    type: String,
    default: ''
  },
  height: {
    type: String,
    default: ''
  },
  selectedOption: {
    type: String,
    default: ''
  },
  disabled: {
    type: Boolean,
    default: false
  }
});

const { label, id, options, selectedOption, disabled } = toRefs(props);
const selectedValue = ref(selectedOption.value);
const isDropdownOpen = ref(false);
const emit = defineEmits(['update:selectedOption']);

const toggleDropdown = () => {
  if (!disabled.value) {
    isDropdownOpen.value = !isDropdownOpen.value;
  }
};

const selectOption = (option) => {
  selectedValue.value = option.value;
  isDropdownOpen.value = false;
  emit('update:selectedOption', option.value); // Emit event to parent component
};

const selectedText = computed(() => {
  const selected = options.value.find(option => option.value === selectedValue.value);
  return selected ? selected.text : 'Choose option';
});

const buttonStyles = computed(() => ({
  borderColor: selectedValue.value ? '#17865F' : '#979797',
  color: selectedValue.value ? '#000000' : '#979797',
  width: props.width,
  height: props.height ? props.height : ""
}));

const containerStyles = computed(() => ({
  width: props.width,
  height: props.height ? props.height : ""
}));

const svgFillColor = computed(() => (selectedValue.value ? '#17865F' : '#979797'));

watch(selectedOption, (newVal) => {
  selectedValue.value = newVal;
});
</script>

<style scoped>
.custom-select-container {
  display: flex;
  flex-direction: column;
}

.custom-select-label {
  font-size: 14px;
  color: #979797;
  margin-bottom: 5px;
  margin-left: 10px;
  transition: color 0.3s;
}

.custom-select-label-selected {
  color: #17865F;
}

.custom-select {
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.custom-select-trigger {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border: 1px solid;
  border-radius: 12px;
  color: #333;
  box-sizing: border-box;
  transition: border-color 0.3s, box-shadow 0.3s, color 0.3s;
}

.custom-select-trigger:hover {
  border-color: #666;
}

.custom-select-trigger:focus {
  border-color: #17865F;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

.custom-options {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 10;
  border-top: none;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-height: 200px;
  overflow-y: auto;
  border: 1px solid #ddd;
  border-radius: 8px;
  margin-top: 5px;
}

.custom-option {
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.custom-option:hover {
  background-color: #f0f0f0;
}

.custom-option.selected {
  background-color: #17865F;
  color: #fff;
}

.custom-select-trigger {
  color: #979797;
  font-size: 16px;
  font-weight: 400;
  line-height: 27.2px;
  letter-spacing: 0.5px;
  text-align: left;
}
</style>
