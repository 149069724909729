<template>
  <div class="login-layout-container">
    <ToastManagerVue ref="toastManager" />
    <LoadingSpinner :isLoading="isLoading" />
    <div class="main">
      <div class="form-container">
        <h2>Complete your Verification</h2>
        <form @submit.prevent="submitForm">
          <p class="header-text">Verification</p>
          <div class="form-group">
            <SelectDropdown
              label="Select document type"
              id="document_type"
              :options="documentOptions"
              width="400px"
              height="60px"
              @update:selectedOption="handleDocumentSelection"
            />
          </div>
          <div class="form-group" style="margin-top: 16px">
            <label :class="{ active: form.documentNumber }">
              {{ idName || "Document number" }}</label
            >
            <input
              type="text"
              v-model="form.documentNumber"
              :class="{ filled: form.documentNumber }"
              placeholder="0000 0000 0000"
            />
          </div>
          <div class="button-div">
            <BaseButton
              label="Next"
              backgroundColor="var(--s-pink-normal)"
              textColor="var(--state-white)"
              height="60px"
              width="400px"
              :disabled="!formValid"
              disabledBackgroundColor="#ECECEC"
              disabledColor="#ECECEC"
              disabledTextColor="#979797"
            />
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, inject } from "vue";
import BaseButton from "@/components/BaseButton.vue";
import SelectDropdown from "@/components/SelectDropdown.vue";
import { formatErrorMessages } from "@/utils/errorHandler";
import ToastManagerVue from "@/components/ToastAlert/ToastManager.vue";
import Request from "@/utils/apiclient.js";
import { useRouter } from "vue-router";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
export default {
  name: "CompleteVerification",
  components: {
    BaseButton,
    SelectDropdown,
    LoadingSpinner,
    ToastManagerVue,
  },
  setup() {
    const isLoading = ref(false);
    const toastManager = ref(null);
    const showToast = (msg, statusValue) => {
      toastManager.value.addToast(msg, statusValue);
    };

    const router = useRouter();

    const formData = inject("formData");
    const userProfile = JSON.parse(localStorage.getItem("_userProfile"));
    const caregiverId = userProfile.care_giver.id;
    const idName = ref("");
    const documentOptions = ref([
      {
        value: "NIN",
        text: "NIN",
      },
      {
        value: "International Passport",
        text: "International Passport",
      },
    ]);

    const form = ref({
      documentType: "",
      documentNumber: "",
    });

    const handleDocumentSelection = (value) => {
      form.value.documentType = value;
      idName.value = form.value.documentType
        ? form.value.documentType + " number"
        : "Identification Number";
    };

    const formValid = computed(() => {
      return form.value.documentType && form.value.documentNumber;
    });

    const submitForm = () => {
      isLoading.value = true;
      console.log("lang", formData.value.stepSevenData.language);

      // payload.append("bio", formData.value.stepSixData.bio);
      // payload.append("sub_category", formData.value.stepSixData.care_sub_category);
      // payload.append("institution", formData.value.stepSevenData.institution);
      // payload.append("degree_obtained", formData.value.stepSevenData.qualification);
      // payload.append("work_preference", formData.value.stepSevenData.work_preference);
      // payload.append("language", formData.value.stepSevenData.language);
      // payload.append("certificate", formData.value.stepSevenData.certificate);
      // payload.append("document_type", form.value.documentType);
      // payload.append("document_number", form.value.documentNumber);

      const payload = new FormData();

      payload.append("bio", formData.value.stepSixData.bio);

      // Append sub_category as individual integers
      formData.value.stepSixData.care_sub_category.forEach((subCat) => {
        payload.append("sub_category", subCat);
      });

      // Append institution
      payload.append("institution", formData.value.stepSevenData.institution);

      // Append degree_obtained
      payload.append(
        "degree_obtained",
        formData.value.stepSevenData.qualification
      );

      // Append work_preference
      payload.append(
        "work_preference",
        formData.value.stepSevenData.work_preference
      );

      // Append language as individual integers
      formData.value.stepSevenData.language.forEach((lang) => {
        payload.append("language", lang);
      });

      // Append certificate (assuming it's a file)
      payload.append("certificate", formData.value.stepSevenData.certificate);

      // Append document_type
      payload.append("document_type", form.value.documentType);

      // Append document_number
      payload.append("document_number", form.value.documentNumber);

      console.log("payload", payload);

      console.log("payload", payload);
      const request = new Request(`/care-giver/${caregiverId}/`);
      request.patchFormData(payload, (data, error) => {
        isLoading.value = false;
        if (error) {
          if (error.status === "failure") {
            return showToast(error.message, "error");
          }
          const errorMessages = formatErrorMessages(error);
          errorMessages.forEach((message) => showToast(message, "error"));
        } else {
          console.log("data", data);
          // showToast("Profile Update Successful", "success");
          router.push("/caregiver-onboarding/complete-verification/step-2");
        }
      });
    };

    return {
      form,
      formValid,
      submitForm,
      documentOptions,
      handleDocumentSelection,
      idName,
      toastManager,
    };
  },
};
</script>

<style scoped>
/* Your existing styles */
.onboarding-container {
  padding-top: 56px;
}
.care-card {
  display: flex;
  gap: 40px;
  justify-content: center;
}
.care-card-single {
  padding: 24px;
  gap: 10px;
  border-radius: 12px;
  border: 1px solid var(--s-green-normal);

  width: 360px;
}
.login-layout-container {
 background-color: #fff8f9;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    width: 100%;
}
.main {
  background-color: #fff8f9;
  min-height: 100vh;
  width: 100%;
  padding-bottom: 200px;
  max-width: 400px;
}
.navbar {
  display: flex;
  padding: 32px 128px;
  justify-content: space-between;
}
header {
  box-shadow: 0px 4px 1px 0px #0000001a;
}
.main-logo {
  width: 168px;
  height: 59px;
  cursor: pointer;
}
.onboarding-header-text {
  font-size: 24px;
  font-weight: 700;
  line-height: 31.2px;
  letter-spacing: 0.5px;
  text-align: center;
  margin-bottom: 60px;
}

.stepper {
  width: 100%;
  margin: 0 auto;
  padding: 20px;
}

.progress-bar {
  width: 100%;
  background-color: #979797;
  height: 5px;
}

.progress {
  background-color: #17865f;
  height: 5px;
  transition: width 0.3s;
}
.create-account {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.create-account p {
  color: #0a3828;
}
.create-account p:first-child {
  font-weight: 700;
  line-height: 27.2px;
  letter-spacing: 0.5px;
  text-align: left;
  margin-right: 8px;
}

.form-container {
  width: 400px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}

form {
  display: flex;
  flex-direction: column;
}

.form-group {
  margin-bottom: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
}

label {
  color: #979797;
  transition: color 0.3s ease;
  margin-bottom: 4px;

  font-size: 12px;
  font-weight: 400;
  line-height: 20.4px;
  letter-spacing: 0.5px;
  text-align: left;
}

label.active {
  color: var(--s-green-normal-active);
}

input,
select {
  padding: 16px 10px;
  width: 100%;
  border: 1px solid #979797;
  background-color: transparent;
  border-radius: 12px;
  transition: border-color 0.3s ease;
  box-sizing: border-box;
  height: 60px;
}
input::placeholder {
  color: #979797;

  font-size: 16px;
  font-weight: 400;
  line-height: 27.2px;
  letter-spacing: 0.5px;
  text-align: left;
}
input.filled,
select.filled {
  border-color: var(--s-green-normal-active);
}

button {
  padding: 10px;
  background-color: pink;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:disabled {
  background-color: lightgray;
  cursor: not-allowed;
}
.name-class {
  display: flex;
  gap: 16px;
  width: 100%;
}
.header-text {
  font-size: 16px;
  font-weight: 700;
  line-height: 27.2px;
  letter-spacing: 0.5px;
  text-align: left;
  margin-bottom: 16px;
  color: #0a3828;
}
h2 {
  margin-bottom: 32px;
  text-align: center;
  margin-top: 60px;

  font-size: 24px;
  font-weight: 700;
  line-height: 31.2px;
  letter-spacing: 0.5px;
  color: #0a3828;
}
@media screen and (max-width: 800px) {
   h2 {
    margin-top: 30px;
    margin-bottom: 10px;
    font-family: Inter;
font-size: 20px;
font-weight: 700;
line-height: 31.2px;
letter-spacing: 0.5px;
text-align: left;

  }
  .care-card-container {
       width: 350px
  }
  .selects-div {
       width: 350px;
    flex-direction: column;
  }

  .form-group >>> .custom-select-container {
        width: 350px !important;
  }
  .form-group >>> .custom-select-trigger {
    width: 350px!important;
  }
  .work-preference {
    justify-content: center;
  }

  .button-div {
       width: 350px;
    display: flex;
    justify-content: center;
  }
  .days-container-main {
       width: 350px
  }
  .complete-bio-container {
       width: 350px
  }
  h2 {
    margin-top: 20px;
    width: 350px;
  }
  .form-group{
    width: 350px
  }
  form{
    display: flex;
    /* align-items: center; */
    /* justify-content: center; */
  }
  .form-container{
    display: flex;
    justify-content: center;
    align-items: center;
  }
 
}
</style>
