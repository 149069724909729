<template>
  <div class="complete-bio-form">
    <ToastManagerVue ref="toastManager" />
    <LoadingSpinner :isLoading="isLoading" />
    <h1>Complete your Bio</h1>
    <label class="text">Add a profile Image</label>
    <div class="profile-image-container">
      <div class="profile-image">
        <img
          :src="profileImage || require(`@/assets/${defaultImage}`)"
          alt="Profile Image"
        />
        <input type="file" @change="onFileChange" id="file-input" />
        <label for="file-input" class="edit-icon">
          <svg
            width="31"
            height="31"
            viewBox="0 0 31 31"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="31" height="31" rx="15.5" fill="#FCE0E5" />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M19.9125 10.2436L20.7563 11.0873C21.25 11.5748 21.25 12.3686 20.7563 12.8561L12.4875 21.1248H9.875V18.5123L16.375 12.0061L18.1437 10.2436C18.6312 9.75605 19.425 9.75605 19.9125 10.2436ZM11.125 19.8748L12.0062 19.9123L18.1437 13.7686L17.2625 12.8873L11.125 19.0248V19.8748Z"
              fill="#979797"
            />
          </svg>
        </label>
      </div>
    </div>

    <div class="bio-section">
      <label for="bio" class="text">Tell us about yourself</label>
      <textarea
        id="bio"
        v-model="bio"
        placeholder="Share details here"
      ></textarea>
    </div>

    <div class="categories">
      <label for="bio" class="text">Categories</label>
      <p class="text-small">Select all that apply</p>
      <div class="selected-options">
        <span
          v-for="(option, index) in selectedOptionNames"
          :key="index"
          class="selected-option"
        >
          {{ option }}
        </span>
      </div>
      <div
        v-for="(category, index) in careGiverCategories"
        :key="index"
        class="care-button"
        :class="{ focus: category.showDropdown }"
      >
        <div class="care-button-first" @click="toggleDropdown(index)">
          {{ category.name }}
          <span v-if="category.showDropdown">
            <svg
              width="12"
              height="8"
              viewBox="0 0 12 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.68141e-08 6.50016L1.41 7.91016L6 3.33016L10.59 7.91016L12 6.50016L6 0.500156L1.68141e-08 6.50016Z"
                fill="black"
              />
            </svg>
          </span>
          <span v-else>
            <svg
              width="12"
              height="8"
              viewBox="0 0 12 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12 1.91L10.59 0.5L6 5.08L1.41 0.5L-6.16331e-08 1.91L6 7.91L12 1.91Z"
                fill="black"
              />
            </svg>
          </span>
        </div>
        <div v-if="category.showDropdown" class="dropdown">
          <div
            v-for="(option, optIndex) in category.sub_categories"
            :key="optIndex"
            class="option-container"
          >
            <input
              type="checkbox"
              :id="option.id"
              v-model="category.selectedOptions"
              :value="option.id"
              @change="updateSelectedOptions"
            />
            <label :for="option.id">{{ option.name }}</label>
          </div>
        </div>
      </div>
    </div>
    <div class="button-div">
      <BaseButton
        label="Next"
        :disabled="!formIsValid"
        backgroundColor="var(--s-pink-normal)"
        textColor="var(--state-white)"
        disabledBackgroundColor="#ECECEC"
        disabledColor="#ECECEC"
        disabledTextColor="#979797"
        height="60px"
        width="100%"
        @click="submitForm"
      />
    </div>
  </div>
</template>

<script>
import { ref, computed, inject, onMounted } from "vue";
import BaseButton from "@/components/BaseButton.vue";
import { useRouter } from "vue-router";
import Request from "@/utils/apiclient.js";
import ToastManagerVue from "@/components/ToastAlert/ToastManager.vue";
import LoadingSpinner from "@/components/LoadingSpinner.vue";

export default {
  name: "BuildProfileStepThree",
  components: {
    BaseButton,
    ToastManagerVue,
    LoadingSpinner,
  },
  setup() {
    const care_giver = ref("");
    const toastManager = ref(null);
    const showToast = (msg, statusValue) => {
      toastManager.value.addToast(msg, statusValue);
    };
    const isLoading = ref(false);

    const userProfile = JSON.parse(localStorage.getItem("_userProfile"));
    const fetchProfile = () => {
      isLoading.value = true;
      const request = new Request(`/profile/${userProfile.id}/`);
      request.get((res, err) => {
        isLoading.value = false;
        if (err) {
          console.error("Error:", err);
        } else {
          console.log("here is profile data", res);
          localStorage.setItem("_userProfile", JSON.stringify(res));
          care_giver.value = res.care_giver;
          initializeCareGiverCategories();
        }
      });
    };
    onMounted(() => {
      fetchProfile();
    });

    const formData = inject("formData");
    const updateFormData = inject("updateFormData");

    const localData = ref({
      ...formData.value.stepSixData,
    });

    const router = useRouter();
    const profileImage = ref(null);
    const defaultImage = ref("profile_pic.svg");
    const bio = ref("");

    const careGiverCategories = ref([]);
    const initializeCareGiverCategories = () => {
      careGiverCategories.value = care_giver.value.category.map((category) => ({
        ...category,
        showDropdown: false,
        selectedOptions: [],
      }));
    };

    const selectedOptions = ref([]);
    const selectedOptionNames = ref([]);

    // const onFileChange = (event) => {
    //   const file = event.target.files[0];
    //   if (file && file.size <= 1048576) {
    //     const formData = new FormData();
    //     formData.append("profile_picture", file);

    //     const request = new Request(`/profile/${userProfile.id}/`);
    //     request.patchFormData(formData, (data, error) => {
    //       if (error) {
    //         showToast("Profile Picture Update Failed", "error");
    //         console.error("File upload error:", error);
    //       } else {
    //         console.log("data", data);
    //         // showToast("Profile Picture Update Successful", "success");
    //         profileImage.value = URL.createObjectURL(file);
    //       }
    //     });
    //   } else {
    //     showToast("File size should be less than 1MB", "error");
    //     console.error("File size should be less than 1MB");
    //   }
    // };
const onFileChange = (event) => {
  const file = event.target.files[0];
  if (file && file.size <= 5242880) { // 5MB = 5242880 bytes
    const formData = new FormData();
    formData.append("profile_picture", file);

    const request = new Request(`/profile/${userProfile.id}/`);
    request.patchFormData(formData, (data, error) => {
      if (error) {
        showToast("Profile Picture Update Failed", "error");
        console.error("File upload error:", error);
      } else {
        console.log("data", data);
        // showToast("Profile Picture Update Successful", "success");
        profileImage.value = URL.createObjectURL(file);
      }
    });
  } else {
    showToast("File size should be less than 5MB", "error");
    console.error("File size should be less than 5MB");
  }
};

    const toggleDropdown = (index) => {
      careGiverCategories.value[index].showDropdown =
        !careGiverCategories.value[index].showDropdown;
    };

    const updateSelectedOptions = () => {
      selectedOptions.value = careGiverCategories.value.flatMap(
        (category) => category.selectedOptions
      );
      selectedOptionNames.value = careGiverCategories.value.flatMap(
        (category) =>
          category.selectedOptions.map(
            (id) =>
              category.sub_categories.find((option) => option.id === id).name
          )
      );
    };

    const formIsValid = computed(() => {
      return bio.value && selectedOptions.value.length > 0;
    });

    const submitForm = () => {
      localData.value.bio = bio.value;
      localData.value.care_sub_category = selectedOptions.value;
      updateFormData("stepSixData", localData.value);

      // const payload = {
      //   ...localData.value,
      //   bio: bio.value,
      //   care_sub_category: selectedOptions.value,
      // };

      // const request = new Request(`/profile/${userProfile.id}/`);
      // request.patch(payload, (data, error) => {
      //   if (error) {
      //     console.error("Submit error:", error);
      //     showToast("Profile Update Failed", "error");
      //   } else {
      //     showToast("Profile Update Successful", "success");
      //     setTimeout(() => {
      //       router.push("/caregiver-onboarding/build-profile/step-4");
      //     }, 2000);
      //   }
      // });
      router.push("/caregiver-onboarding/build-profile/step-4");
    };

    return {
      profileImage,
      defaultImage,
      bio,
      careGiverCategories,
      selectedOptions,
      selectedOptionNames,
      onFileChange,
      toggleDropdown,
      updateSelectedOptions,
      formIsValid,
      submitForm,
      toastManager,
    };
  },
};
</script>

<style scoped>
.complete-bio-form {
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  border-radius: 10px;
}
.profile-image-container {
  display: flex;
  justify-content: center;
  margin-bottom: 29px;
  margin-top: 29px;
}
.profile-image {
  width: 112px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.profile-image img {
  width: 112px;
  height: 112px;
  border-radius: 50%;
}

.profile-image input[type="file"] {
  display: none;
}

.edit-icon {
  position: absolute;
  bottom: 0;
  right: 0;
  border-radius: 50%;
  cursor: pointer;
}

.bio-section {
  margin-top: 20px;
}
.bio-section textarea {
  display: flex;
  flex-direction: column;
  height: 197px;
  width: 100%;
  padding: 16px;
  background-color: transparent;
  gap: 10px;
  border-radius: 12px;
  box-sizing: border-box;
  border: 1px solid #979797;
  margin-top: 16px;
}

.dropdown {
  display: flex;
  flex-wrap: wrap;
  border-radius: 5px;
}
.dropdown label {
  white-space: nowrap;
  font-size: 14px;
  font-weight: 400;
  line-height: 23.8px;
  margin-left: 8px;
  color: #000000;
}

.option-container {
  width: 50%;
  box-sizing: border-box;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
}
.care-button .category {
  margin-top: 10px;
}

.care-button {
  padding: 16px;
  gap: 10px;
  border-radius: 12px;
  border: 1px solid #979797;
  width: 100%;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  background-color: transparent;
  margin-top: 20px;
  flex-direction: column;
  box-sizing: border-box;
}
.care-button-first {
  display: flex;
  justify-content: space-between;
}
.care-button {
  max-height: 210px;
}

.text {
  font-size: 16px;
  font-weight: 700;
  line-height: 27.2px;
  letter-spacing: 0.5px;
  text-align: left;
  color: #000000;
}
h1 {
  color: #0a3828;
  text-align: center;
  margin-bottom: 32px;
  margin-top:20px
}
.text-small {
  font-size: 12px;
  font-weight: 400;
  line-height: 20.4px;
  letter-spacing: 0.5px;
  text-align: left;
}

input[type="checkbox"] {
  appearance: none;
  width: 15px;
  height: 15px;
  border: 2px solid #979797;
  background-color: transparent;
  display: grid;
  place-content: center;
  transition: background-color 0.3s, border-color 0.3s;
}

input[type="checkbox"]::before {
  content: "";
  width: 10px;
  height: 10px;
  transform: scale(0);
  transition: transform 0.3s;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
  background-color: var(--s-green-normal-active);
}

input[type="checkbox"]:checked {
  border-color: var(--s-green-normal-active);
}

input[type="checkbox"]:checked::before {
  transform: scale(1);
}
.categories {
  margin-bottom: 29px;
  margin-top: 29px;
}
.selected-options {
  margin-bottom: 16px;
  margin-top: 16px;
}
.selected-option {
  display: inline-block;
  background-color: var(--s-pink-light-hover);
  border-radius: 12px;
  padding: 10px;
  margin-right: 8px;
  margin-bottom: 8px;
  font-size: 12px;
  font-weight: 400;
  line-height: 20.4px;
  letter-spacing: 0.5px;
  color: #000000;
  border: 1px solid var(--s-green-normal-active);
}
.focus {
  border: 1px solid var(--s-green-normal);
}
@media screen and (max-width: 800px) {
  .care-card-container {
    width: 300px;
  }
  .selects-div {
    width: 300px;
    flex-direction: column;
  }

  .selects-div >>> .custom-select-container {
    width: 300px !important;
  }
  .selects-div >>> .custom-select-trigger {
    width: 300px !important;
  }
  .work-preference {
    justify-content: center;
  }

  .button-div {
    width: 300px;
    display: flex;
    justify-content: center;
  }
  .days-container-main {
    max-width: 300px;
  }
  .button-div {
    width: 100%;
  }
  h1{
  
font-size: 20px;
font-weight: 700;
line-height: 31.2px;
letter-spacing: 0.5px;
text-align: left;

}
}
</style>
