<template>
  <div :class="containerClass">
    <!-- Label for the slider -->
    <label>{{ label ?? "" }}</label>
    <div class="slider-container">
      <!-- Slider input with v-model binding to localRate -->
      <input
        type="range"
        v-model="localRate"
        :min="min"
        :max="max"
        :step="step"
        @input="updateFormattedRate"
        class="slider"
        :style="sliderStyle"
      />
      <!-- Display the formatted rate value -->
      <div class="slider-value">N {{ formattedRate }}</div>
    </div>
    <div class="rate-display-container">
      <div class="rate-display">
        <span>N {{ formattedRate }}</span>/month
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, watch } from 'vue';

export default {
  name: 'RateSlider',
  props: {
    modelValue: {
      type: Number,
      required: true,
    },
    label: {
      type: String,
      default: "",
    },
    min: {
      type: Number,
      default: 50000,
    },
    max: {
      type: Number,
      default: 150000,
    },
    step: {
      type: Number,
      default: 1000,
    },
    removeContainerClass: {
      type: Boolean,
      default: false,
    },
  },
setup(props, { emit }) {
  const localRate = ref(props.modelValue || 0);  // Local rate state, initialized with the prop value

  const formattedRate = ref(localRate.value.toLocaleString());  // Formatted rate for display

  const updateFormattedRate = () => {
    formattedRate.value = localRate.value.toLocaleString();  // Update formatted rate
    emit('update:modelValue', localRate.value);  // Emit the updated rate to the parent component
  };

  const sliderStyle = computed(() => {
    const percentage = ((localRate.value - props.min) / (props.max - props.min)) * 100;
    return {
      '--value': localRate.value,
      '--min': props.min,
      '--max': props.max,
      background: `linear-gradient(to right, var(--s-green-normal) ${percentage}%, #e0e0e0 ${percentage}%)`,
    };
  });

  const containerClass = computed(() => {
    return props.removeContainerClass ? '' : 'rate-container';
  });

  watch(() => props.modelValue, (newValue) => {
    localRate.value = newValue || 0;
    updateFormattedRate();
  });

  watch(localRate, updateFormattedRate);  // Watch for changes in localRate and call updateFormattedRate

  return {
    localRate,
    formattedRate,
    sliderStyle,
    updateFormattedRate,
    containerClass,
  };
}

};
</script>

<style scoped>
/* Add your styles here */
.rate-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 60px;
  font-family: Arial, sans-serif;
}

h1 {
  color: #0a3828;
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 32px;
}

.rate-input {
  width: 100%;
  max-width: 400px;
  text-align: center;
  margin-bottom: 20px;
}

.rate-input label {
  display: block;
  font-size: 16px;
  color: #000000;
  margin-bottom: 56px;
  font-size: 14px;
  font-weight: 400;
  line-height: 23.8px;
  letter-spacing: 0.5px;
  text-align: left;
}

.slider-container {
  position: relative;
  width: 100%;
}

.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 10px;
  background: linear-gradient(to right, var(--s-green-normal) 50%, #e0e0e0 50%);
  border-radius: 5px;
  outline: none;
  padding: 0;
  margin: 0;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: #17865f;
  cursor: pointer;
  /* Inner white border and outer green border with 4px apart */
  box-shadow: 0 0 0 4px #ffffff, 0 0 0 8px #b7d9cd; /* Outer green border, 4px from white border */
}

.slider::-moz-range-thumb {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: #17865f;
  cursor: pointer;
  /* Inner white border and outer green border with 4px apart */
  box-shadow: 0 0 0 4px #ffffff, 0 0 0 8px #b7d9cd; /* Outer green border, 4px from white border */
}

.slider-container .slider-value {
  position: absolute;
  top: -40px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #17865f;
  color: white;
  padding: 5px 10px;
  border-radius: 20px;
  font-size: 14px;
}

.slider-value {
  position: absolute;
  top: -40px;
  left: 50%;
  transform: translateX(-50%);
  background-color: var(--s-green-normal);
  color: white;
  font-size: 14px;
  padding: 10px;
  gap: 10px;
  border-radius: 12px;
}

.rate-display-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.rate-display {
  font-size: 14px;
  color: #0a3828;
  margin-top: 23px;
  padding: 5px 10px;
  border: 1px solid #979797;
  border-radius: 5px;
  width: 146px;
  padding: 10px 12px 10px 12px;
  gap: 10px;
  border-radius: 12px;
}

.rate-info {
  display: flex;
  align-items: center;
  background-color: #0063f71a;
  color: #000000;
  padding: 10px 24px;
  border-radius: 12px;
  margin-bottom: 24px;
  max-width: 400px;
  text-align: left;
  gap: 16px;
  box-sizing: border-box;
}

.info-icon {
  background-color: #007bff;
  color: white;
  font-size: 14px;
  font-weight: bold;
  padding: 5px;
  border-radius: 50%;
  margin-right: 10px;
}

.next-button {
  background-color: #ff6978;
  color: white;
  font-size: 16px;
  padding: 10px 20px;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.next-button:disabled {
  background-color: #ececec;
  color: #979797;
  cursor: not-allowed;
}

.average-text {
  font-size: 14px;
  font-weight: 400;
  line-height: 23.8px;
  letter-spacing: 0.5px;
  text-align: left;
  color: #000000;
}

@media (max-width: 800px) {
  
.rate-display {
  font-size: 12px;
  
}
.slider-value{
  font-size: 12px !important;
}
}
</style>
