<template>
  <div style="margin-bottom: 24px; margin-top: 10px">
    <div class="subjects-div">
      <button
        v-for="(subject, index) in subjects"
        :key="index"
        @click="toggleSubject(subject)"
        :class="{ active: selectedSubjects.includes(subject) }"
        :style="{ minWidth: buttonWidth + 'px' }"
      >
        {{ subject }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "SubjectSelector",
  props: {
    subjects: {
      type: Array,
      required: true,
    },
    selectedSubjects: {
      type: Array,
      required: true,
    },
    singleSelect: {
      type: Boolean,
      default: false,
    },
    buttonWidth: {
      type: Number,
      default: 126.67, // default button width
    },
  },
  methods: {
    toggleSubject(subject) {
      let updatedSubjects;
      if (this.singleSelect) {
        updatedSubjects = this.selectedSubjects.includes(subject) ? [] : [subject];
      } else {
        updatedSubjects = this.selectedSubjects.includes(subject)
          ? this.selectedSubjects.filter((s) => s !== subject)
          : [...this.selectedSubjects, subject];
      }
      this.$emit("update:selectedSubjects", updatedSubjects);
    },
  },
};
</script>

<style scoped>
.subjects-div {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
.subjects-div button {
  height: 44px;
  padding: 10px;
  border-radius: 12px;
  border: 1px solid #979797;
  background-color: transparent;
  white-space: nowrap;
  font-size: 14px;
  font-weight: 400;
  line-height: 23.8px;
  letter-spacing: 0.5px;
}
.subjects-div button.active {
  background-color: var(--s-pink-light-hover);
  color: var(--s-green-dark);
  border-color: var(--s-green-normal);
}
.child-text-main {
  font-size: 16px;
  font-weight: 400;
  line-height: 23.8px;
  letter-spacing: 0.5px;
}
</style>
