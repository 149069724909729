<template>
 <DashboardLayout>
     <LoadingSpinner :isLoading="isLoading" />
  <div class="overall-container">
    <div class="main-container">
      <div class="content">
        <div class="jobs-div">
          <h2>Jobs</h2>
          <BaseButton
            label="Create Job Post"
            backgroundColor="transparent"
            textColor="var(--s-green-normal-active)"
            height="48px"
            width="176px"
            borderColor="var(--s-green-normal-active)"
            @click="redirectToCreateJob"
          />
        </div>
        <div class="content-main">
          <div class="tabs">
            <div
              class="single-tab"
              :class="{ active: currentTab === 'Current' }"
              @click="setTab('Current')"
            >
              <p>Current</p>
            </div>
            <div
              class="single-tab"
              :class="{ active: currentTab === 'Previous' }"
              @click="setTab('Previous')"
            >
              <p>Previous</p>
            </div>
          </div>
          <div class="jobs-list">
            <div
              class="jobs-list-item"
              v-for="jobs in careSeekerJobs"
              :key="jobs.id"
            >
              <div class="jobs-list-item-first-div">
                <div>
                  <h4>{{ jobs.category.name }} needed</h4>
                  <p class="sub-text">
                    <!-- {{ jobs.experience_level }} - -->
                     Est. Budget: {{ jobs.budget }}
                  </p>
                </div>
                <p class="sub-text">{{ jobs.time_of_post }}</p>
              </div>
              <div>
                <p class="main-text">{{ jobs.other_requirement }}</p>
              </div>
              <div>
                <router-link :to="`view-edit-job/${jobs.id}`" class="view-edit"
                  >View Job / Edit Job</router-link
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
 </DashboardLayout>
</template>

<script>
import BaseButton from "@/components/BaseButton.vue";
import { useRouter } from "vue-router";
import { ref,onMounted } from "vue";
import DashboardLayout from "@/views/Dashboard/Caregiver/IndexPage.vue";
import Request from "@/utils/apiclient.js";
import LoadingSpinner from "@/components/LoadingSpinner.vue";

export default {
  name: "CareSeekerJobs",
  components: { BaseButton, DashboardLayout,LoadingSpinner },
  setup() {
     const isLoading = ref(true);
    const currentTab = ref("Current");
    const setTab = (tab) => {
      currentTab.value = tab;
    };
    const careSeekerJobs = ref([
      // {
      //   id: 1,
      //   title: "Senior Care Needed",
      //   time_of_post: "posted 2 days ago",
      //   experience_level: "intermediate",
      //   details:
      //     "I need a sit in care giver for my mom of 75 years I need a sit in care giver for my mom of 75 years I need a sit in care giver for my mom of 75 years. I need a sit in care giver for ..",
      //   budget: "N20,000",
      // },
      // {
      //   id: 2,
      //   title: "Senior Care Needed",
      //   time_of_post: "posted 2 days ago",
      //   experience_level: "intermediate",
      //   details:
      //     "I need a sit in care giver for my mom of 75 years I need a sit in care giver for my mom of 75 years I need a sit in care giver for my mom of 75 years. I need a sit in care giver for ..",
      //   budget: "N20,000",
      // },
      // {
      //   id: 3,
      //   title: "Senior Care Needed",
      //   time_of_post: "posted 2 days ago",
      //   experience_level: "intermediate",
      //   details:
      //     "I need a sit in care giver for my mom of 75 years I need a sit in care giver for my mom of 75 years I need a sit in care giver for my mom of 75 years. I need a sit in care giver for ..",
      //   budget: "N20,000",
      // },
    ]);
    const router = useRouter();
    const redirectToCreateJob = () => {
      router.push("/careseeker/create-job");
    };
      const fetchJobs = () => {
      const request = new Request("/job/");
      request.get((res, err) => {
        isLoading.value = false;
        if (err) {
          console.error("Error:", err);
        } else {
          console.log("here is admin careseekers data", res);
  careSeekerJobs.value = [...res.results];
        }
      });
    };
     onMounted(() => {
      fetchJobs();
    });
    return {
      setTab,
      currentTab,
      careSeekerJobs,
      redirectToCreateJob,
      isLoading
    };
  },
};
</script>

<style scoped>
.overall-container {
  display: flex;
  width: 100%;
  justify-content: center;
}
.main-container {
  display: flex;
  padding: 32px 0px 500px 28px;
}
.content {
  width: 967px;
}
.jobs-div {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.tabs {
  display: flex;
  justify-content: flex-start;
  margin-top: 42px;
}
.tabs p {
  color: #0a3828;
  font-size: var(--font-body-normal-regular-size);
  line-height: var(--font-body-normal-regular-line-height);
  font-weight: var(--font-weight-regular);
}
.single-tab {
  padding: 0px 20.5px 4px 20.5px;
  cursor: pointer;
}
.active {
  border-bottom: 3px solid var(--s-pink-normal);
}
.active p {
  color: var(--s-pink-normal) !important;
  font-weight: var(--font-weight-bold) !important;
}
.jobs-list {
  padding-top: 16px;
}
.jobs-list-item {
  margin-bottom: 16px;
  border: 1px solid var(--s-green-light-active);
  border-radius: 12px;
  padding: 20px 16px;
}
.jobs-list-item-first-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.jobs-list-item-first-div div:first-child {
  display: flex;
  flex-direction: column;
}
h4 {
  margin: 0px 0px 4px 0px;
}
.view-edit {
  color: var(--s-green-normal-active);

  text-decoration: none;
}
.main-text {
  margin-bottom: 20px;
  font-size: 14px;
  font-weight: 400;
  line-height: 23.8px;
  letter-spacing: 0.5px;
  text-align: justified;
}
.sub-text {
  font-size: 12px;
  font-weight: 400;
  line-height: 20.4px;
  letter-spacing: 0.5px;
  text-align: left;
}
h2 {
  margin: 0px;

  font-size: 28px;
  font-weight: 700;
  line-height: 36.4px;
  letter-spacing: 0.5px;
  text-align: center;
  display: flex;
  align-items: center;
}
</style>
