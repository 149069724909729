<template>
  <div v-if="isVisible" class="modal-overlay" @click="closeModal">
    <div class="modal-content" :style="genericStyles" @click.stop>
      <header>
        <h5>{{ title }}</h5>
        <!-- <button class="close-button" @click="closeModal">X</button> -->
      </header>
      <div class="modal-body" @click.stop>
        <slot></slot>
      </div>
      <!-- <footer>
        <button class="apply-button" @click="confirmAction">Update</button>
      </footer> -->
    </div>
  </div>
</template>

<script>
import {computed} from "vue";
export default {
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
    padding: {
      type: String,
      default: "35px 50px",
    },
  },
  setup(props, { emit }) {
    console.log("got here");
    const genericStyles = computed(() => ({
      padding: props.padding ? props.padding : "35px 50px",
    }));

    const closeModal = () => {
      emit("close");
    };

    const confirmAction = () => {
      emit("confirm");
    };

    return {
      ...props,

      closeModal,
      confirmAction,
      genericStyles,
    };
  },
};
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
}

.modal-content {
  background: #fff;
  padding: 35px 50px;
  max-width: 500px;
  min-height: 300px;

  border: 1px solid var(--s-green-normal);
  width: 100%;
  border-radius: 12px;
  box-sizing: border-box;
  overflow-y: auto;
}

.close-button {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

footer {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
}

.apply-button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  background: #28a745;
  color: #fff;
}
h5 {
  font-size: 20px;
  font-weight: 700;
  line-height: 34px;
  letter-spacing: 0.5px;
  text-align: center;
  margin: 0px;
}
.modal-body {
  margin-top: 25px;
}

@media (max-width: 800px) {
  .modal-content{
    max-width: 300px;
      max-height: 500px;
  }
}
</style>
