<template>
  <div class="caregiver-container">
    <div>
      <h1>What Kind of Care do you need?</h1>
      <p class="text">Please select only one option</p>
    </div>
    <div class="care-card-container">
      <div
        v-for="service in services"
        :key="service.id"
        class="care-card"
        :class="{
          'care-card-selected': localData.type_of_care === service.id,
        }"
        @click="setCare(service.id)"
      >
        <img :src="require(`@/assets/${service.icon}`)" />
        <p>{{ service.label }}</p>
      </div>
    </div>

    <div>
      <BaseButton
        label="Next"
        :disabled="disabled"
        backgroundColor="var(--s-pink-normal)"
        textColor="var(--state-white)"
        disabledBackgroundColor="#ECECEC"
        disabledColor="#ECECEC"
        disabledTextColor="#979797"
        height="60px"
        width="400px"
        @click="handleClick"
      />
    </div>
    <div></div>
  </div>
</template>

<script>
import BaseButton from "@/components/BaseButton.vue";
import { ref, inject, watch, onMounted } from "vue";
import { useRouter } from "vue-router";
import Request from "@/utils/apiclient.js";
export default {
  name: "CareSeekerCreateAccountStepOne",
  components: {
    BaseButton,
  },
  setup() {
    const services = ref([]);
    const isLoading = ref(false);

     const fetchCareCategory = () => {
       isLoading.value = true;
      const request = new Request("/category/");
      request.get((res, err) => {
         isLoading.value = false;
        if (err) {

          console.error("Error:", err);
        } else {
          console.log("Data:", res);
          // Map the API data to add the icon field from the static data
          const iconMap = {
            'Elderly care': 'senior_care.svg',
            'Tutor': 'academic_care.svg',
            'Child care': 'child_care.svg',
            'Housekeeper': 'house_care.svg',
          };

          services.value = res.results.map(item => {
            return {
              id: item.id,
              label: item.name,
              icon: iconMap[item.name], // fallback icon if not found
            };
          });
        }
      });
    };
    onMounted(() => {
      fetchCareCategory();
    });

    const formData = inject("onboardingCareSeekerformData");
    const updateFormData = inject("onboardingUpdateCareSeekerformData");

    const localData = ref({
      ...formData.value.stepOneData,
    });

    const router = useRouter();
    const disabled = ref(true);

    const setCare = (care) => {
      localData.value.type_of_care = care;
      disabled.value = false; // Enabling the button after selection
    };

    const handleClick = () => {
      updateFormData("stepOneData", localData.value);
      router.push("/careseeker-onboarding/create-account/step-2");
    };

    watch(
      localData,
      (newValue) => {
        updateFormData("stepOneData", newValue);
      },
      { deep: true }
    );

    return {
      disabled,
      localData,
      handleClick,
      setCare,
      services,
    };
  },
};
</script>

<style scoped>
h1 {
  color: #0a3828;
  font-size: 24px;
  font-weight: 700;
  line-height: 31.2px;
  letter-spacing: 0.5px;
  margin: 0px;
  text-align: center;
}

.text {
  color: #979797;
  font-size: 16px;
  font-weight: 400;
  line-height: 27.2px;
  letter-spacing: 0.5px;
  text-align: center;
}

.caregiver-container {
  padding-top: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.care-card {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 16px 24px;
  gap: 16px;
  border-radius: 12px;
  border: 1px solid #979797;
  box-sizing: border-box;
  transition: background-color 0.3s, border-color 0.3s;
}

.care-card-selected {
  background-color: var(--s-pink-light-hover);
  border-color: var(--s-green-normal-active);
}

.care-card-container {
  width: 400px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 42px;
  margin-bottom: 24px;
  cursor: pointer;
}

.care-card p {
  color: #0d4a34;
}
</style>
